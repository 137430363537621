import i18next from 'i18next';

import './styles-configuration.scss'

import { get_template, empty, addLiveEventListener, traverse_object, traverse_object_set, parentSelector } from '../../libs/helpers.js'
import { styles, styles_configuration } from '../../libs/consts.js'
import {Observable} from '../../libs/observable.js'
import { OptionsStorage } from '../../models/optionsStorage.js'
import user_configuration from '../../models/user_configuration.js'


class CustomStylesOption extends OptionsStorage {
    constructor() {
        super('custom_styles', {
            stringify: (d) => JSON.stringify([...d.entries()]),
            parse: (s) => new Map(JSON.parse(s))
        })
    }

    get defaultValue() {
        return new Map()
    }

    conflictResolution() {
    var choice = confirm("Les styles ont été modifiées de façons différentes sur le serveur et en local. Récupérer les données du serveur ?")
    if (choice) {
            return "server"
        } else {
            return "local"
        }
    }
}

const customSourceOption = new CustomStylesOption();

class StyleConfiguration {
    constructor() {
        this.buildOptionHmi()
        this.onStyleUpdated = new Observable("onStyleUpdated")
        console.log("[STYLES] create onStyleUpdated Observable")
        this.style = styles
        this._customStyles = null;
        this.customStylesModified = false;
    }

    get customStylesPromise() {
        if (this._customStyles) {
            return Promise.resolve(this._customStyles)
        }
        return new Promise((resolve) => {
            customSourceOption.get()
            .then((customStyles) => {
                this._customStyles = customStyles
                resolve(this._customStyles)
            })
        })
    }

    async setCustomStyles(style) {
        const cs = await this.customStylesPromise
        cs.set(style.id, style)
        this.customStylesModified = true
        //customSourceOption.set(cs)
    }

    async removeCustomStyles(styleId) {
        const cs = await this.customStylesPromise
        cs.delete(styleId)
        this.customStylesModified = true
        //customSourceOption.set(cs)
    }

    async getCustomStyles(styleId) {
        const cs = await this.customStylesPromise
        return cs.get(styleId)
    }

    async getAllCustomStyles() {
        return await this.customStylesPromise
    }

    async saveCustomStyles() {
        const cs = await this.customStylesPromise
        customSourceOption.set(cs)
    }

    async buildOptionHmi() {
        for (const el of document.querySelectorAll("#styles-configuration-list-selection .styles-configuration-list-option")) {
            el.remove()
        }
        var selection = localStorage.getItem("styles-configuration-list-selection")
        var selectionFind = false
        const styles = await this.getAllCustomStyles()
        for (const style of styles.values()) {
            const optElt = get_template("template-styles-configuration-list-option")
            optElt.value = style.id
            optElt.innerText = style.name
            document.getElementById("styles-configuration-list-selection").insertAdjacentElement('beforeend', optElt)
            if (selection == style.id) {
                selectionFind = true
            }
        }
        if ( ! selectionFind ) {
            selection = "default"
        }
        document.getElementById("styles-configuration-list-selection").value = selection
    }

    async newStyle() {
        const style = {
            id : window.crypto.randomUUID(),
            name: "new style",
            fields: [
            ]
        }
        await this.setCustomStyles(style)
        return style.id
    }

    async removeStyle(styleId) {
        await this.removeCustomStyles(styleId)
    }

    async computeStyle(selection=null) {
        const style = JSON.parse(JSON.stringify(styles))
        if (selection == null) {
            selection = localStorage.getItem("styles-configuration-list-selection") || "default"
        }
        console.log("[STYLE]", selection)
        if (selection != "default") {
            const cs = await this.getCustomStyles(selection)
            if (cs) {
                for (const field of cs.fields) {
                    traverse_object_set(style, field.path, field.value)
                }
            }
        }
        return style
    }

    async applyStyle(selection=null) {
        const style = await this.computeStyle(selection)
        this.style = style
        console.log("[STYLES] trigger onStyleUpdated Observable")
        this.onStyleUpdated.notify(this.style)
    }

    async styleById(id) {
        return await this.getCustomStyles(id)
    }

    getPathValue(style, path) {
        for (const field of style.fields) {
            if (field.path == path) {
                return field.value
            }
        }
        return traverse_object(styles, path)
    }

    _setPathValue(style, path, value) {
        for (const field of style.fields) {
            if (field.path == path) {
                field.value = value
                return
            }
        }
        style.fields.push({
            path: path,
            value: value
        })
    }

    setPathValue(style, path, value) {
        this._setPathValue(style, path, value)
        this.customStylesModified = true
    }
}

export const styleConfiguration = new StyleConfiguration()

user_configuration.on_cloud_changed.subscribe(async () => {
    styleConfiguration.applyStyle()
})

export function dataField(data, el) {
        var value = traverse_object(data, el.dataset.field)

        if (el.dataset.fieldTransform == "i18n") {
            value = i18next.t(value)
        }

        if (el.dataset.localeOptions && (value != undefined)) {
            value = value.toLocaleString('fr-FR', JSON.parse(el.dataset.localeOptions))
        } else {
            if (value == undefined ) {
                value = ""
            }
        }

        if (el.dataset.assign == "innerHTML") {
            el.innerHTML = value
        } else if ((el.tagName == "A")) {
            el.href = el.dataset.href + value
        } else if ((el.tagName == "SELECT")) {
            el.value = value
        } else if ((el.tagName == "INPUT") && (el.type == "radio")) {
            el.checked = value || false
        } else if ((el.tagName == "INPUT") && (el.type == "checkbox")) {
            el.checked = value || false
        } else if ((el.tagName != "INPUT") & (el.tagName != "TEXTAREA")) {
            el.innerText = value
        } else {
            el.value = value || ""
        }
}

async function buildEditHmi(currentSelection=null) {
    const selectElt = document.getElementById("styles-configuration-edit-list-selection")
    if (currentSelection == null) {
        currentSelection = selectElt.value
    }
    for (const el of selectElt.querySelectorAll(".styles-configuration-list-option")) {
        el.remove()
    }
    var selectionFind = false;
    const styles = await styleConfiguration.getAllCustomStyles()
    for (const style of styles.values()) {
        const optElt = get_template("template-styles-configuration-list-option")
        optElt.value = style.id
        optElt.innerText = style.name
        selectElt.insertAdjacentElement('beforeend', optElt)
        if (currentSelection == style.id) {
            selectionFind = true
        }
    }
    if (currentSelection && selectionFind) {
        selectElt.value = currentSelection
    }
    selectElt.parentElement.classList.toggle("d-none", styles.size == 0)
    document.getElementById("styles-configuration-dialog-style").classList.toggle("d-none", styles.size == 0)
    document.getElementById("styles-configuration-remove-button").classList.toggle("d-none", styles.size == 0)
    if (styles.size) {
        buildEditStyleHmi()
    }
}

function formatSvg(sectionElt, style) {
    const svg = sectionElt.querySelector("svg .svg-configuration-item")
    for (const [k, v] of Object.entries(sectionElt.sectionConfiguration.svg)) {
        svg.setAttribute(k, traverse_object(style, v));
    }
}

async function buildEditStyleHmi() {
    console.log("buildEditStyleHmi")
    const styleId = document.getElementById("styles-configuration-edit-list-selection").value
    const styleConf = await styleConfiguration.styleById(styleId)
    const style = await styleConfiguration.computeStyle(styleId)
    document.getElementById("styles-configuration-dialog-style-name").value = styleConf.name

    const fieldsElt = document.getElementById("styles-configuration-dialog-fields")
    empty(fieldsElt)

    for (const section of styles_configuration) {
        const optElt = get_template(section.template)
        optElt.sectionConfiguration = section

        formatSvg(optElt, style)
        for (const el of optElt.querySelectorAll("[data-field]")) {
            dataField(section, el)
        }

        fieldsElt.insertAdjacentElement('beforeend', optElt)

        const itemElt = optElt.querySelector(".styles-configuration-items")

        for (const field of section.fields) {
            const tmpElt = get_template(field.template)
            for (const el of tmpElt.querySelectorAll("[data-field]")) {
                dataField(field, el)
            }
            const input = tmpElt.querySelector("input")
            input.styleField = field
            input.value = styleConfiguration.getPathValue(styleConf, field.path)
            itemElt.insertAdjacentElement('beforeend', tmpElt)

        }
    }
}

document.getElementById("styles-configuration-edit-button").onclick = () => {
    buildEditHmi(document.getElementById("styles-configuration-list-selection").value);
    document.getElementById("styles-configuration-dialog").showModal();
}

document.getElementById("styles-configuration-edit-list-selection").onchange = () => {
    buildEditStyleHmi()
}

document.getElementById("styles-configuration-dialog-style-name").onchange = async (event) => {
    const styleId = document.getElementById("styles-configuration-edit-list-selection").value
    const style = await styleConfiguration.styleById(styleId)
    style.name = event.target.value
    styleConfiguration.setCustomStyles(style)
    buildEditHmi();
}

addLiveEventListener(document.getElementById("styles-configuration-dialog-fields"), "change", "input", async (event) => {
    const styleId = document.getElementById("styles-configuration-edit-list-selection").value
    const style = await styleConfiguration.styleById(styleId)
    const path = event.target.styleField.path
    const value = event.target.value
    styleConfiguration.setPathValue(style, path, value)
    styleConfiguration.computeStyle(styleId)
    .then((style) => {
        formatSvg(parentSelector(event.target, ".styles-configuration-section"), style)
    })

})


document.getElementById("styles-configuration-new-button").onclick = async () => {
    const styleId = await styleConfiguration.newStyle();
    buildEditHmi(styleId);
}

document.getElementById("styles-configuration-remove-button").onclick = async () => {
    if (window.confirm(i18next.t('styles_configuration.edit.delete_style'))) {
        await styleConfiguration.removeStyle(document.getElementById("styles-configuration-edit-list-selection").value);
        buildEditHmi();
    }
}

document.getElementById("styles-configuration-dialog").onclose = () => {
    styleConfiguration.saveCustomStyles();
    styleConfiguration.buildOptionHmi();
    styleConfiguration.applyStyle();
}

document.getElementById("styles-configuration-dialog-close-button").onclick = () => {
    document.getElementById("styles-configuration-dialog").close();
}

document.getElementById("styles-configuration-list-selection").addEventListener("change", (event) => {
    styleConfiguration.applyStyle(event.target.value);
})