"use strict";

import * as bootstrap from 'bootstrap';

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: new URL('../node_modules/leaflet/dist/images/marker-icon-2x.png', import.meta.url),
    iconUrl: new URL('../node_modules/leaflet/dist/images/marker-icon.png', import.meta.url),
    shadowUrl: new URL('../node_modules/leaflet/dist/images/marker-shadow.png', import.meta.url),
})

import 'leaflet-easybutton'
import 'leaflet-easybutton/src/easy-button.css'

import 'leaflet-extra-markers'
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css'

import './modules/beaufifymarker/leaflet-beautify-marker-icon.js'
import './modules/beaufifymarker/leaflet-beautify-marker-icon.css'

import * as turf from '@turf/turf'

import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
import '@fortawesome/fontawesome-free/scss/regular.scss'
import '@fortawesome/fontawesome-free/scss/solid.scss'

import 'bootstrap-icons/font/bootstrap-icons.css'

import togpx from './libs/togpx'
import './libs/logger.js'

import {styles, consts } from "./libs/consts.js"
import {Route, gpx_to_geojson} from "./libs/route.js"
import routes from "./routes/jjab_routes.js"
import { TilesStorage, geojsonFromTile, LeafletLatLngToGeojson,
         boundsFromTile } from './libs/utils.js'

import { ready, on_init, addLiveEventListener, trigger, download, watch, dispatch, delayed } from './libs/helpers.js'

import i18next from 'i18next';

import user_configuration from './models/user_configuration.js'
import tiles_configuration from './models/tiles_configuration.js'
import trace_tiles from './models/trace_tiles.js'

import { Route_Ign } from './libs/routing_ign.js'
import { Route_Brouter } from './libs/routing_brouter.js'
import './libs/jjab_notification.js'
import { cloud_open } from './routes/jjab_cloud.js'
import './libs/no_sleep.js'

import { grid_add } from './map/grid.js'
import './map/custom_sources.js'
import './map/JjabTileLayer.js'
import { mapSourcesManager } from './map/tiles_layers_mgnt.js'
import { marker_add } from './map/geoloc_marker.js'
import { heading_add } from './map/geoloc_heading.js'
import { tiles_user_geojson } from './tiles/tiles_user_geojson.js'

import './routes/jjab_undo.js'
import './routes/jjab_route_info.js'
import './routes/jjab_cloud.js'
import './routes/jjab_elevation.js'
import { load_offline_tiles } from './tiles/tiles_offline.js'
import { trace } from './models/trace.js'

import geoloc_ctrl from './controllers/geoloc_ctrl.js'
import sound from './models/sound.js'

import visited_tiles from './models/visited_tiles.js'

import './controllers/internationalization.js'
import './modules/privacy/privacy.js'
import './controllers/sound.js'
import './controllers/service_worker.js'
import {route_center} from './controllers/routes.js'
import {processInputStorage} from './controllers/input-storage.js'

import './views/configuration.js'

import './views/routes-list/routes-list.js'
import { sidebar_toggle } from './views/sidebar-app/sidebar-app.js'

import './controllers/debug.js'

import { styleConfiguration } from './modules/styles-configuration/styles-configuration.js'
import { DBFile_expiration } from './models/json-cache.js'
import { overlayAction } from './controllers/overlay_action.js'


if (navigator.onLine) {
    DBFile_expiration()
}

sound.on_play.subscribe((ctx) => {
    if (ctx.state == "suspended") {
        overlayAction.show("options.options.sound", () => {
            ctx.resume();
        })
    }
})

if (process.env.NODE_ENV !== 'development') {
    window.screenLog.init()
}

ready(function(){
    // ############ USER CONFIGURATION ############
    function name_request() {
        return prompt(i18next.t("application.name_request"), user_configuration.user);
    }

    document.getElementById('changeUser').onclick = function() {
        const new_user = name_request();
        if (new_user != null) {
            user_configuration.setUser(new_user)
            .catch((rejectedData) => {
                dispatch("notification", "api.error.desc", rejectedData.error, true)
            })
        }
    }

    on_init(()=> {
        user_configuration.initialize(name_request)
        .catch((rejectedData) => {
            dispatch("notification", "api.error.desc", rejectedData.error, true)
        })
    })

    // ############ TILES CONFIGURATION ############
    watch("level_change", function(level, init) {
        tiles_configuration.set_selection(level, init)
    })

    tiles_configuration.on_configuration_changed.subscribe((conf) => {
        visited_tiles.load(conf);
    })

    // OPTIONS MANAGEMENT

    // ############ MAP ############
    var mymap = L.map('map-container', {zoomSnap: 0.5, zoomDelta:0.5, attributionControl: false});

    const resizeObserver = new ResizeObserver((/*entries*/) => {
        mymap.invalidateSize();
    });

    resizeObserver.observe(document.querySelector("#map-container"));


    // Create panes to add layers on the right z-index
    for (const pane of consts.panes) {
        mymap.createPane(pane.name);
        mymap.getPane(pane.name).style.zIndex = pane.zIndex;
    }

    // ############ SAVE MAP ZOOM & CENTER ############
    {
        user_configuration.on_user_changed.subscribe((name, init) => {
            if (init) {
                if (name && localStorage.getItem("map_center_"+name)) {
                    mymap.setView(JSON.parse(localStorage.getItem("map_center_"+name)),
                                  JSON.parse(localStorage.getItem("map_zoom_"+name)));
                } else {
                    mymap.setView({"lat":49,"lng":2}, 7);
                }
            }
        })

        mymap.on("moveend", function(/*event*/) {
            if (user_configuration.user) {
                localStorage.setItem("map_zoom_"+user_configuration.user, JSON.stringify(mymap.getZoom()))
                localStorage.setItem("map_center_"+user_configuration.user, JSON.stringify(mymap.getCenter()))
            }
        });

        watch('geojson_loaded', () => {
            if (localStorage.getItem("map_center_"+user_configuration.user) == null) {
                trigger(document.getElementById("squadrats-center"), "click")
            }
        })

        watch('mymap_fitBounds', (bounds) => {
            mymap.fitBounds(bounds);
        });

    }

    // initialize ihm elements to mymap
    mapSourcesManager.addTo(mymap)
    tiles_user_geojson(mymap, user_configuration.user)
    grid_add(mymap)
    marker_add(mymap)
    heading_add(mymap)

    // ############ GEOLOCALISATION ############

    L.easyButton('fa-location-arrow', function(/*btn, map*/) {
        dispatch("geoloc_button");
        geoloc_ctrl.start();
    }, "geloc", "button-geoloc").addTo( mymap );

    document.getElementById("navigation-stop").onclick = function() {
        geoloc_ctrl.stop();
        settings_modal.hide();
    }
    watch("geoloc_stop", () => document.getElementById("navigation-stop").setAttribute( "disabled", "true" ))
    watch("geoloc_start", () => document.getElementById("navigation-stop").removeAttribute( "disabled" ))

    watch("trace_loaded", function() {
        geoloc_ctrl.autostart(trace.last_date());
    })

    // ############ GEOLOC SURVEY ############
    {
        let survey_timeout = null

        const on_survey_timeout = function() {
            if (geoloc_ctrl.started) {
                document.getElementById("button-geoloc").style.backgroundColor = "lightcoral";
                console.warn("Geoloc timeout: relaunch geolocalisation")
                geoloc_ctrl.start();
            }
        }

        watch("geoloc_start", function() {
            survey_timeout = setTimeout(on_survey_timeout, 10000)
            document.getElementById("button-geoloc").style.backgroundColor = "lightblue"

        })

        watch("geoloc_pos", function() {
            document.getElementById("button-geoloc").style.backgroundColor = "lightgreen"
            if (survey_timeout) {
                clearTimeout(survey_timeout)
            }
            survey_timeout = setTimeout(on_survey_timeout, 10000)
        })

        watch("geoloc_stop", () => {
            if (survey_timeout) {
                clearTimeout(survey_timeout)
            }
            document.getElementById("button-geoloc").style.backgroundColor = ""

        })
    }

    // ############ VISITED TILES ############
    var visited_new_tiles_count = 0;
    let layer_tiles = L.layerGroup([]).addTo(mymap)
    var trace_tiles_style = styles.trace_tiles

    styleConfiguration.onStyleUpdated.subscribe((styles) => {
        trace_tiles_style = styles.trace_tiles
        layer_tiles.eachLayer((layer) => {
            layer.setStyle(trace_tiles_style)
        })
    })

    trace.on_new_point.subscribe((pos) => {
        const tile = trace_tiles.add_latlon(pos.coords.latitude, pos.coords.longitude, pos.coords.accuracy);
        if (tile != false) {
            if (!visited_tiles.has(tile.x, tile.y)) {
                visited_new_tiles_count++;
                console.log("app.trace.on_new_point visited_new_tiles_count", visited_new_tiles_count)
                sound.play();
                L.rectangle(tile.bounds, trace_tiles_style).addTo(layer_tiles);
                dispatch("new_tiles_changed")
            }
        }
    })

    function visited_new_tiles__redisplay() {
        layer_tiles.clearLayers();
        visited_new_tiles_count = 0;
        console.log(trace_tiles.tiles)
        for (const [x, y] of trace_tiles.tiles) {
            if (!visited_tiles.has(x, y)) {
                visited_new_tiles_count++;
                const bounds = boundsFromTile(x, y, tiles_configuration.level);
                L.rectangle(bounds, trace_tiles_style).addTo(layer_tiles);
            }
        }
        dispatch("new_tiles_changed");
    }

    tiles_configuration.on_tile_level_changed.subscribe((level, init) => {
        if (!init) {
            trace_tiles.recompute(trace.geojson());
        }
    })

    watch("precisionFactor_change", function(factor) {
        trace_tiles.precision_factor = factor
    })

    watch("visited_tiles_loaded", (/*init*/) => {
        visited_new_tiles__redisplay();
    })

    trace_tiles.on_tiles_updated.subscribe(visited_new_tiles__redisplay);
    trace_tiles.initialize(mymap);


    {
        watch("tiles_config_changed", (conf/*, user, init*/) => {
            visited_tiles.load(conf)
        })

        document.getElementById("reload").addEventListener("click", function() {
            visited_tiles.load(tiles_configuration.configuration)
        })

    }

    { // ############ OFFLINE MAP ############
        document.getElementById('offline-download').onclick = function() {
            console.log(">> offline-download")
            if (!routes.selection) return
            load_offline_tiles(routes.selection, tiles_configuration.level || 14, mymap)
        }
    }

    // ############ FOLLOW ############
    {
        let is_lock = false
        let is_watch_move = false
        let last_position = false
        let last_move = false

        watch("geoloc_button", () => {
            is_lock = true
            if (last_position) {
                is_watch_move = true
                mymap.setView(last_position);
            } else {
                // will be set on next position from watch
            }
        })

        watch("geoloc_start", () => {
            is_lock = true
        })

        watch("geoloc_pos", function(pos) {
            if (document.getElementById('forcelock').checked) {
                if (pos.timestamp - last_move > 10000) {
                    is_lock = true
                }
            }

            var new_pos = [pos.coords.latitude, pos.coords.longitude]
            if (is_lock) {
                is_watch_move = true
                if (!last_position && (mymap.getZoom()<15)) {
                    mymap.setView(new_pos, 15);
                } else {
                    mymap.setView(new_pos);
                }
            }
            last_position = new_pos
        })

        mymap.on("movestart", function(/*event*/) {
            if (is_watch_move == false) {
                last_move = Date.now()
                if (is_lock) {
                    is_lock = false
                }
            }
        });

        mymap.on("moveend", function(/*event*/) {
            is_watch_move = false
        });

    }

    // ############ OPTIONS DIALOG ###########
    var settings_modal = new bootstrap.Modal(document.getElementById('modal-options'), { keyboard: true })

    document.getElementById("squadrats-center").addEventListener("click", () => {
        settings_modal.hide()
    })

    var trace_reset_modal = document.getElementById('modal-trace-reset')
    {
        L.easyButton('fa-cog', function(/*btn, map*/) {
            settings_modal.show();
        }).setPosition("bottomleft").addTo(mymap);
    }

    var log_modal = new bootstrap.Modal(document.getElementById('modal-log'), { keyboard: true })
    document.getElementById('log-open').onclick = function(){
        // document.getElementById('log-content').innerHTML = bugout.getLog().replaceAll("\n", "<br/>")
        log_modal.show();
    }
    document.getElementById('log-mail').onclick = function(){
        const log = Array.from(document.getElementById('log-content').children).map(x => x.innerText).join("\n")
        var sMailTo = 'mailto:benoit.bouillard@gmail.com?subject='+encodeURIComponent('JJAB log') + '&body=' + encodeURIComponent(log)
        window.location.href = sMailTo
    }

    // ############ ROUTES DIALOG ###########
    {
        L.easyButton({
            id: "sidebar-open",
            icon: 'fa-route',
            states:[{                 // specify different icons and responses for your button
                stateName: 'get-center',
                onClick: function(){
                        sidebar_toggle(true)
                },
                icon: 'fa-route'
            }]
        }).setPosition("bottomright").addTo(mymap);
    }

    // ############ ROUTES ###########
    {
        /*     ROUTES MGNT       */
        var routing_service = new Route_Brouter()

        watch("routes_list_add", (route) => {
            route.routing_service = routing_service;
        });

        watch("routing_service_change", (service) => {
            console.log("Set routing service", service)
            if (service == "ign") {
              routing_service = new Route_Ign()
            } else {
              routing_service = new Route_Brouter()
            }
            for (const r of routes.routes) {
                r.routing_service = routing_service
            }

        });

        document.getElementById('edit-mode').checked = false
        document.getElementById('edit-mode').disabled = true

        watch("routes_list_change_selection", (route) => {
            if (route) {
                document.getElementById('edit-mode').disabled = false
            } else {
                document.getElementById('edit-mode').checked = false
                document.getElementById('edit-mode').disabled = true
            }
        })


        document.getElementById('routes_list-new').onclick = () => {
            const route = new Route();
            routes.add(route);
            routes.select(route);
            if (!document.getElementById('edit-mode').checked) {
                document.getElementById('edit-mode').click()
            }
        }


        addLiveEventListener(document.getElementById('routes_list'), "click", ".route-tr", function() {
            if (routes.selection && (routes.selection === this.route)) {
                return
            }
            routes.select(this.route);
        })

        on_init(()=> {
            routes.init();
        })

        var modal_marker = new bootstrap.Modal(document.getElementById('modal-marker-action'))

        var all_routes_tiles = new TilesStorage()
        var all_routes_new_tiles = new TilesStorage()

        var selected_route_tiles = new TilesStorage()
        var selected_route_new_tiles = new TilesStorage()

        { // TILES ROUTES
        /*
        input:
        - routes
        - visited_tiles
        - mymap
        */
            const tiles_from_routes = function() {
                all_routes_new_tiles = new TilesStorage()
                all_routes_tiles = new TilesStorage()
                selected_route_tiles = new TilesStorage()
                selected_route_new_tiles = new TilesStorage()
                if (tiles_configuration.level) {
                    for (const route of routes.routes) {
                        const route_tiles = route.get_tiles(tiles_configuration.level);
                        if (route == routes.selection) {
                            selected_route_tiles.append(route_tiles);
                        } else {
                            all_routes_tiles.append(route_tiles);
                        }
                    }

                    for (const [x, y] of selected_route_tiles) {
                        if (!visited_tiles.has(x, y)) {
                            selected_route_new_tiles.add(x, y)
                        }
                    }
                    if (!document.getElementById('display_only_current').checked) {
                        for (const [x, y] of all_routes_tiles) {
                            if (!visited_tiles.has(x, y) && !selected_route_new_tiles.has(x, y)) {
                                all_routes_new_tiles.add(x, y)
                            }
                        }
                    }
                }
                dispatch("tiles_routes_updated");
            }

            watch(["routes_list_init_completed", "routes_list_remove", "routes_list_add",
                   "routes_list_change_selection", "route_tiles_changed", "visited_tiles_loaded",
                   "display_only_current"], tiles_from_routes);

            const geojson_routes_new_tiles = function() {
                if (tiles_configuration.level) {
                    var geojson_tiles = []
                    if (!document.getElementById('display_only_current').checked) {
                        for (const [x, y] of all_routes_new_tiles) {
                            if (!trace_tiles.has(x, y)) {
                                geojson_tiles.push(geojsonFromTile(x, y, tiles_configuration.level))
                            }
                        }
                    }
                    for (const [x, y] of selected_route_new_tiles) {
                        if (!trace_tiles.has(x, y)) {
                            geojson_tiles.push(geojsonFromTile(x, y, tiles_configuration.level))
                        }
                    }
                    return turf.featureCollection(geojson_tiles)
                }
                return false
            }

            var edit_layer_tiles = null
            var route_tiles_style = styles.route_tiles

            styleConfiguration.onStyleUpdated.subscribe((styles) => {
                route_tiles_style = styles.route_tiles
                if (edit_layer_tiles != null) {
                    edit_layer_tiles.setStyle(route_tiles_style.style)
                }
            })

            let edit_refresh_route_tiles_layer = delayed(()=>{
                console.log("REFRESH_ROUTE_TILES_LAYER")
                if (edit_layer_tiles) {
                  edit_layer_tiles.remove()
                }
                var tiles_geojson = geojson_routes_new_tiles()
                if (tiles_geojson) {
                    edit_layer_tiles = L.geoJSON(tiles_geojson, route_tiles_style).addTo(mymap)
                }
            });

            var route_line_style = styles.route_line
            var other_routes_style = styles.other_routes
            var route_line_edit_style = styles.route_line_edit
            var route_line_halo_style = styles.route_line_halo

            const routes_line_styles_apply_configuration = function() {
                route_line_style.filter = feature => feature.geometry.type=="LineString"
                other_routes_style.filter = feature => feature.geometry.type=="LineString"
                route_line_edit_style.filter = feature => feature.geometry.type=="LineString"

                route_line_halo_style.onEachFeature = function(feature, layer) {
                    if (feature.geometry.type=="LineString") {
                        layer.on("click", function(event) {
                            const routing_mode = event.originalEvent.altKey?"flyby":null;
                            routes.selection.waypoint_insert(feature, [event.latlng.lng, event.latlng.lat], routing_mode);
                            L.DomEvent.stopPropagation(event)
                        })
                    }
                }

                route_line_halo_style.pointToLayer = function(feature, latlng) {
                    const route = routes.selection
                    var index = route.indexOfFeature(feature);
                    var icon_conf = consts.markersIcons[feature.properties.routing || "flyby"]
                    if (index==0) {
                        icon_conf = {...icon_conf}
                        icon_conf.icon = 'fa-play'
                    }
                    if (index==route.geojson.features.length-1) {
                        icon_conf = {...icon_conf}
                        icon_conf.icon = 'fa-stop'
                    }
                    var icon_marker = L.ExtraMarkers.icon(icon_conf);
                    var draggable = feature.properties.routing != "gpx";
                    if ((index != 0  && route.geojson.features[index-2].properties.routing == "gpx")) {
                        draggable = false;
                    }
                    /*if ((index != route.geojson.features.length-1 && feature.properties.routing != "flyby" && route.geojson.features[index+2].properties.routing == "gpx")) {
                        draggable = false;
                    }*/
                    var marker = L.marker(latlng, { icon: icon_marker,  draggable: draggable, autopan: true, zIndexOffset:400 })
                    .on("moveend", function(/*event*/) {
                        route.waypoint_move(index, LeafletLatLngToGeojson(this.getLatLng()))
                    })
                    .on("click", function(event){
                        // Set values
                        for (const elt of document.querySelectorAll("#group-routing-mode input[data-value]")) {
                            elt.checked = elt.getAttribute("data-value") == feature.properties.routing
                            elt.onclick = function() {
                                route.waypoint_set_routing_mode(index, this.getAttribute("data-value"))
                                modal_marker.hide()
                            }
                        }
                        document.getElementById("marker-delete").disabled = index!=route.geojson.features.length-1 && index>0 && route.geojson.features[index-2].properties.routing == "gpx"
                        document.getElementById("marker-delete").onclick = function() {
                            route.waypoint_remove(index)
                            modal_marker.hide()
                        }
                        modal_marker.show()
                        L.DomEvent.stopPropagation(event)
                    })
                    return marker
                }
                route_line_edit_style.onEachFeature = route_line_halo_style.onEachFeature;

            }

            routes_line_styles_apply_configuration()

            styleConfiguration.onStyleUpdated.subscribe((styles) => {
                route_line_style = styles.route_line
                other_routes_style = styles.other_routes
                route_line_edit_style = styles.route_line_edit
                route_line_halo_style = styles.route_line_halo

                routes_line_styles_apply_configuration()
                console.log("[STYLES.ROUTES] route_refresh_layer")
                for (const route of routes.routes) {
                    route_refresh_layer(route)
                }
            })

            watch(["route_tiles_changed", "new_tiles_changed", "tiles_routes_updated", "display_only_current"], edit_refresh_route_tiles_layer);

            watch("route_changed", (route) => {
                route.get_tiles(tiles_configuration.level);
            });
        }

        const route_remove_layer = function(route) {
            if (route.layers) {
                for (const layer of route.layers) {
                    layer.remove();
                }
            }
            route.layers = []
        }

        const marker_km_style = {
            pointToLayer: (feature, latlng) => {
                const options = {
                    isAlphaNumericIcon : true,
                    text : feature.properties.distance.toLocaleString('fr-FR',{"maximumFractionDigits":1}),
                    innerIconAnchor: [0, 2],
                }
                if (feature.properties.distance - Math.floor(feature.properties.distance) > 0.001) {
                    options.text = (feature.properties.distance - Math.floor(feature.properties.distance)).toLocaleString('fr-FR',{"maximumFractionDigits":1}).substring(1);
                    options.borderWidth = 1;
                    options.iconSize = [18, 18];
                    options.iconAnchor = [9, 9];
                    options.innerIconAnchor= [0, 1];
                }
                const marker = L.marker(latlng, {icon: L.BeautifyIcon.icon(options)})
                .on("click", function(event){
                    if (document.getElementById('edit-mode').checked) {
                        const route = routes.selection
                        const route_feature = route.featureAtDistance(feature.properties.distance)
                        const routing_mode = event.originalEvent.altKey?"flyby":null;
                        routes.selection.waypoint_insert(route_feature, [event.latlng.lng, event.latlng.lat], routing_mode);
                    }
                    L.DomEvent.stopPropagation(event)
                })

                return marker;
            }
        };

        var markers_km_layer = null;
        var current_markers_km = null

        function update_marker_km_layer() {
            let markers = null
            if (document.getElementById('display-marker').checked && routes.selection) {
                markers = routes.selection.get_km_markers(mymap.getZoom())
            }
            if (current_markers_km != markers) {
                if (markers_km_layer) {
                    markers_km_layer.remove();
                }
                if (markers) {
                    markers_km_layer = L.geoJSON(markers, marker_km_style).addTo(mymap)
                }
                current_markers_km = markers;
            }
        }
        watch(["routes_list_select", "routes_list_unselect",
               "routes_selected_route_changed"], update_marker_km_layer);

        document.getElementById('display-marker').addEventListener("change", update_marker_km_layer);

        mymap.on("zoomend", update_marker_km_layer)


        const route_refresh_layer = function(route, hidden=false) {
            console.log("ROUTE_REFRESH_LAYER", route.id, route.name, routes.selection == route);
            route_remove_layer(route);
            if (routes.selection == route) {
                if (document.getElementById('edit-mode').checked) {
                    route.layers.push(L.geoJSON(route.geojson, route_line_halo_style).addTo(mymap))
                    route.layers.push(L.geoJSON(route.geojson, route_line_edit_style).addTo(mymap))
                } else {
                    route.layers.push(L.geoJSON(route.geojson, route_line_style).addTo(mymap))
                }
            } else {
                if (!document.getElementById('display_only_current').checked) {
                    route.layers.push(L.geoJSON(route.geojson, other_routes_style).addTo(mymap))
                }
            }
        }

        watch(["routes_list_select", "routes_list_unselect", "routes_list_add",
               "route_changing", "route_changed"], (route) => {
            route_refresh_layer(route);
        });

        watch("routes_list_remove", (route) => {
            route_remove_layer(route);
        });

        const edit_map_onclick = function(event) {
            if (! routes.selection) return
            routes.selection.waypoint_append(LeafletLatLngToGeojson(event.latlng))
        }

        document.getElementById('edit-mode').addEventListener("change", function() {
            dispatch("edit_mode_changed", this.checked )
            if (this.checked) {
                mymap.on("click", edit_map_onclick)
            } else {
                mymap.off("click", edit_map_onclick)
            }
            route_refresh_layer(routes.selection);
        })

        document.getElementById('display_only_current').addEventListener("change", function() {
            dispatch("display_only_current", this.checked )
            for (const route of routes.routes) {
                route_refresh_layer(route)
            }
        })

        document.getElementById('add-gpx').onchange = function() {
            console.log("add-gpx change")
            if (this.files.length==0) {
                return
            }
            var reader = new FileReader();
            reader.onload = function() {
                let geojson = false
                if (this.result.startsWith('{"type":"FeatureCollection"')) {
                    geojson = JSON.parse(this.result);
                } else {
                    var dom = (new DOMParser()).parseFromString(this.result, 'text/xml');
                    geojson = gpx_to_geojson(dom)
                }
                geojson.id = window.crypto.randomUUID();
                let route = new Route(geojson);
                route.modified = true
                routes.add(route);
                routes.select(route);
                route_center(route)
                document.getElementById('add-gpx').value = ""
            }
            reader.readAsText(this.files[0]);
        }

        document.getElementById('edit-import').onclick = () => {
            trigger(document.getElementById('add-gpx'),'click')
        }

         /*   CLOUD   */ {
            document.getElementById('cloud-open').onclick = () => {
                cloud_open(mymap.getCenter(), (geojson) => {
                    if (geojson) {
                        const route = new Route(geojson);
                        if (routes.add(route))
                        {
                            routes.select(route);
                            route_center(route);
                        } else {
                            if (window.confirm(i18next.t("route.already_open_confirm_duplicate_message"))) {
                                route.id = window.crypto.randomUUID();
                                route.geojson.modified = true;
                                routes.add(route);
                                routes.select(route);
                                route.center(route);
                            }
                        }
                    }
                })
            }

        }

    }

    let update_tiles_display_count = delayed(()=>{
        let t = ""
        if (visited_new_tiles_count>0) {
            t += visited_new_tiles_count.toString()
        }
        if ((visited_new_tiles_count>0) && (selected_route_new_tiles.count + all_routes_new_tiles.count>0)) {
            t += "/"
        }

        if (selected_route_new_tiles.count>0) {
            t += selected_route_new_tiles.count.toString()
        }
        if (all_routes_new_tiles.count>0) {
            t += "("+all_routes_new_tiles.count.toString()+")"
        }
        if (t!="") {
            t += "❏"
        }
        document.getElementById('tiles_count').textContent = t
    })

    watch(["tiles_routes_updated", "new_tiles_changed"], update_tiles_display_count)

    // ############ TRACE ############
    trace.init(mymap)

    watch("geoloc_pos", function (pos) {
        trace.add_point(pos);
    });

    document.getElementById("navigation-reset").onclick = function() {
        trace_reset_modal.showModal()
    }

    document.getElementById("navigation-reset-all").onclick = function() {
        console.log("Navigation reset all")
        trace_tiles.recompute(false);
        trace.delete();
    }

    document.getElementById("navigation-export").onclick = function () {
        if (trace.exists()) {
            const trace_geojson = trace.geojson();
            const name = `Trace JJAB du ${(new Date(trace_geojson.features[0].properties.times[0])).toLocaleString()}`;
            const filename = `JJAB_trace_${(new Date(trace_geojson.features[0].properties.times[0])).toISOString().replaceAll(":", "-")}.gpx`;
            const gpx = togpx(trace_geojson, {creator:"JJAB", metadata:{name:`Trace JJAB du ${name}`}});
            download(filename, gpx, "application/gpx+xml");
        }
    };

    processInputStorage();

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    for (const tooltipTriggerEl of tooltipTriggerList) {
        new bootstrap.Tooltip(tooltipTriggerEl)
    }

})
