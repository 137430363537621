import i18next from 'i18next';
import * as turf from '@turf/turf'
import { dispatch, download } from '../libs/helpers.js'
import togpx from '../libs/togpx'

import { cloud } from '../routes/jjab_cloud.js'
import { Route } from "../libs/route.js"

import routes from "../routes/jjab_routes.js"

export function route_rename(route) {
  const new_name = prompt(i18next.t("options.routes.prompt.rename"), route.name)
  if (new_name != null) {
    route.name = new_name
    route.tr.querySelector('.route-name').innerHTML = new_name
  }
}

export function route_reverse(route) {
    route.reverse();
}

export function route_duplicate(route) {
    let geojson = JSON.parse(JSON.stringify(route.geojson))
    geojson.id = window.crypto.randomUUID()
    geojson.modified = true
    route = new Route(geojson);
    routes.add(route);
    routes.select(route);
}

export function route_center(route) {
    var bbox = turf.bbox(route.geojson)
    if (bbox[0] != Infinity) {
        dispatch("mymap_fitBounds", [[bbox[1], bbox[0]], [bbox[3], bbox[2]]])
    }
}

export function route_export_gpx(route) {
    const path = route.compute_path()
    if (path==null) return
    var gpx = togpx(route.compute_path(), {creator:"jjab"})
    download(route.name + ".gpx", gpx, "application/gpx+xml");
}

export function route_export_jjab(route) {
    download(route.name + ".geojson", JSON.stringify(route.geojson), "application/json+geo");
}

export function route_cloud_revert(route) {
    if (window.confirm(`"${route.name}"\n` + i18next.t("options.routes.prompt.confirm_revert"))) {
        cloud.read(route.id)
        .then(function(data) {
            route.set_geojson(data.geojson, false)
        })
    }
}

export function route_cloud_remove(route) {
    if (window.confirm(`"${route.name}"\n` + i18next.t("options.routes.prompt.confirm_jjab_delete"))) {
        cloud.delete(route.id)
        .then(()=> {
            route.modified = true;
        })
    }
}

export function route_cloud_save(route) {
    route.modified = false;
    cloud.save(route)
    .then(() => {
    })
    .catch(() => {
        route.modified = true;
    });
}

export function route_remove(route) {
    if (window.confirm(`"${route.name}"\n` + i18next.t("route.delete_route_confirmation_message"))) {
        routes.remove(route);
    }
}


export function route_goto(route, action) {
    const start = route.geojson.features[0].geometry.coordinates
    const url_schemes = {
        "route-goto-applemaps": `https://maps.apple.com/?daddr=${start[1]},${start[0]}`,
        "route-goto-googlemaps": `https://www.google.com/maps/dir/?api=1&destination=${start[1]},${start[0]}`,
        "route-goto-waze": `https://www.waze.com/ul?ll=${start[1]},${start[0]}&navigate=yes`
    }
    const url = url_schemes[action]
    console.log("GOTO", url)
    window.open(url, "_blank");
}

/*export function route_cloud_share(route) {
}*/