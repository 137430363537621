"use strict";

import i18next from 'i18next';
import * as turf from '@turf/turf'

import { dispatch } from '../libs/helpers.js'
import { geojson_tiles } from '../libs/utils.js'
import { TilesStorage, TileFromCoord } from '../libs/utils.js'
import { mapSourcesManager } from '../map/tiles_layers_mgnt.js'


document.getElementById("offline").classList.add("d-none")

var active_worker = null;

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener("message", (event) => {
        // event is a MessageEvent object
        console.log(`The service worker sent me a message:`);
        console.log(event.data)
        if (event.data.fromAction == 'cache_clear') {
            if (event.data.result) {
                dispatch("notification", "offline_maps.notification.title", "offline_maps.notification.clear_done")
            } else {
                dispatch("notification", "offline_maps.notification.title", "offline_maps.notification.cache_empty")
            }
        }
        if (event.data.fromAction == 'cache_tiles') {
            if (event.data.result) {
                if (event.data.count == event.data.total) {
                    dispatch("notification", "offline_maps.notification.title", `${event.data.count} #offline_maps.notification.tiles_cached#`)
                } else {
                    dispatch("notification", "offline_maps.notification.title", `${event.data.count}/${event.data.total} #offline_maps.notification.tiles_cached#`)
                }

            } else {
                dispatch("notification", "offline_maps.notification.title", "offline_maps.notification.error", true)
            }
            document.getElementById("offline-download-button-icon").classList.remove("d-none")
            document.getElementById("offline-download-button-spinner").classList.add("d-none")
        }
        if (event.data.fromAction == 'cache_count') {
            document.getElementById("offline-count").textContent = event.data.count
        }
    });

    navigator.serviceWorker.ready.then((registration) => {
        console.log("SERVICE WORKER READY")
        document.getElementById("offline").classList.remove("d-none")
        active_worker = registration.active
        active_worker.postMessage({action:"cache_count"});

        document.getElementById('offline-clear').onclick = function() {
            if (window.confirm(i18next.t("offline_maps.clear_confirmation_message"))) {
                  active_worker.postMessage({action:"cache_clear"});
            }
        }
    });
}


export function load_offline_tiles(route, map_level, mymap) {
    console.log(">> offline-download")
    let tiles_urls = []

    document.getElementById("offline-download-button-icon").classList.add("d-none")
    document.getElementById("offline-download-button-spinner").classList.remove("d-none")

    let mapsLayers;
    if (document.getElementById("offline-maps").value == "all") {
        mapsLayers = mapSourcesManager.selected_layers;
    } else {
        mapsLayers = [mapSourcesManager.active_layer]
    }

    let tilesForLevels = new Map();

    if (document.getElementById("offline-source").value == "route") {
        if (!route) {
            document.getElementById("offline-download-button-icon").classList.remove("d-none")
            document.getElementById("offline-download-button-spinner").classList.add("d-none")
            return
        }
        const buffer_route = turf.buffer(route.geojson, 0.1 * 2**(17-map_level))
        console.log("buffer:", 0.1 * 2**(17-map_level))

        for (let level = map_level-5; level<=map_level; level++) {
            let tiles = geojson_tiles(route.geojson, level)
            console.log("for level", level, tiles.count, "before buffer")
            tiles.append(geojson_tiles(buffer_route, level))
            console.log("for level", level, tiles.count)
            tilesForLevels.set(level, tiles)
        }
    } else {
        const bounds = mymap.getBounds()
        let nw = bounds.getNorthWest();
        let se = bounds.getSouthEast();

        for (let level = map_level-5; level<=map_level; level++) {
            let tiles = new TilesStorage()
            const [nx, ny] = TileFromCoord(nw.lat, nw.lng, level)
            const [sx, sy] = TileFromCoord(se.lat, se.lng, level)
            for (let x=Math.min(nx, sx); x<=Math.max(nx, sx); x++) {
                for (let y=Math.min(ny, sy); y<=Math.max(ny, sy); y++) {
                    tiles.add(x, y);
                }
            }
            tilesForLevels.set(level, tiles);
        }
    }

    for (const layer of mapsLayers) {
        for (let level = map_level-5; level<=Math.min(map_level, layer.options.maxNativeZoom || layer.options.maxZoom); level++) {
            for (const [x, y] of tilesForLevels.get(level)) {
                let url = layer.getTileUrl({x: x, y: y, z:level})
                tiles_urls.push(url)
            }
        }
    }
    console.log("Tiles count:", tiles_urls.length)
    if (tiles_urls.length > 250) {
        const answer = confirm(tiles_urls.length+" tiles vont être téléchargées pour être mises en cache. Confirmez vous ?")
        if (answer == false) {
            document.getElementById("offline-download-button-icon").classList.remove("d-none")
            document.getElementById("offline-download-button-spinner").classList.add("d-none")
            return;
        }
    }

    active_worker.postMessage({action:"cache_tiles", urls:tiles_urls});

}

window.addEventListener('offline', function() {
    console.log('OFFLINE EVENT - hors ligne');
});

window.addEventListener('online', function() {
    console.log('ONLINE EVENT - connecté');
});
