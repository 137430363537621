"use strict";

import * as bootstrap from 'bootstrap';
import * as turf from '@turf/turf'
import i18next from 'i18next';

import { addLiveEventListener, empty, dispatch, watch  } from '../libs/helpers.js'
import isNumeric from 'is-number'

import user_configuration from '../models/user_configuration.js'

const ROUTES_URL = "https://bouillard.org/kikourou/routes/routes_v3.php"

var open_route_callback = false;
var import_modal = new bootstrap.Modal(document.getElementById('modal-import'), { keyboard: true })

class Cloud {
  request(request, payload=new Map(), silent_errors=[]) {
    const url = new URL(ROUTES_URL);
    let options = {
      method: "GET",
      cache: "no-cache",
      referrerPolicy: "no-referrer"
    };
    if (request == "put") {
        options.method = "POST";
    }
    payload.action = request;
    if (!("key" in payload)) {
        payload.key = user_configuration.cloud.key;
    }
    if (options.method=="GET") {
        for (const k in payload) {
          url.searchParams.append(k, payload[k]);
        }
    } else {
        options.body = new FormData();
        for (const k in payload) {
          options.body.set(k, payload[k]);
        }
    }
    return new Promise((resolve, reject) => {
        fetch(url, options)
        .then((response)=> response.json())
        .then((msg) => {
            console.log("Cloud.request received json");
            if (!msg.status) {
                console.log("Cloud.request status:False", silent_errors);
                if (!silent_errors.includes(msg.error)) {
                  let msg_error = "";
                  if (msg.error) {
                      msg_error = msg.error;
                  } else {
                      msg_error = "Exception:"+msg.error;
                  }
                dispatch("notification", "jjab.error.title", msg_error, true);
                }
                console.log("Cloud.request=>reject");
                reject(msg);
                return;
            }
            if ('message' in msg) {
                dispatch("notification", "jjab.message.title", msg.message);
            }
            resolve(msg);
        })
        .catch(() => {
            dispatch("notification", "jjab.error.title", "jjab.error.unhandled_error", true);
            reject("cloud.unhandle_error");
        });
    });
  }

  list() {
    return this.request("list");
  }

  delete(id) {
    return this.request("delete", {id: id } );
  }

  read(id) {
    return this.request("get", {id: id } );
  }

  rename(id, name) {
    return this.request("rename", {id: id, name: name } );
  }

  save(route) {
    return new Promise((resolve, reject) => {
      const self = this;
      console.log("Cloud.save");
      this.request("put", { geojson: JSON.stringify(route.geojson) }, ["jjab.error.route_wrong_idx"])
        .then((answer) => {
          route.idx = answer.idx;
          save_on_route_change(route);
          resolve(route);
        })
        .catch((answer) => {
          console.log("Cloud.save catch", answer.error);
          if (answer.error == "jjab.error.route_wrong_idx") {
            document.getElementById("wrong_idx_cancel").onclick = function () {
              reject(route);
            };

            document.getElementById("wrong_idx_duplicate").onclick = function () {
              route.id = 'route_' + Date.now();
              self.save(route)
                .then(() => resolve(route))
                .catch(() => reject(route));
            };

            document.getElementById("wrong_idx_revert").onclick = function () {
              self.load(route.id)
                .then((answer) => {
                  route.set_geojson(answer.geojson);
                  resolve(route);
                })
                .catch(() => reject(route));
            };

            document.getElementById("wrong_idx_force").onclick = function () {
              route.idx = answer.idx;
              self.save(route)
                .then((route) => {
                  resolve(route);
                })
                .catch(() => reject(route));
            };

            document.getElementById("jjab_save_wrong_idx").showModal();
          } else {
            reject(route);
          }
        });
    });
  }
}

export var cloud = new Cloud();

user_configuration.on_cloud_changed.subscribe((cloud) => {
    if (cloud) {
        document.querySelector('body').classList.add("with_cloud")
    } else {
        document.querySelector('body').classList.remove("with_cloud")
    }
    if (!cloud) { // COMPATIBILITY
        const key = localStorage.getItem("jjab_cloud_key")
        if (key) {
            user_configuration.setCloud({user: user_configuration.user, key: key})
            .then(() => {
            }, (rejected) => {
                if (rejected.error && (rejected.error == "api.error.invalid_user_token"))
                dispatch("notification", "jjab_cloud.notification.title", "jjab_cloud.notification.invalid_user_key", true);
            })
        }
    }
})

{ /*    KEY MGNT     */
    document.getElementById('cloud-key').onclick = function() {
        let key = ""
        if (user_configuration.cloud) {
            key = user_configuration.cloud.key
        }
        var new_key = prompt("Votre clef JJAB Cloud", key);
        if (new_key != null) {
            user_configuration.setCloud({user: user_configuration.user, key: new_key})
            .then(() => {
            }, (rejected) => {
                if (rejected.error) {
                    dispatch("notification", "jjab.error.title", rejected.error, true);
                }
            })
        }
    }
}

function save_on_route_change(route) {
    console.log("save_on_route_change")
    if (route.finished) {
        if (route.modified) {
            route.tr.classList.add("route-modified")
        } else {
            route.tr.classList.remove("route-modified")
        }
    } else {
        route.tr.classList.remove("route-modified")
    }
}



watch(["route_modified_status_changed", "route_rename", "routes_list_add_hmi", "route_changed", "route_changing"], function(route) {
    save_on_route_change(route);
});

export function cloud_open(map_center, open_callback) {
    open_route_callback = open_callback
    if (!user_configuration.cloud) {
        document.getElementById('cloud-key').click();
        open_route_callback(false)
        return
    }
    import_modal.show()
    document.getElementById('cloud-load').checked = true;

    const table = document.getElementById('jjab-list')
    const tbody = table.querySelector('tbody')
    empty(tbody)

    cloud.list()
    .then(data => {
        map_center = [map_center.lng, map_center.lat]

        for (const elt of data.routes) {
            var length = "";
            var dist = "-";
            if (elt.start_point) {
              var l = turf.lineString([elt.start_point, map_center])
              dist = turf.length(l).toFixed(2)
            }
            elt.start = dist
            if (elt.length != null) length = elt.length.toFixed(2);
            const date = new Date(elt.date*1000)

            const html = `<tr class="jjab-import" role="button" data-id="${elt.id}">
              <td class="" data-value="${elt.date}">${date.toLocaleString()}</td>
              <td class="route-name">${elt.name}</td>
              <td class="text-end">${length}</td>
              <td class="text-end">${dist}</td></tr>`
            tbody.insertAdjacentHTML('beforeend',html)
        }
        sort_table(table)
    })
    .catch(()=>{}) // Error manage by cloud management
}


addLiveEventListener(document.getElementById('jjab-list'), "click", '.jjab-import', (evt) => {
    if (document.getElementById('cloud-delete').checked) {
        if (window.confirm(i18next.t("route.delete_route_confirmation_message"))) {
            cloud.delete(evt.target.getAttribute("data-id"))
            .then(function() {
                evt.target.parentNode.removeChild(evt.target);
            })
            .catch(()=>{}) // Error manage by cloud management
        }
        return
    }
    if (document.getElementById('cloud-rename').checked) {
        let new_name = prompt(i18next.t("route.new_name_message"), evt.target.querySelector('.route-name').textContent);
        if (new_name != null) {
            cloud.rename(evt.target.getAttribute("data-id"), new_name)
            .then(function() {
                evt.target.querySelector('.route-name').textContent = new_name
            })
            .catch(()=>{}) // Error manage by cloud management
        }
        return
    }
    cloud.read(evt.target.getAttribute("data-id"))
    .then(function(data) {
        import_modal.hide()
        data.geojson.modified = false
        open_route_callback(data.geojson)
    })
    .catch(()=>{
        open_route_callback(false)
    }) // Error manage by cloud management
})

// ******** Table sort ***********

addLiveEventListener(document.getElementById('jjab-list-header'), "click", 'th[data-sort]', function(){
    var s = this.getAttribute("data-sorted")
    var table = this.closest("table")
    if (table.querySelector('th[data-sort][data-sorted]')) {
        table.querySelector('th[data-sort][data-sorted]').removeAttribute("data-sorted")
    }
    if (s=="asc") {
        s = "desc"
    } else {
        s = "asc"
    }
    this.setAttribute("data-sorted", s)
    localStorage.setItem(table.getAttribute('id')+"_sort", this.getAttribute("data-sort"))
    localStorage.setItem(table.getAttribute('id')+"_sorted", s)
    sort_table(table)
})

{
    var ls = localStorage.getItem("jjab-list_sort")
    if (ls) {
        var f = document.querySelector('#jjab-list th[data-sort="'+ls+'"]');
        f.setAttribute("data-sorted", localStorage.getItem("jjab-list_sorted"))
    }
}

function sort_table(table) {
    var th_sort = table.querySelector('th[data-sorted]')
    var index = [...th_sort.parentNode.children].indexOf(th_sort)
    var asc = th_sort.getAttribute("data-sorted") == "asc"
    const tbody = table.querySelector('tbody')
    var rows = [...tbody.querySelectorAll('tr')].sort(comparer(index))

    if (!asc){rows = rows.reverse()}
    for (const row of rows){
        tbody.append(row)
    }
}

function comparer(index) {
    return function(a, b) {
        var valA = getCellValue(a, index), valB = getCellValue(b, index)
        if (valA == valB) {
            var i2 = index==2?3:2
            valA = getCellValue(a, i2)
            valB = getCellValue(b, i2)
        }
        return isNumeric(valA) && isNumeric(valB) ? valA - valB : valA.toString().localeCompare(valB)
    }
}
function getCellValue(row, index) {
    //var td = row.children('td').eq(index)
    var td = row.children[index]
    if (td.hasAttribute('data-value')) {
        return td.getAttribute('data-value')
    }
    return td.textContent
}

