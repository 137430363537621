import { dispatch } from '../libs/helpers.js'


if (process.env.NODE_ENV === 'development') {
    console.log("******** DEBUG MODE *******")

    let longitude = 2;
    const debug =  function() {
        longitude += 0.001;
        dispatch("geoloc_pos", {coords: {"latitude":49.0002,"longitude":longitude, accuracy:5}, timestamp: Date.now()});
    }
    document.getElementById("header").onclick = debug;

}