"use strict";

import L from 'leaflet'
import * as turf from '@turf/turf'

export function TileFromCoord(lat, lon, level=17) {
    const n = Math.pow(2, level);
    const x = Math.floor(n * (lon + 180 ) / 360);
    const lat_r = lat*Math.PI/180;
    const y = Math.floor(n * ( 1 - ( Math.log( Math.tan(lat_r) + 1/Math.cos(lat_r) ) / Math.PI ) ) / 2);
    return [x, y];
}

export function coordsFromTile(x, y, level=17) {
    const n = Math.pow(2, level);
    const lat = Math.atan( Math.sinh( Math.PI * (1 - 2*y / n ) ) ) * 180.0 / Math.PI;
    const lon = x / n * 360.0 - 180.0;
    return [lat, lon];
}

export function LatLngFromTile(x, y, level=17) {
    const [lat, lon] = coordsFromTile(x, y, level)
    return L.latLng(lat, lon);
}
export function boundsFromTile(x, y, level=17) {
    return L.latLngBounds(LatLngFromTile(x, y, level), LatLngFromTile(x+1, y+1, level));
}

export function geometryFromTile(x, y, level=17) {
    const [lat1, lon1] = coordsFromTile(x, y, level)
    const [lat2, lon2] = coordsFromTile(x+1, y+1, level)
    return [
        [lon1, lat1],
        [lon1, lat2],
        [lon2, lat2],
        [lon2, lat1],
        [lon1, lat1]
    ]
}

export function geometryFromRect(x1, y1, x2, y2, level=17) {
    const [lat1, lon1] = coordsFromTile(x1, y1, level)
    const [lat2, lon2] = coordsFromTile(x2+1, y2+1, level)
    return [
        [lon1, lat1],
        [lon1, lat2],
        [lon2, lat2],
        [lon2, lat1],
        [lon1, lat1]
    ]
}

export function geojsonFromTile(x, y, level=17) {
    return turf.polygon([geometryFromTile(x, y, level)])
}
let COORDINATES_PRECISION= 7

function lon2squadrat(lon, z) {
    return (Math.floor((lon + 180) / 360 * Math.pow(2, z)));
}
function lat2squadrat(lat, z) {
    return (Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, z)));
}
function squadrat2lon(x, z) {
    return +(x / Math.pow(2, z) * 360 - 180).toFixed(COORDINATES_PRECISION);
}
function squadrat2lat(y, z) {
    const n = Math.PI - 2 * Math.PI * y / Math.pow(2, z);
    return +(180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)))).toFixed(COORDINATES_PRECISION);
}

export function LeafletLatLngToGeojson(latlng) {
    return [latlng.lng, latlng.lat]
}

function lines2geojson(lines, zoom) {
    return {
        type: 'FeatureCollection',
        features: lines.map(function (d) {
            return {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        d.map(function (e) {
                            return [ squadrat2lon(e.x, zoom), squadrat2lat(e.y, zoom)];
                        }),
                    ],
                },
            };
        }),
    };
}

export function generateGrid(bounds, zoom, limit=false) {
    const segments = [];
    let nw = bounds.getNorthWest();
    let se = bounds.getSouthEast();

    nw.x = lon2squadrat(nw.lng, zoom);
    nw.y = lat2squadrat(nw.lat, zoom);
    se.x = lon2squadrat(se.lng, zoom)+1;
    se.y = lat2squadrat(se.lat, zoom)+1;
    if (limit && (Math.abs((nw.x - se.x) * (nw.y - se.y)) > limit)) {
        return false
    }
    for (let x = nw.x; x < se.x; x++) {
        segments.push([{ x: x, y: nw.y }, { x: x, y: se.y }]);
    }
    for (let y = nw.y; y < se.y; y++) {
        segments.push([{ x: nw.x, y: y }, { x: se.x, y: y }]);
    }
    return lines2geojson(segments, zoom);
}

export class TilesStorage {
    constructor(t={}) {
        this.count = 0
        if (Array.isArray(t)) {
            this.tiles = {}
            for (const [x, y] of t) {
                this.add(x, y)
            }
        } else {
           this.tiles = t
           this.count_update()
        }
    }
    has(x, y) {
        if (x in this.tiles) {
            if (Array.isArray(this.tiles[x])) {
                return this.tiles[x].includes(y)
            } else {
                return y in this.tiles[x]
            }
        } else {
            return false
        }
    }

    add(x, y) {
        if (!(x in this.tiles)) {
            this.tiles[x] = []
        }
        if (this.tiles[x].includes(y)) {
            return false
        }
        this.tiles[x].push(y)
        this.count++
        return true
    }

    remove(x, y) {
        const index = this.tiles[x].indexOf(y);
        if (index > -1) {
            this.tiles[x].splice(index, 1);
            this.count--
        }
    }

    clear() {
        this.tiles = {}
        this.count = 0
    }

    append(other) {
        for (const [x, y] of other) {
            this.add(x, y)
        }
    }

    *[Symbol.iterator]() {
        for (let x in this.tiles) {
            if (Array.isArray(this.tiles[x])) { // for compatibility to avoid errors
                for (const y of this.tiles[x].toSorted()) {
                    yield [x*1, y];
                }
            }
        }
    }

    count_update() {
        var that = this
        this.count = 0
        for (let x in this.tiles) {
            if (Array.isArray(this.tiles[x])) { // for compatibility to avoid errors
                that.count+ this.tiles[x].length
            }
        }
    }

    list() {
        var l = []
        for (const [x, y] of this) {
            l.push([x, y])
        }
        return l
    }

    is_equal(other) {
        for (const [x, y] of this) {
            if (!other.has(x, y)) return false
        }
        for (const [x, y] of other) {
            if (!this.has(x, y)) return false
        }
        return true;
    }
}

export function geojson_tiles(geojson, level=17) {
    let tiles = new TilesStorage()
    var [px, py] = [false, false]
    let last_coord = false
    turf.coordEach(geojson, (coord) => {
        const [nx, ny] = TileFromCoord(coord[1], coord[0], level)
        if (last_coord) {
            if (Math.abs(px - nx) + Math.abs(py - ny) > 1) {
                let segment= turf.lineString([last_coord, coord])
                for (let x=Math.min(px, nx); x<=Math.max(px, nx); x++) {
                    for (let y=Math.min(py, ny); y<=Math.max(py, ny); y++) {
                        if (turf.booleanCrosses(geojsonFromTile(x, y, level), segment) ) {
                            tiles.add(x, y)
                        }
                    }
                }
            }
        }
        tiles.add(nx, ny)
        px = nx
        py = ny
        last_coord = coord
    })
    return tiles
}


