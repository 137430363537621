import { trigger, dispatch } from '../libs/helpers.js'


export function processInputStorage() {
    // GENERIC INPUT STORAGE
    for (const elt of document.querySelectorAll('input[type="checkbox"].persistent')) {
        let storage = localStorage.getItem(elt.id)
        if (storage == "true") {
            elt.checked = true;
            trigger(elt, "change")
        }
        elt.addEventListener("change", () => localStorage.setItem(elt.id, elt.checked))
    }

    for (const elt of document.querySelectorAll('input[type="range"].persistent')) {
        let storage = (localStorage.getItem(elt.id) || 1.0) * 1.0
        elt.value = storage;
        trigger(elt, "change")
        elt.addEventListener("change", () => localStorage.setItem(elt.id, elt.value));
    }

    for (const elt of document.querySelectorAll('select.persistent')) {
        let storage = localStorage.getItem(elt.id)
        var value = null
        if (storage) {
            value = storage
            elt.value = value;
        } else {
            value = elt.value
        }
        dispatch(elt.id+"_change", value, true)

        elt.addEventListener("change", function() {
            localStorage.setItem(elt.id, elt.value);
            dispatch(elt.id+"_change", elt.value, false);
        })
    }
}
