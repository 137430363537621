{
  "en": {
    "translation": {
      "content": {
        "yardin": "Yardin of"
      },
      "application": {
        "notification": {
          "title": "Application",
          "new_version": "A new version is available. Do you want to reload to use it?"
        },
        "name_request": "Enter your kikourou name"
      },
      "options": {
        "tiles": {
          "title": "Tiles",
          "level": {
            "title": "Tiles level",
            "both": "Both",
            "none": "None",
            "statshunters_user": "Statshunters",
            "statshunters_community": "Community"
          }
        },
        "navigation": {
          "title": "Navigation",
          "stop": "Stop",
          "erase": "Erase",
          "dialog_reset": {
            "message": "Erase trace?",
            "all": "Yes",
            "cancel": "Cancel"
          }
        },
        "routes": {
          "prompt": {
            "rename": "Route name:",
            "confirm_revert": "Are you sure to reload this route from cloud? Your modifications will be lost!",
            "confirm_jjab_delete": "Are you sure to delete this route of the cloud?"
          },
          "title": "Routes",
          "stop": "Stop",
          "erase": "Erase",
          "open": "Open",
          "new": "New",
          "save": "Save",
          "key": "JJAB cloud key…",
          "reverse": "Reverse route",
          "import": "Import…",
          "rename": "Rename",
          "duplicate": "Duplicate",
          "center": "Center the map",
          "revert": "Reload from cloud",
          "delete": "Delete from cloud",
          "share": "Share…",
          "gpx_export": "GPX export",
          "geojson_export": "geojson export for JJAB",
          "name": "Name",
          "edit": "Edit mode",
          "hide": "",
          "goto": {
            "title": "Navigate to start with:",
            "applemaps": "Apple Maps",
            "googlemaps": "Google Maps",
            "waze": "Waze"
          },
          "list": {
            "name": "Route name"
          },
          "tooltip": {
            "save": "Save route on cloud",
            "open": "Open a route from cloud",
            "new": "New route"
          }
        },
        "options": {
          "title": "Options",
          "error_margin": "Error margin factor",
          "no_sleep": "Avoid screen standby",
          "heading": "Heading line",
          "auto_center": "Automatic recenter",
          "sound": "Sound",
          "routing_service": "Routing service",
          "clusters_list": "Display clusters list",
          "display_elevation": ""
        },
        "maps": {
          "title": "Maps",
          "sources": "Map sources"
        },
        "offline_maps": {
          "title": "Offline maps",
          "cache_count": "tile(s) in cache"
        },
        "install": "Install App",
        "change_user": "Change user name",
        "help": "Help"
      },
      "no_sleep": {
        "notifications": {
          "title": "Avoid screen standby",
          "activated": "Activated",
          "release": "Deactivated",
          "unsupported": "Unsupported by your platform (OS or browser)",
          "error": "Error. Relaunch?"
        }
      },
      "import": {
        "name": "Title",
        "length": "Length",
        "start": "Start at",
        "open": "Open",
        "rename": "Rename",
        "delete": "Delete"
      },
      "markers": {
        "boar": "Boar",
        "track": "Track",
        "bike": "Bike",
        "road": "Road",
        "delete": "Delete",
        "cancel": "Cancel"
      },
      "route": {
        "delete_route_confirmation_message": "Delete route?",
        "new_name_message": "New name:",
        "already_open_confirm_duplicate_message": "This route is already open. Do you want to duplicate it?",
        "error": {
          "title": "Erreur d'itinéraire",
          "waypoint_append_gpx": "Impossible d'ajouter un point après un point de type GPX."
        }
      },
      "visited_tiles": {
        "notification": {
          "title": "Visited tiles",
          "loading_error": "File loading error"
        }
      },
      "jjab_cloud": {
        "delete_route_confirmation_message": "Delete route from cloud?",
        "notification": {
          "title": "JJAB Cloud",
          "invalid_user_key": "Wrong key for user. You have to revalidate cloud key with right user to be able to user this key with an other user"
        }
      },
      "offline_maps": {
        "clear_confirmation_message": "Delete map tiles in cache?",
        "notification": {
          "title": "Offline maps",
          "error": "An unexpected error occurs. Maps have not been saved !",
          "tiles_cached": "tiles have been put in cache",
          "clear_done": "Cache has been cleared",
          "cache_empty": "Cache was already empty!"
        }
      },
      "custom_maps": {
        "title": "Map sources",
        "name": "Name",
        "url":  "Url",
        "zoom": "Max zoom",
        "delete_source": "Confirm delete source ?"
      },
      "jjab": {
        "error": {
          "no_request": "No request",
          "no_action": "No action",
          "invalid_action": "Invalid action",
          "missing_argument": "Argument missing",
          "invalid_key_format": "Invalid key format",
          "invalid_key": "Invalid key",
          "invalid_id_format": "Invalid route id format",
          "route_not_found": "Route not found",
          "name_too_long": "Route name too long",
          "invalid_geojson": "Invalid geojson data",
          "rename_error": "Rename error"
        },
        "message": {
          "valid_key": "Valid key",
          "route_renamed": "Route renamed",
          "route_deleted": "Route deleted",
          "route_copied": "Route copied",
          "route_replaced": "Route replaced",
          "new_route_saved": "Route saved"
        },
        "dialog_wrong_idx": {
          "message": "Route has been modified on cloud. Select an action:",
          "cancel": "Cancel",
          "duplicate": "Duplicate",
          "revert": "Reload from cloud",
          "force": "Force saving on cloud"
        }
      },
      "api": {
        "error": {
          "desc": "Error",
          "no_user": "User don't exists",
          "invalid_user_token": "Invalid key"
        }
      },
      "notification": {
        "actions": {
          "yes": "Yes",
          "no": "No"
        }
      },
      "overlay": {
        "title": "Click to re-activated services:"
      },
      "privacy": {
        "open_button": "Privacy configuration",
        "dialog": {
          "message": "Privacy configuration",
          "ok": "Save privacy choice",
          "cancel": "Cancel"
        },
        "configuration": {
          "statshunters": {
            "title": "Statshunters",
            "activity_list": {
              "title": "Activity list",
              "user": {
                "title": "User",
                "generate_list": "Generation",
                "generate_list_long": "Generate your activity list"
              },
              "community": {
                "title": "Community",
                "with_user_name": "With your name",
                "with_user_name_long": "Display your name on community activity list. Otherwise as anonymous"
              },
              "activity_details": {
                "title": "Activity details",
                "with_title": "Title",
                "with_title_long": "Display title of your activities",
                "with_day": "Day",
                "with_day_long": "Display day of your activities",
                "with_time": "Hour",
                "with_time_long": "Display hour of your activities",
                "with_type": "Type",
                "with_type_long": "Display type of your activities",
                "with_distance": "Distance",
                "with_distance_long": "Display distance of your activities",
                "with_map": "Map",
                "with_map_long": "Display map with new tiles",
                "with_route_on_map": "With trace",
                "with_route_on_map_long": "Display the trace of your activity on the map",
                "with_strava_link": " Strava link",
                "with_strava_link_long": "Add a link to strava activity on the title"
              }
            }
          },
          "squadrats": {
            "title": "Squadrats",
                "share_link": "Génère vos données",
                "share_link_long": "Génère la carte et le lien vers votre dernière activité Squadrats"
          }
        },
        "notification": {
          "title": "Privacy configuration",
          "no_key": "JJAB key is requested",
          "no_username": "User name is requested",
          "server_connexion_error": "Internal error !!",
          "privacy_updated": "Configuration saved"
        }
      },
      "styles_configuration": {
        "options": {
          "title": "Styles",
          "selection": "Style",
          "default": "By default",
          "edit": "Edit"
        },
        "edit": {
          "title": "Edit styles",
          "delete_style": "Remove this style ?",
          "style_name": "Name",
          "close": "OK"
        }
      },
      "styles": {
        "trace": "Trace",
        "marker": "Position marker",
        "heading": "Headline",
        "trace_tiles": "New tiles",
        "route_tiles": "Route tiles",
        "grid_primary": "Primary grid",
        "grid_secondary": "Secondary grid",
        "main_route": "Current route",
        "other_route": "Other routes",
        "edit_route": "Edit route",
        "edit_halo_route": "Edit route (clickable halo)",
        "cluster": "Main cluster",
        "sub-cluster": "Sub-cluster",
        "others-tiles": "Other tiles",
        "max_square": "Max-Square",
        "max_square_sub": "Sub Max-Square",

        "color": "Color",
        "weight": "Width",
        "opacity": "Line opacity",
        "fillOpacity": "Fill opacity"
      }
    }
  },
  "fr": {
    "translation": {
      "content": {
        "yardin": "Le yardin de"
      },
      "application": {
        "notification": {
          "title": "Application",
          "new_version": "Une nouvelle version est disponible. Voulez vous recharger pour l'utiliser ?"
        },
        "name_request": "Entrer votre nom kikourou"
      },
      "options": {
        "tiles": {
          "title": "Carrés",
          "level": {
            "title": "Choix des carrés",
            "both": "Les deux",
            "none": "Aucun",
            "statshunters_user": "Statshunters",
            "statshunters_community": "Communauté"
          }
        },
        "navigation": {
          "title": "Navigation",
          "stop": "Arrêter",
          "erase": "Effacer",
          "dialog_reset": {
            "message": "Effacer la trace ?",
            "all": "Oui",
            "cancel": "Annuler"
          }
        },
        "routes": {
          "prompt": {
            "rename": "Nom du parcours:",
            "confirm_revert": "Êtes vous sur de vouloir recharger le parcours depuis le cloud ? Vos modifications seront perduent !",
            "confirm_jjab_delete": "Êtes vous sur de vouloir supprimer ce parcours du cloud ?"
          },
          "title": "Parcours",
          "stop": "Arrêter",
          "erase": "Effacer",
          "open": "Ouvrir…",
          "new": "Nouvelle",
          "save": "Enregistrer",
          "key": "Clé JJAB cloud…",
          "reverse": "Inverser le parcours",
          "import": "Importer…",
          "rename": "Renommer",
          "duplicate": "Dupliquer",
          "center": "Centrer la carte",
          "revert": "Recharger depuis le cloud",
          "delete": "Effacer du cloud",
          "share": "Partager…",
          "gpx_export": "Exporter en GPX",
          "geojson_export": "Exporter en geojson pour jjab",
          "goto": {
            "title": "Aller au départ avec:",
            "applemaps": "Apple Maps",
            "googlemaps": "Google Maps",
            "waze": "Waze"
          },
          "name": "Nom",
          "edit": "Edition",
          "hide": "",
          "list": {
            "name": "Nom du parcours"
          },
          "tooltip": {
            "save": "Enregistrer le parcours dans le cloud",
            "open": "Ouvrir un parcours du cloud",
            "new": "Créer un nouveau parcours"
          }
        },
        "options": {
          "title": "Options",
          "error_margin": "Marge d'erreur",
          "no_sleep": "Eviter la veille",
          "heading": "Ligne de visée",
          "auto_center": "Recentrace automatique",
          "sound": "Son",
          "routing_service": "Routage",
          "clusters_list": "Liste des clusters",
          "display_elevation": ""
        },
        "maps": {
          "title": "Cartes",
          "sources": "Sources de carte"
        },
        "offline_maps": {
          "title": "Carte hors-ligne",
          "cache_count": "tile(s) en cache"
        },
        "install": "Installer l'application",
        "change_user": "Changer de nom",
        "help": "Aide"
      },
      "no_sleep": {
        "notifications": {
          "title": "Éviter la veille",
          "activated": "Activé",
          "release": "Désactivé",
          "unsupported": "Non supporté par votre plateforme (OS ou navigateur)",
          "error": "Une erreur est arrivée. Relancer ?"
        }
      },
      "import": {
        "name": "Nom",
        "length": "Longueur",
        "start": "Départ à",
        "open": "Ouvrir",
        "rename": "Renommer",
        "delete": "Supprimer"
      },
      "markers": {
        "boar": "Sanglier",
        "track": "Chemin",
        "bike": "Vélo",
        "road": "Route",
        "delete": "Supprimer",
        "cancel": "Annuler"
      },
      "route": {
        "delete_route_confirmation_message": "Effacer l'itinéraire ?",
        "new_name_message": "Nouveau nom:",
        "already_open_confirm_duplicate_message": "Cette route est déjà ouverte. Voulez-vous la dupliquer ?",
        "error": {
          "title": "Erreur d'itinéraire",
          "waypoint_append_gpx": "Impossible d'ajouter un point après un point de type GPX."
        }
      },
      "visited_tiles": {
        "notification": {
          "title": "Carrés visité",
          "loading_error": "Erreur de chargemenet du fichier"
        }
      },
      "jjab_cloud": {
        "delete_route_confirmation_message": "Effacer l'itinéraire du cloud ?",
        "notification": {
          "title": "JJAB Cloud",
          "invalid_user_key": "La clé JJAB est invalide pour ce user. Vous devez revalider cette clé avec le bon nom d'utilisateur avant de pouvoir utiliser le Cloud avec un autre nom."
        }

      },
      "offline_maps": {
        "clear_confirmation_message": "Effacer les cartes en cache ?",
         "notification": {
          "title": "Offline maps",
          "error": "Une erreur est survenue. Les cartes n'ont pas été sauvegardées en cache !",
          "tiles_cached": "tiles ont été mise en cache",
          "clear_done": "Le cache a été effacé",
          "cache_empty": "Le cache était déjà vide"
        }
     },
      "custom_maps": {
        "title": "Sources de carte",
        "name": "Nom",
        "url":  "Url",
        "zoom": "Zoom max",
        "delete_source": "Confirmez la suppression de la source ?"
      },
      "jjab": {
        "error": {
          "title": "Erreur JJAB Cloud",
          "no_request": "Aucune requête",
          "no_action": "Aucune action",
          "invalid_action": "Action invalide",
          "missing_argument": "Argument manquant",
          "invalid_key_format": "Format de clé invalide",
          "invalid_key": "Clé invalide",
          "invalid_id_format": "Identifiant de parcours invalide",
          "route_not_found": "Parcours non trouvée",
          "name_too_long": "Nom de parcours trop long",
          "invalid_geojson": "Données geojson non valides",
          "rename_error": "Erreur de renommage",
          "unhandled_error": "Erreur non supportée"
        },
        "message": {
          "title": "JJAB Cloud",
          "valid_key": "Clé valide",
          "route_renamed": "Parcours renommé",
          "route_deleted": "Parcours supprimé",
          "route_copied": "Parcours copié",
          "route_replaced": "Parcours remplacé",
          "new_route_saved": "Nouveau parcours sauvegardé"
        },
        "dialog_wrong_idx": {
          "message": "L'itinéraire a été modifié sur le cloud entre temps. Que voulez vous faire ?",
          "cancel": "Annuler",
          "duplicate": "Dupliquer",
          "revert": "Recharger du cloud",
          "force": "Écraser sur le cloud"
        }
      },
      "api": {
        "error": {
          "desc": "Erreur",
          "no_user": "L'utilisateur n'existe pas",
          "invalid_user_token": "Clé invalide"
        }
      },
      "notification": {
        "actions": {
          "yes": "Oui",
          "no": "Non"
        }
      },
      "overlay": {
        "title": "Cliquer pour réactiver les services suivants :"
      },
      "privacy": {
        "open_button": "Confidentialité",
        "dialog": {
          "message": "Configuration de la confidentialité",
          "ok": "Sauvegarder vos choix",
          "cancel": "Annuler"
        },
        "notification": {
          "title": "Configuration de la confidentialité",
          "no_key": "La clé du JJAB est requise",
          "no_username": "Le nom est requis",
          "server_connexion_error": "Erreur interne !!",
          "privacy_updated": "Configuration enregistrée"
        },
        "configuration": {
          "statshunters": {
            "title": "Statshunters",
            "activity_list": {
              "title": "Activity list",
              "user": {
                "title": "Vous",
                "generate_list": "Génération",
                "generate_list_long": "Génère la liste de vos activités"
              },
              "community": {
                "title": "Communauté",
                "with_user_name": "Avec votre nom",
                "with_user_name_long": "Affiche votre nom a vos activités pour la communauté. Sinon en anonyme"
              },
              "activity_details": {
                "title": "Detail des activités",
                "with_title": "Avec le titre",
                "with_title_long": "Affiche le titre de votre activité",
                "with_day": "Avec le jour",
                "with_day_long": "Affiche le jour de votre activité",
                "with_time": "Avec l'heure",
                "with_time_long": "Affiche l'heure de votre activité",
                "with_type": "Avec le type",
                "with_type_long": "Affiche le type de votre activité",
                "with_distance": "Avec la distance",
                "with_distance_long": "Affiche la distance de votre activité",
                "with_map": "Avec la carte",
                "with_map_long": "Affiche la carte avec les nouveaux carrés",
                "with_route_on_map": "Avec la trace",
                "with_route_on_map_long": "Affiche la trace de votre activité sur la carte",
                "with_strava_link": "Lien strava",
                "with_strava_link_long": "Ajoute un lien vers l'activité strava sur le titre"
              }
            }
          },
          "squadrats": {
            "title": "Squadrats",
                "share_link": "Génère vos données",
                "share_link_long": "Génère la carte et le lien vers votre dernière activité Squadrats"
          }
        }
      },
      "styles_configuration": {
        "options": {
          "title": "Styles",
          "selection": "Style",
          "default": "Par défaut",
          "edit": "Modifier"
        },
        "edit": {
          "title": "Modifier les styles",
          "delete_style": "Voulez vous supprimer ce style ?",
          "style_name": "Nom",
          "close": "OK"
        }
      },
      "styles": {
        "trace": "Trace",
        "marker": "Marqueur de position",
        "heading": "Ligne de visée",
        "trace_tiles": "Nouveaux carrés",
        "route_tiles": "Carrés des parcours",
        "grid_primary": "Grille principale",
        "grid_secondary": "Grille secondaire",
        "main_route": "Route courante",
        "other_route": "Autres routes",
        "edit_route": "Route édition",
        "edit_halo_route": "Route édition (halo cliquable)",
        "cluster": "Cluster principal",
        "sub-cluster": "Sous-cluster",
        "others-tiles": "Autres carrés",
        "max_square": "Max-Square",
        "max_square_sub": "Sous Max-Square",

        "color": "Couleur",
        "weight": "Épaisseur",
        "opacity": "Opacité ligne",
        "fillOpacity": "Opacité remplissage"
      }
    }
  }
}