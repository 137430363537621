import * as L from "leaflet";

import * as turf from '@turf/turf'

import { geometryFromRect } from "../libs/utils.js"
import { dispatch, watch, addLiveEventListener, empty } from '../libs/helpers.js'
import tiles_configuration from '../models/tiles_configuration.js'
import { TilesStorage } from '../libs/utils.js'

import { getDBJsonFile } from '../models/json-cache.js'
import { styleConfiguration } from '../modules/styles-configuration/styles-configuration.js'

const RGBToHSL = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s
    ? l === r
      ? (g - b) / s
      : l === g
      ? 2 + (b - r) / s
      : 4 + (r - g) / s
    : 0;
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2,
  ];
};

const hex2rgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // return {r, g, b}
    return [ r, g, b ];
}


export function tiles_user_geojson(mymap) {
    let geojson_user_tiles = new Map();
    let showPopup = true
    let sub_cluster_colored = false
    let sub_cluster_color_index = 0
    var geojson_style = styleConfiguration.style.squadrats
    updateStyle();

    function updateStyle() {
        geojson_style.not_clusters.color = geojson_style.all.color
        const hsl = RGBToHSL(...hex2rgb(geojson_style.cluster.color))
        geojson_style.style = function(feature) {
            if (sub_cluster_colored) {
                if (feature.properties.kind == "sub-cluster" || feature.properties.kind == "cluster") {
                    if (feature.properties.kind == "cluster") {
                        sub_cluster_color_index = 0;
                    }
                    let s = {...geojson_style[feature.properties.kind]}
                    let h = (Math.floor(sub_cluster_color_index)*40 + hsl[0]) % 360
                    sub_cluster_color_index = (sub_cluster_color_index+1)
                    s.color = `hsl(${h},${hsl[1]}%,${hsl[2]}%)`
                    return s
                }
                else if (feature.properties.kind == "max_square" || feature.properties.kind == "max_square_sub") {
                    let s = {...geojson_style[feature.properties.kind]}
                    return s
                } else {
                    return geojson_style[feature.properties.kind]
                }
            } else {
                return geojson_style[feature.properties.kind]
            }
        }
        geojson_style.onEachFeature = function(feature, layer) {
            let desc = false
            if (showPopup) {
                if ((feature.properties.kind=="sub-cluster") || (feature.properties.kind=="cluster")) {
                    desc = `${feature.properties.size} carré(s)`
                }
                if ((feature.properties.kind=="max_square") || (feature.properties.kind=="max_square_sub")) {
                    desc = `${feature.properties.size}x${feature.properties.size}`
                }
                if (desc) {
                    layer.bindPopup(`${feature.properties.name || ""} <br/> ${desc}`)
                }
            }
        }
        geojson_style.filter = function(feature) {
            if (!sub_cluster_colored && feature.properties.kind=="max_square_sub") {
                return false
            }
            const style = geojson_style[feature.properties.kind]
            if ((style.opacity == 0 ) && (style.fillOpacity == 0)) {
                console.log("Remove feature")
                return false
            }
            return true
        }
    }

    styleConfiguration.onStyleUpdated.subscribe((styles) => {
        geojson_style = styles.squadrats
        updateStyle();
        for (const geojson of geojson_user_tiles.values()) {
            if (geojson) {
                geojson.layer.remove()
                geojson.layer = squadrats_display(geojson.geojson)
            }
        }
        /*if (edit_layer_tiles != null) {
        }*/
    })

    document.getElementById("sub-cluster-list-open").classList.add("d-none")
    document.getElementById('sub-cluster-color').onchange = function(){
        sub_cluster_colored = this.checked;
        if (tiles_configuration.configuration) {
            load_squadrats_tiles(tiles_configuration.configuration.geojson);
        }
        if (sub_cluster_colored) {
          document.getElementById("sub-cluster-list-open").classList.remove("d-none")
        } else {
          document.getElementById("sub-cluster-list-open").classList.add("d-none")
        }
    }

    function squadrats_display(data) {
        return L.geoJSON(data, geojson_style).addTo(mymap)
    }

    // center on cluster when click on button
    document.getElementById("squadrats-center").addEventListener("click", () => {
        for (const sq of geojson_user_tiles.values()) {
            if (sq) {
                for (const feature of sq.geojson.features) {
                    if ((feature.properties.size>0) && ((feature.properties.kind == "cluster") || (feature.properties.kind == "max_square"))) {
                        var bbox = turf.bbox(feature)
                        mymap.fitBounds([[bbox[1], bbox[0]], [bbox[3], bbox[2]]]);
                        return;
                    }
                }
            }
        }
    });

    addLiveEventListener(document.getElementById("sub-cluster-list"), "click", "a", function() {
        let feature = this.feature
        let bbox = turf.bbox(feature)
        mymap.fitBounds([[bbox[1], bbox[0]], [bbox[3], bbox[2]]]);
    });

    function update_cluster_list(features) {
        sub_cluster_color_index = 0
        const list_elt = document.getElementById("sub-cluster-list")
        empty(list_elt)
        if (!features) {
            document.getElementById("sub-cluster-list-open").classList.add("d-none")
            return
        }
        document.getElementById("sub-cluster-list-open").classList.remove("d-none")
        for (const feature of features.values()) {
            let html = false
            if ((feature.properties.kind=="sub-cluster") && (feature.properties.size>10)) {
                html = `sous-cluster ${feature.properties.size} ${feature.properties.name || ""}`
            }
            if (feature.properties.kind=="max_square_sub") {
                html = `sous-max-square ${feature.properties.size}x${feature.properties.size} ${feature.properties.name || ""}`
            }
            if (feature.properties.kind=="max_square") {
                html = `max-square ${feature.properties.size}x${feature.properties.size} ${feature.properties.name || ""}`
            }
            if (feature.properties.kind=="cluster") {
                html = `cluster ${feature.properties.size} ${feature.properties.name || ""}`
            }
            if (html) {
                let li = document.createElement('li')
                li.innerHTML = `<a class="dropdown-item" href="#">${html}</a>`
                li.firstElementChild.feature = feature
                list_elt.insertAdjacentElement('beforeend', li)
            }
        }
    }

    let load_squadrats_tiles_request_id = 1

    function load_squadrats_tiles(conf_geojson) {
        console.log("load_squadrats_tiles", conf_geojson)
        const this_load_squadrats_tiles_request_id = ++load_squadrats_tiles_request_id
        for (let gc of geojson_user_tiles.values()) {
            gc.layer.remove();
        }
        geojson_user_tiles.clear();
        update_cluster_list(null)

        for (let [level, url] of Object.entries(conf_geojson)) {
            url = url.replace(".geojson", "_v3.geojson")
            getDBJsonFile(url, (data) => {
                // successCallback
                if (this_load_squadrats_tiles_request_id != load_squadrats_tiles_request_id) return
                var cluster_find = false;
                for (const feature of data.features.values()) {
                    if (feature.properties.kind=="cluster") {
                        if (cluster_find) {
                            feature.properties.kind = "sub-cluster"
                        } else {
                            cluster_find = true
                        }
                    }
                    if (feature.properties.gen_geometry_tiles) {
                        let tiles = new TilesStorage(feature.properties.gen_geometry_tiles)
                        let geoms = []
                        var rect = null
                        for (const [x, y] of tiles) {
                            if (rect == null) {
                                rect = [x, y, x, y]
                            } else if ((rect[2] == x) && (rect[3] == y-1)) {
                                rect[2] = x
                                rect[3] = y
                            } else {
                                geoms.push(geometryFromRect(...rect, level))
                                rect = [x, y, x, y]
                            }
                        }
                        geoms.push(geometryFromRect(...rect, level))

                        feature.geometry = {type:"MultiPolygon", coordinates: [geoms]}
                    }
                }

                if (geojson_user_tiles.has(level)) {
                    geojson_user_tiles.get(level).layer.remove();
                }
                geojson_user_tiles.set(level, {'geojson': data, 'layer': squadrats_display(data)})
                dispatch("geojson_loaded");
                /*if (localStorage.getItem("map_center_"+user) == null) {
                    trigger(document.getElementById("squadrats-center"), "click")
                }*/
                if (sub_cluster_colored) {
                    update_cluster_list(data.features)
                }
            }, () => {
                // errorCallback
                dispatch("notification", "ERREUR", "Erreur dans le chargement du fichier geojson", true);
            })
        }
    }

    function toggle_popup(isShowPopup) {
        for (const tiles of geojson_user_tiles.values()) {
            tiles.layer.eachLayer(function (layer) {
                if (isShowPopup) {
                    if (!layer.getPopup()) {
                        let desc = false
                        if ((layer.feature.properties.kind=="sub-cluster") || (layer.feature.properties.kind=="cluster")) {
                            desc = `${layer.feature.properties.size} carré(s)`
                        }
                        if ((layer.feature.properties.kind=="max_square") || (layer.feature.properties.kind=="max_square_sub")) {
                            desc = `${layer.feature.properties.size}x${layer.feature.properties.size}`
                        }
                        if (desc) {
                            layer.bindPopup(`${layer.feature.properties.name || ""} <br/> ${desc}`)
                        }
                    }
                } else {
                    layer.unbindPopup()
                }
            })
        }
    }

    tiles_configuration.on_configuration_changed.subscribe((conf) => {
        load_squadrats_tiles(conf.geojson);
    });

    watch("edit_mode_changed", function(checked) {
        showPopup = !checked
        console.log("showPopup", showPopup)
        toggle_popup(showPopup)
    })

    document.getElementById("reload").addEventListener("click", function() {
        load_squadrats_tiles(tiles_configuration.configuration.geojson)
    })
}
