import { Observable } from '../libs/observable.js'
import { serverApi } from './serverApi.js'
//import { getUserPublicInfo } from './json-cache.js'
import jsonCache from './json-cache.js'

class UserConfigurationModel {
    constructor() {
        this._user = false;
        this._cloud = null; // key, user
        this.user_from_query_string = false;
        this.infos = {"name":"?","squadrats":false,"statshunters":false}
        this.on_user_changed = new Observable("UserConfiguration.on_user_changed");
        this.on_infos_updated = new Observable("UserConfiguration.on_infos_updated");
        this.on_cloud_changed = new Observable("Cloud.on_cloud_changed")
    }

    initialize(name_request) {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has('user')) {
            this._user = urlParams.get('user')
            this.user_from_query_string = true
        } else {
            if (localStorage.getItem("user")) {
                this._user = localStorage.getItem("user")
            } else {
                this._user = name_request()
                localStorage.setItem("user", this._user)
            }
        }
        this.on_user_changed.notify(this._user, true);
        const storage_cloud = localStorage.getItem("jjab_cloud")
        if (storage_cloud) {
            this._cloud = JSON.parse(storage_cloud)
        } else {
            this._cloud = null
        }
        this.on_cloud_changed.notify(this._cloud);
        return this.init_user_info(true);
    }

    get user() {
        return this._user;
    }

    get cloud() {
        return this._cloud;
    }

    setCloud(config) {
        return new Promise((resolve, reject) => {
            if (!config) {
                this._cloud = null
                localStorage.removeItem("jjab_cloud")
                this.on_cloud_changed.notify(this._cloud);
                resolve()
                return
            }

            serverApi.request({
                action: "user_check_key",
                token: config.key,
                name: config.user
            })
            .then((resolveResult) => {
                if (resolveResult.status) {
                    this._cloud = config
                    localStorage.setItem("jjab_cloud", JSON.stringify(config))
                    this.on_cloud_changed.notify(this._cloud);
                    resolve()
                } else {
                    reject(resolveResult)
                }
            }, (rejectResult) => {
                reject(rejectResult)
            })
        })
    }

    setUser(name) {
        console.log("[USER CONFIGURATION] setUser", name)
        this._user = name;
        localStorage.setItem("user", this._user)
        localStorage.removeItem("user-infos")
        this.on_user_changed.notify(this._user, false);
        return this.init_user_info(false)
    }

    init_user_info(init) {
        return new Promise((resolve, reject) => {
            jsonCache.getUserPublicInfo(this._user)
            .then((infos) => {
                console.log("[USER_INFO]", infos)
                this.infos = infos;
                this.on_infos_updated.notify(infos, init);
                resolve(this.infos)
            }, (rejectedData) => {
                console.log("[USER_INFO] init_user_info REJECT", this.infos)
                this.infos = {"name":this.user,"squadrats":false,"statshunters":false}
                this.on_infos_updated.notify(this.infos, init);
                console.log("[USER_INFO] ->reject")
                reject(rejectedData)
            })
        })
    }
}

export default new UserConfigurationModel()

