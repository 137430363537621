"use strict";

import L from 'leaflet'
import Browser from 'leaflet/src/core/Browser.js';
import * as Util from 'leaflet/src/core/Util.js';

L.TileLayer.Jjab = L.TileLayer.extend({
    initialize: function(url, options) {
        L.TileLayer.prototype.initialize.call(this, url, options);
    },
    getTileUrl: function(coords) {
        const data = {
          r: Browser.retina ? '@2x' : '',
          s: this._getSubdomain(coords),
          x: coords.x,
          y: coords.y,
          z: 'z' in coords ? coords.z : this._getZoomForUrl()
        };
        if (this._map && !this._map.options.crs.infinite) {
          const invertedY = this._globalTileRange.max.y - coords.y;
          if (this.options.tms) {
            data['y'] = invertedY;
          }
          data['-y'] = invertedY;
        }

        return Util.template(this._url, Util.extend(data, this.options));
    }
});
L.tileLayer.jjab = function(url, options) {
    return new L.TileLayer.Jjab(url, options);
}
