"use strict";

const service_url = "https://brouter.de/brouter"
const profile_url = service_url + "/profile"

const routing_modes = {
      "path": { profile: "shortest"},
      "bike": { profile: "trekking"},
      "road": { profile: null, config: new URL('../models/brouter_road.brf', import.meta.url)}
}

export class Route_Brouter {
    constructor() {
    }

    profile(routing_mode) {
        if (routing_mode.profile != null) {
            return Promise.resolve(routing_mode.profile)
        }
        return new Promise((resolve, reject) => {
            fetch(routing_mode.config)
            .then((response) => response.text())
            .then((profile) => {
                fetch(profile_url, {
                    method: "POST",
                    body: profile
                })
                .then((response)=> response.json())
                .then((json) => {
                    routing_mode.profile = json.profileid
                    resolve(json.profileid);
                })
                .catch((error) => {
                    reject(error)
                })
            })

        })
    }

    route(from_coord, to_coord, routing_mode) {
        const rm = routing_modes[routing_mode]

        return new Promise((resolve, reject) => {
            this.profile(rm)
            .then((profileId) => {
                const url = new URL(service_url);
                url.searchParams.append("alternativeidx", "0");
                url.searchParams.append("format", "geojson");
                url.searchParams.append("lonlats", `${from_coord[0]},${from_coord[1]}|${to_coord[0]},${to_coord[1]}`);
                url.searchParams.append("profile", profileId);

                fetch(url)
                .then((response)=> response.json())
                .then((json) => {
                    resolve(json.features[0].geometry);
                })
                .catch((error) => {
                    reject(error)
                })
            })
        })
    }
}