import { trigger } from '../libs/helpers.js'

import user_configuration from '../models/user_configuration.js'
import tiles_configuration from '../models/tiles_configuration.js'

// USER
user_configuration.on_user_changed.subscribe((user_name) => {
    const changeUserClasses = document.getElementById("changeUser").classList
    if (user_configuration.user_from_query_string) {
        changeUserClasses.add("d-none");
    } else {
        changeUserClasses.remove("d-none");
    }
    document.getElementById('name').textContent = user_name
    document.title = "JJAB "+user_name
})

// TILES
user_configuration.on_infos_updated.subscribe((infos, init) => {
    console.log("user_configuration.on_infos_updated", infos)
    tiles_configuration.configure(infos, init);

    var uil = document.querySelectorAll('option[data-user-info]');
    for (const v of uil) {
        for (const field of ["squadrats", "statshunters"]) {
            if (v.dataset.userInfo == field) {
                v.hidden = !infos[field]
                v.disabled = !infos[field]
            }
        }
    }
    uil = document.querySelectorAll('option[data-user-info]:checked[hidden]');
    for (const v of uil) {
        const p = v.parentElement;
        v.parentElement.value = p.querySelector('option:not([hidden])').value
        trigger(p, "change")
    }
});
