import { Observable } from '../libs/observable.js'

class AudioController {
    constructor() {
        this.audioBuffer = false;
        this.ctx = false;
        this.gainNode = false;
        this.on_play = new Observable("AudioController.on_play");
    }

    reset() {
        this.audioBuffer = false;
    }

    async play(volume=null) {
        if (!document.getElementById('play_sound').checked) {
            return null
        }

        console.log("[audio] play sound")
        if (this.audioBuffer == false) {
            console.log("[audio] Create audio buffer")
            this.ctx = new AudioContext();
            this.gainNode = new GainNode(this.ctx);
            this.gainNode.connect(this.ctx.destination);

            let response = await fetch(new URL('../media/valid.mp3', import.meta.url));
            let arrayBuffer = await response.arrayBuffer()
            this.audioBuffer = await this.ctx.decodeAudioData(arrayBuffer)
        }
        this.gainNode.gain.value = (volume!=null) ? volume : (document.getElementById('play_volume').value * 1.0);
        let sourceBuffer = this.ctx.createBufferSource();

        sourceBuffer.buffer = this.audioBuffer;
        sourceBuffer.connect(this.gainNode);
        sourceBuffer.start();
        console.log("[sound model] play:", this.ctx.state)
        this.on_play.notify(this.ctx)
    }
}

export default new AudioController()
