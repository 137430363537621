import i18next from 'i18next';
import { dispatch, traverse_object } from '../../libs/helpers.js'

import user_configuration from '../../models/user_configuration.js'

(function() {
    const API_URL = "https://bouillard.org/kikourou/api.php";
    let privacy_dialog_fields = document.getElementById("privacy_dialog_fields")

    async function privacy_configuration() {
        console.log("privacy_configuration START")
        if (privacy_dialog_fields.childElementCount > 0) return
        var response = await fetch(API_URL+"?action=get_privacy_config&token=lgOJGY-DTrVhJ-XQo7UJ", { cache: "no-cache" })
        var privacy_config = await response.json()
        privacy_config = privacy_config.privacy_config
        console.log(privacy_config);

        function traverse_configuration(config, node, path) {
            let entryPath;
            if (path === "") {
                entryPath = config.name;
            } else {
                entryPath = path +"."+ config.name;
            }
            if ("items" in config) {
                let sectionElt = document.createElement('div')
                sectionElt.className = "privacy_tree"
                let titleElt = document.createElement('div')
                titleElt.className = "privacy_title"
                titleElt.innerHTML = i18next.t("privacy.configuration." + entryPath + ".title")
                let contentElt = document.createElement('div')
                contentElt.className = "privacy_elements"
                sectionElt.appendChild(titleElt)
                sectionElt.appendChild(contentElt)
                node.appendChild(sectionElt)

                for (var value of config.items) {
                    traverse_configuration(value, contentElt, entryPath)
                }
            } else {
                if (config.type == "boolean") {
                    let sectionElt = document.createElement('div')
                    let inputElt = document.createElement('input')
                    let labelElt = document.createElement('label')
                    let longLabelElt = document.createElement('span')
                    inputElt.id = entryPath
                    inputElt.setAttribute("type", "checkbox")
                    labelElt.setAttribute("for", entryPath)
                    labelElt.innerHTML = i18next.t("privacy.configuration." + entryPath)
                    longLabelElt.innerHTML = " : " + i18next.t("privacy.configuration." + entryPath + "_long")

                    sectionElt.appendChild(inputElt)
                    sectionElt.appendChild(labelElt)
                    sectionElt.appendChild(longLabelElt)
                    node.appendChild(sectionElt)
                } else {
                    console.warn("Type not managed")
                }
            }
        }

        for (const elt of privacy_config) {
            traverse_configuration(elt, privacy_dialog_fields, "")
        }
        console.log("privacy_configuration END")

    }

    document.getElementById("open-privacy").onclick = async () => {
        if (!user_configuration.cloud) {
            return dispatch("notification", "privacy.notification.title", "privacy.notification.no_key", true);
        }
        await privacy_configuration();

        const url = new URL(API_URL);
        url.searchParams.append("action", "user_get_privacy");
        url.searchParams.append("token", user_configuration.cloud.key);
        url.searchParams.append("name", user_configuration.cloud.user);

        fetch(url, { cache: "no-cache" })
        .then((response)=> response.json())
        .then((json) => {
            if (json.status) {
                console.log("user_get_privacy answer")
                for (const el of privacy_dialog_fields.querySelectorAll("input")) {
                    el.checked = traverse_object(json.privacy, el.id) || false
                }
                document.getElementById("privacy_dialog").showModal();
            } else {
                return dispatch("notification", "privacy.notification.title", json.error, true);
            }
        })
        .catch(() => {
            return dispatch("notification", "privacy.notification.title", "privacy.notification.server_connexion_error", true);
        })
    }

    document.getElementById("privacy_dialog_ok").onclick = () => {
        const url = new URL(API_URL);

        function set_path_value(object, path, value) {
            for (const v of path.split('.').slice(0,-1)) {
                if (!(v in object)) {
                    object[v] = {}
                }
                object = object[v]
            }
            object[path.split('.').at(-1)] = value
        }
        let privacy = {}
        for (const el of privacy_dialog_fields.querySelectorAll("input")) {
            set_path_value(privacy, el.id, el.checked)
        }

        url.searchParams.append("action", "user_set_privacy");
        url.searchParams.append("token", user_configuration.cloud.key);
        url.searchParams.append("name", user_configuration.cloud.user);
        url.searchParams.append("privacy", JSON.stringify(privacy));

        fetch(url, { cache: "no-cache" })
        .then((response)=> response.json())
        .then((json) => {
            if (json.status) {
                return dispatch("notification", "privacy.notification.title", "privacy.notification.privacy_updated");
            } else {
                return dispatch("notification", "privacy.notification.title", json.error, true);
            }
        })
        .catch(() => {
            return dispatch("notification", "privacy.notification.title", "privacy.notification.server_connexion_error", true);
        })
    }

})()