"use strict";

import i18next from 'i18next';

import { addLiveEventListener } from '../libs/helpers.js'
import user_configuration from '../models/user_configuration.js'
import { OptionsStorage } from '../models/optionsStorage.js'
import { Observable } from '../libs/observable.js'

var map_sources = []; //JSON.parse(localStorage.getItem("map_sources")) || []

export const onCustomSourcesChanged = new Observable("onCustomSourcesChanged")

user_configuration.on_cloud_changed.subscribe(async () => {
    map_sources = await getMaps();
})

class CustomSourcesOption extends OptionsStorage {
    constructor() {
        super('custom_maps', JSON)
    }

    get defaultValue() {
        return []
    }

    conflictResolution() {
    var choice = confirm("Les sources de cartes ont été modifiées de façons différentes sur le serveur et en local. Récupérer les données du serveur ?")
    if (choice) {
            return "server"
        } else {
            return "local"
        }
    }
}

const customSourcesOption = new CustomSourcesOption()


async function getMaps() {
    let maps = await customSourcesOption.get()
    console.log("[CUSTOM_MAPS]", maps)
    if (maps == null) {
        maps = JSON.parse(localStorage.getItem("map_sources")) || []
        customSourcesOption.set(maps)
    }
    onCustomSourcesChanged.notify(maps)
    return maps;
}

async function setMaps(maps) {
    return await customSourcesOption.set(maps)
}

function map_sources_refresh_list() {
    const tbody = document.querySelector('#map_source-list tbody')
    while(tbody.firstChild) tbody.removeChild(tbody.firstChild)
    for (const [i, source] of map_sources.entries()) {
        const html = `<tr class="map-source" data-idx="${i}">
            <td class="map_source_edit" data-field="name">${source.name}</td>
            <td class="map_source_edit overflow-hidden" style="max-width:20em;" data-field="url">${source.url}</td>
            <td class="map_source_edit" data-field="maxNativeZoom">${source.maxNativeZoom}</td>
            <td class="map_source_delete" data-idx="${i}"><i class="bi bi-dash-square"></i></td>
            </tr>`
        tbody.insertAdjacentHTML('beforeend', html)
    }
}

document.getElementById('modalCustomMaps').addEventListener('show.bs.modal', function() {
    map_sources_refresh_list()
})

document.getElementById('modalCustomMaps').addEventListener('hide.bs.modal', async function() {
    map_sources = await setMaps(map_sources)
})

addLiveEventListener(document.querySelector('#map_source-list tbody'), "click", ".map_source_edit", function() {
    const idx = parseInt(this.parentNode.getAttribute('data-idx'))
    const field = this.getAttribute('data-field')
    const value = this.textContent
    const new_value = prompt("New value", value)
    if (new_value != null) {
        const intVal = parseInt(new_value)
        if (!isNaN(intVal)) {
            map_sources[idx][field] = intVal
        } else {
            map_sources[idx][field] = new_value
        }
        map_sources_refresh_list()
    }
})

document.getElementById('custom_maps_add').onclick = function() {
    map_sources.push({
        name: "new source",
        url: "source url",
        maxNativeZoom: 20,
        id: window.crypto.randomUUID()
    })
    map_sources_refresh_list()
}

addLiveEventListener(document.getElementById('map_source-list'), "click", "td.map_source_delete" , function() {
    if (window.confirm(i18next.t('custom_maps.delete_source'))) {
        const idx = parseInt(this.parentNode.getAttribute('data-idx'))
        map_sources.splice(idx, 1)
        map_sources_refresh_list()
    }
})
