"use strict";

import * as d3shape from "d3-shape"
import * as d3selection from "d3-selection"
import { scaleLinear } from "d3-scale"
import { range, extent, max, min } from "d3-array"
import { axisBottom, axisLeft } from "d3-axis"

import * as turf from '@turf/turf'

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/line-chart
function LineChart(data, {
  x = ([x]) => x, // given d in data, returns the (temporal) x-value
  y = ([, y]) => y, // given d in data, returns the (quantitative) y-value
  defined, // for gaps in data
  curve = d3shape.curveLinear, // method of interpolation between points
  marginTop = 20, // top margin, in pixels
  marginRight = 30, // right margin, in pixels
  marginBottom = 30, // bottom margin, in pixels
  marginLeft = 40, // left margin, in pixels
  width = 640, // outer width, in pixels
  height = 400, // outer height, in pixels
  xType = scaleLinear, // the x-scale type
  xDomain, // [xmin, xmax]
  xRange = [marginLeft, width - marginRight], // [left, right]
  yType = scaleLinear, // the y-scale type
  yDomain, // [ymin, ymax]
  yRange = [height - marginBottom, marginTop], // [bottom, top]
  yFormat, // a format specifier string for the y-axis
  yLabel, // a label for the y-axis
  color = "currentColor", // stroke color of line
  strokeLinecap = "round", // stroke line cap of the line
  strokeLinejoin = "round", // stroke line join of the line
  strokeWidth = 2, // stroke width of line, in pixels
  strokeOpacity = 1, // stroke opacity of line
} = {}) {
  // Compute values.
  const X = data.map(x);
  const Y = data.map(y);
  const I = range(X.length);
  if (defined === undefined) defined = (d, i) => !isNaN(X[i]) && !isNaN(Y[i]);
  const D = data.map(defined);

  // Compute default domains.
  if (xDomain === undefined) xDomain = extent(X);
  if (yDomain === undefined) yDomain = [min(Y), max(Y)];

  // Construct scales and axes.
  const xScale = xType(xDomain, xRange);
  const yScale = yType(yDomain, yRange);
  const xAxis = axisBottom(xScale).ticks(width / 80).tickSizeOuter(0);
  const yAxis = axisLeft(yScale).ticks(height / 40, yFormat);

  // Construct a line generator.
  const line = d3shape.line()
      .defined(i => D[i])
      .curve(curve)
      .x(i => xScale(X[i]))
      .y(i => yScale(Y[i]));

  const svg = d3selection.create("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;background-color:#FFFFFFA0");

  svg.append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(xAxis);

  svg.append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(yAxis)
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll(".tick line").clone()
          .attr("x2", width - marginLeft - marginRight)
          .attr("stroke-opacity", 0.1))
      .call(g => g.append("text")
          .attr("x", -marginLeft)
          .attr("y", 10)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .text(yLabel));

  svg.append("path")
      .attr("fill", "none")
      .attr("stroke", color)
      .attr("stroke-width", strokeWidth)
      .attr("stroke-linecap", strokeLinecap)
      .attr("stroke-linejoin", strokeLinejoin)
      .attr("stroke-opacity", strokeOpacity)
      .attr("d", line(I));

  return svg.node();
}

export function elevation_svg(geojson, elt) {
    let ele_data = []
    let d = 0
    let has_elevation = false
    let previous = null
    let ele = 0
    let elevation_start = null
    let elevation_max = null
    let elevation_min = null
    const filter = 5
    turf.coordEach(geojson, function (currentCoord) {
        if (previous && (elevation_start!=null)) {
            d += turf.distance(previous, currentCoord)
            if ((currentCoord[2] != undefined) && (previous[2] != undefined))
            {
                if (currentCoord[2] > elevation_max) {
                    elevation_max = currentCoord[2]
                }
                if (currentCoord[2] < elevation_min) {
                    elevation_min = currentCoord[2]
                }
                if ((currentCoord[2] <= elevation_max - filter) && (elevation_max > elevation_start)) {
                    //console.log("up ", elevation_start, "->", elevation_max)
                    if (elevation_start < elevation_max) {
                        ele += elevation_max - elevation_start
                    }
                    elevation_min = currentCoord[2]
                    elevation_start = elevation_max
                }
                if ((currentCoord[2] >= elevation_min + filter) && (elevation_min < elevation_start)) {
                    //console.log("down ", elevation_start, "->", elevation_min)
                    elevation_start = elevation_min
                    elevation_max = currentCoord[2]
                }
            }
        } else {
            if (currentCoord[2] != undefined) {
                elevation_start = currentCoord[2]
                elevation_max = currentCoord[2]
                elevation_min = currentCoord[2]
            }
        }
        if (currentCoord[2] != undefined) {
            has_elevation = true
        }
        ele_data.push([d, currentCoord[2]])
        previous = currentCoord
    });
    if ((elevation_max > elevation_start)) {
        //console.log("up ", elevation_start, "->", elevation_max)
        if (elevation_start < elevation_max) {
            ele += elevation_max - elevation_start
        }
    }

    if (has_elevation) {
        const box = elt.getBoundingClientRect();
        return LineChart(ele_data, {
          x: d => d[0],
          y: d => d[1],
          yLabel: "D+: "+ele.toFixed(0)+" m",
          width: box.width,
          height: box.height,
          color: "steelblue"
        })
    } else {
        return null
    }
}
