export function sidebar_toggle(value) {
    const sidebar_wrapper = document.getElementById("sidebar-wrapper")
    sidebar_wrapper.classList.toggle("slide-out", !value)
    sidebar_wrapper.classList.toggle("slide-in", value)
    const content_overlay = document.getElementById("content-overlay")
    content_overlay.classList.toggle("slide-out", !value)
    content_overlay.classList.toggle("slide-in", value)
}

export function sidebar_lock(value) {
    console.log("sidebar-lock", value)
    document.getElementById("sidebar-app").classList.toggle("sidebar-autohide", !value)
    const sidebar_wrapper = document.getElementById("sidebar-wrapper")
    sidebar_wrapper.classList.toggle("slide-out", !value)
    sidebar_wrapper.classList.toggle("slide-in", value)
    const content_overlay = document.getElementById("content-overlay")
    content_overlay.classList.toggle("slide-out", true)
    content_overlay.classList.toggle("slide-in", false)
}

document.getElementById("sidebar-lock").onclick = () => {
    sidebar_lock(document.getElementById("sidebar-app").classList.contains("sidebar-autohide"))
}

document.getElementById("sidebar-close").onclick = () => {
    if (document.getElementById("sidebar-app").classList.contains("sidebar-autohide")) {
        sidebar_toggle(false)
    } else {
        sidebar_lock(false)
    }
}

document.getElementById("content-overlay").onclick = () => {
    sidebar_toggle(false)
}
