"use strict";

import L from 'leaflet'

import { addLiveEventListener, empty  } from '../libs/helpers.js'
import { consts } from "../libs/consts.js"
import { onCustomSourcesChanged } from "../map/custom_sources.js"

// TILES SOURCES
function default_layers() {
    let dl = []
    for (const layer of consts.baseLayers) {
        if (layer.byDefault) {
          dl.push(layer.name)
        }
    }
    return dl
}

class MapSourcesManager {
    constructor() {
        this.mymap = null
        this.control_layer = null

        this.layers_name = []
        this.tile_layers = {}
        this.selected_layers = []
        this.active_layers = localStorage.getItem("active_layers")
        if (this.active_layers) {
            this.active_layers = JSON.parse(this.active_layers)
        } else {
            this.active_layers = default_layers()
        }
        this.customSources = []

        onCustomSourcesChanged.subscribe((data) => {
            this.customSources = data
            if (this.mymap) {
                this.refresh()
            }
        })
    }

    get active_layer() {
        return this.tile_layers[this.layer]
    }

    get layer() {
        return localStorage.getItem("layer")
    }

    set layer(value) {
        localStorage.setItem("layer", value)
    }

    addTo(mymap) {
        this.mymap = mymap

        mymap.on("baselayerchange", (event) => {
            this.layer = event.name
        })

        this.control_layer = L.control.layers({}, {}, {collapsed: false}).addTo(mymap);
        this.refresh()
    }

    refresh_layer_list() {
        const list_elt = document.getElementById("tiles-sources")
        empty(list_elt)
        const layers = [...consts.baseLayers, ...this.customSources];
        for (const layer of layers) {
            const html = `<div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" value="" id="${layer.name}" ${this.active_layers.includes(layer.name) ? 'checked' : ''}>
              <label class="form-check-label" for="${layer.name}">${layer.name}</label>
            </div>`
            list_elt.insertAdjacentHTML('beforeend', html)
        }
    }

    load_layers() {
        this.layers_name = []
        this.tile_layers = {}

        const layers = [...consts.baseLayers, ...this.customSources];
        for (const layer of layers) {
            this.layers_name.push(layer.name)
            if (!layer.maxZoom) {
                layer.maxZoom = layer.maxNativeZoom
            }
            this.tile_layers[layer.name] = L.tileLayer.jjab(layer.url, layer)
        }
    }

    refresh_layers() {
        if (this.layer) {
          if (this.layer in this.tile_layers) {
            this.tile_layers[this.layer].removeFrom(this.mymap);
          }
        }
        this.control_layer.remove()
        this.control_layer = L.control.layers({}, {}, {collapsed: false}).addTo(this.mymap);
        var al = this.layers_name.filter(name => this.active_layers.includes(name))
        if (al.length==0) {
            al = [...this.layers_name]
        }
        this.selected_layers = []
        for (const layer_name of al) {
            this.selected_layers.push(this.tile_layers[layer_name])
            this.control_layer.addBaseLayer(this.tile_layers[layer_name], layer_name)
        }
        if (!al.includes(this.layer)) {
            this.layer = al[0]
        }
        this.tile_layers[this.layer].addTo(this.mymap);
    }

    refresh() {
        this.refresh_layer_list()
        if (this.layer) {
          if (this.layer in this.tile_layers) {
            this.tile_layers[this.layer].removeFrom(this.mymap);
          }
        }
        this.load_layers()
        this.refresh_layers()
    }

    toggleLayer(layerId, value) {
        if (value) {
            if (!this.active_layers.includes(layerId)) {
                this.active_layers.push(layerId)
            }
        } else {
            this.active_layers = this.active_layers.filter(layer => layer != layerId)
        }
        localStorage.setItem("active_layers", JSON.stringify(this.active_layers))

        this.refresh_layers()
    }
}

export const mapSourcesManager = new MapSourcesManager()

addLiveEventListener(document.getElementById("tiles-sources"), "change", "input", function() {
    mapSourcesManager.toggleLayer(this.id, this.checked)
})
