import L from 'leaflet'

import { watch } from '../libs/helpers.js'
import { styleConfiguration } from '../modules/styles-configuration/styles-configuration.js'


export function marker_add(mymap) {
    "use strict";
    var marker_style = {}
    var marker = null

    styleConfiguration.onStyleUpdated.subscribe((styles) => {
        marker_style = styles.marker
        if (marker != null) {
            marker.setStyle(marker_style)
        }
    })

    watch("geoloc_pos", function(pos) {
        const coords = [pos.coords.latitude, pos.coords.longitude]
        if (marker) {
            marker.setLatLng(coords)
            marker.setRadius(pos.coords.accuracy)
        } else {
            marker = L.circle(coords, pos.coords.accuracy, marker_style).addTo(mymap)
        }
    })

    watch("geoloc_stop", () => {
        if (marker) {
            marker.remove()
            marker = null
        }
    })
}
