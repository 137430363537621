export class Observable {
  constructor(name=null) {
    this.name = name
    this.observers = [];
  }

  subscribe(func) {
    this.observers.push(func);
  }

  unsubscribe(func) {
    this.observers = this.observers.filter((observer) => observer !== func);
  }

  notify() {
    if (process.env.NODE_ENV === 'development') {
        console.log(`NOTIFY ${this.name}`)
    }
    this.observers.forEach((observer) => observer.apply(null, arguments));
  }
}