import deepEqual from "deep-equal";

import { database } from "./database.js";
import { serverApi } from "./serverApi.js";

export function DBFile_expiration() {
    database.open()
    .then(function (db) {
        const objectStore = database.getObjectStore(db, "jsonfiles");
        const expirationDate = Date.now() - 7 * 24 * 60 * 60;
        objectStore.openCursor().onsuccess = (event) => {
          const cursor = event.target.result;
            if (cursor) {
                if (cursor.value.lastAccess < expirationDate) {
                    cursor.delete();
                    console.log("[DBFile] delete", cursor.value.path, cursor.value.lastAccess);
                }
                cursor.continue();
            } else {
                console.log("No more entries!");
            }
        };
    });
}

async function fetchFile(path, date=0) {
    return new Promise((resolve, reject) => {
        serverApi.request({
            token: serverApi.API_PUBLIC_TOKEN,
            action: "get_file",
            path: path,
            date: date
        })
        .then((data) => {
            if (data.status) {
                resolve(data);
            } else {
                reject(data);
            }
        });
    });
}

export async function getDBFile(path, updateCallback, errorCallback) {
    console.log("[getDBFile] ", path);
    var dbFile = await database.get("jsonfiles", path);
    if (dbFile) {
        console.log("[getDBFile] dbFile OK", path);
        updateCallback(dbFile.content);
    } else {
        dbFile = {date: 0, content: null};
    }
    if (navigator.onLine) {
        try {
            const serverFile = await fetchFile(path, dbFile.date);
            if (serverFile.change) {
                console.log("[getDBFile] server change", path);
                dbFile = {path: path, date: serverFile.date, content: serverFile.content};
                updateCallback(dbFile.content);
            }
        } catch {
            console.log("[getDBFile] server error");
        }
    }
    if (dbFile.content) {
        dbFile.lastAccess = Date.now();
        database.set("jsonfiles", dbFile);
    } else {
        console.log("[getDBFile] errorCallback");
        errorCallback();
    }
}

export async function getDBJsonFile(path, updateCallback, errorCallback) {
    getDBFile(path, (content) => {
        updateCallback(JSON.parse(content));
    } , errorCallback);
}

async function fetchUserPublicInfo(user) {
    return new Promise((resolve, reject) => {
        serverApi.request({
            token: serverApi.API_PUBLIC_TOKEN,
            action: "user_public_info",
            name: user,
        })
        .then((data) => {
            if (data.status) {
                resolve(data);
            } else {
                reject(data);
            }
        });
    });
}

export async function getUserPublicInfo(name) {
    return new Promise(async (resolve, reject) => {
        const path = "user_public_info/"+name;
        var dbFile = await database.get("jsonfiles", path);

        if (dbFile) {
            console.log("[getDBFile] dbFile OK", path);
        } else {
            dbFile = {path: path,  content: null};
        }

        console.log("[getDBFile] ", name)
        if (navigator.onLine) {
            let errorData;
            try {
                const serverFile = await fetchUserPublicInfo(name);
                if (!deepEqual(serverFile.user, dbFile.content)) {
                    console.log("[getDBFile] server change", path);
                    dbFile = {path: path, content: serverFile.user, lastAccess: Date.now()};
                    database.set("jsonfiles", dbFile);
                }
            } catch(error) {
                errorData = error;
            }
            if (dbFile.content) {
                resolve(dbFile.content);
            } else {
                reject(errorData);
            }
        } else {
            if (dbFile.content) {
                dbFile.lastAccess = Date.now();
                database.set("jsonfiles", dbFile);
                resolve(dbFile.content);
            } else {
                reject({error:"api.error.offline"});
            }
        }

    });
}

export default {
    DBFile_expiration,
    getDBFile,
    getDBJsonFile,
    getUserPublicInfo
}