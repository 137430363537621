import { dispatch } from '../libs/helpers.js'
import { TilesStorage } from '../libs/utils.js'
import tiles_configuration from  '../models/tiles_configuration'
import { getDBJsonFile } from '../models/json-cache.js'

class VisitedTiles {
    constructor() {
        this.visited_tiles = new TilesStorage()
        this.level_tiles = false
    }

    load(tiles_conf) {
        if (tiles_configuration.level == false) {
            this.visited_tiles = new TilesStorage()
            dispatch("visited_tiles_loaded")
            return
        }
        getDBJsonFile(tiles_conf.json, (data) => {
            // successCallback
            this.visited_tiles = new TilesStorage(data)
            dispatch("visited_tiles_loaded")
        }, () => {
            // errorCallback
            dispatch("notification", "visited_tiles.notification.title", "visited_tiles.notification.loading_error", true)
        })
    }

    has(x, y) {
        return this.visited_tiles.has(x, y)
    }

}

export default new VisitedTiles()