import * as bootstrap from 'bootstrap';

import { watch, dispatch, get_template, addLiveEventListener } from '../../libs/helpers.js'

import { route_rename, route_reverse, route_duplicate, route_center, route_export_gpx,
         route_export_jjab, route_cloud_revert, route_cloud_remove, route_cloud_save,
         route_remove, route_goto/*, route_cloud_share*/ } from '../../controllers/routes.js'


watch("routes_list_add", (route) => {
    let tr = get_template("template-route");
    tr.querySelector(".route-name").innerText = route.name
    tr.route = route
    route.tr = tr
    const routes_list = document.getElementById('routes_list')
    routes_list.insertAdjacentElement('beforeend',tr)
    dispatch("routes_list_add_hmi", route);
});

watch("routes_list_remove", (route) => {
    route.tr.remove();
});

watch("routes_list_select", (route) => {
    route.tr.classList.add("selected");
});

watch("routes_list_unselect", (route) => {
    route.tr.classList.remove("selected");
});

const actions = {
    "route-rename": route_rename,
    "route-reverse": route_reverse,
    "route-duplicate": route_duplicate,
    "route-center": route_center,
    "route-export-gpx": route_export_gpx,
    "route-export-jjab": route_export_jjab,
    "route-cloud-revert": route_cloud_revert,
    "route-cloud-remove": route_cloud_remove,
    "route-cloud-save": route_cloud_save,
    //"route-cloud-share": route_cloud_share,
    "route-remove": route_remove,
    "route-goto-applemaps": route_goto,
    "route-goto-googlemaps": route_goto,
    "route-goto-waze": route_goto,
};

addLiveEventListener(document.getElementById('routes_list'), "click", '*[data-action]', (evt) => {
    const tr = evt.target.closest('tr');
    const dd = bootstrap.Dropdown.getInstance(tr.querySelector('[data-bs-toggle="dropdown"]'));
    if (dd) {
        dd.hide();
    }
    const action = evt.target.dataset.action;
    console.log(action);
    actions[action](tr.route, action);
    return true;
});