
import { dispatch, watch } from '../libs/helpers.js'
import { Route } from '../libs/route.js'
import { database } from '../models/database.js'

function route_save(route) {
    database.set('routes', route.geojson);
}

watch(["route_rename", "route_changed", "route_modified_status_changed", "route_tiles_changed"], (route) => {
    route_save(route);
});

class RoutesList {
  constructor() {
    this.routes = [];
    this.selection = null;
  }

  async init() {
    { // COMPAT load from localStorage
        let routes_list = JSON.parse(localStorage.getItem("routes_list")) || null;
        if (routes_list !== null) {
            for (const route_id of routes_list) {
                const geojson = JSON.parse(localStorage.getItem('routes__'+route_id))
                if (geojson) {
                    database.set("routes", geojson)
                } else {
                    dispatch("notification", "routes.error.title", "routes.error.lost", true);
                }
                localStorage.removeItem('routes__'+route_id)
            }
            localStorage.removeItem("routes_list")
        }
    }

    let selection = localStorage.getItem("routes_selection");

    const routes_list = await database.getAll('routes')

    for (const geojson of routes_list) {
        if (geojson) {
            const route = new Route(geojson);
            this._add(route);
            if (geojson.id == selection) {
                this.selection = route;
            }
        } else {
            dispatch("notification", "routes.error.title", "routes.error.lost", true);
        }
    }

    if (this.selection) {
        dispatch("routes_list_select", this.selection)
    }
    dispatch("routes_list_change_selection", this.selection)
    dispatch("routes_list_init_completed")


    watch("route_change_id", function(from_id, to_id) {
      if (this.selection.id == to_id) {
        this._save_selection();
      }
    }, this);

    watch("route_changed", function(route) {
      if (this.selection == route) {
        dispatch("routes_selected_route_changed", route);
      }
    }, this);

    watch("route_changing", function(route) {
      if (this.selection == route) {
        dispatch("routes_selected_route_changing", route);
      }
    }, this);

  }

  select(route) {
        const previous_selection = this.selection;
        this.selection = route
        if (previous_selection) {
            dispatch("routes_list_unselect", previous_selection);
        }
        if (route) {
            dispatch("routes_list_select", route);
        }
        dispatch("routes_list_change_selection", route);
        this._save_selection();
  }

  _save_selection() {
    if (this.selection) {
      localStorage.setItem("routes_selection", this.selection.id);
    } else {
      localStorage.removeItem("routes_selection");
    }
  }

  _add(route) {
    this.routes.push(route);
    dispatch("routes_list_add", route);
  }

  add(route) {
    for (const r of this.routes) {
        if (r.id == route.id) {
            return false;
        }
    }
    this._add(route);
    route_save(route);
    return true;
  }

  remove(route) {
    let index = this.routes.indexOf(route);
    this.routes.splice(index, 1);
    if (this.selection == route) {
      if (index>0) {
        this.select(this.routes[index-1])
      } else if (this.routes.length > index) {
        this.select(this.routes[index+1])
      } else {
        this.select(null);
      }
    }
    dispatch("routes_list_remove", route);
    database.remove('routes', route.id);
  }
}

export default new RoutesList()