import L from 'leaflet'
import * as turf from '@turf/turf'
import isNumeric from 'is-number'

import {styles } from "../libs/consts.js"
import { watch, unwatch } from '../libs/helpers.js'
import { styleConfiguration } from '../modules/styles-configuration/styles-configuration.js'

export function heading_add(mymap) {
    "use strict";

    let heading_layer = null
    let heading_style = styles.heading

    styleConfiguration.onStyleUpdated.subscribe((styles) => {
        heading_style = styles.heading
        console.log("[HEADING] set style", heading_style)
        if (heading_layer != null) {
            heading_layer.setStyle(heading_style.style)
        }
    })

    function heading_remove() {
        if (heading_layer) {
            heading_layer.remove()
            heading_layer = null
        }
    }

    let geoloc_pos_handler = false
    let geoloc_stop_handler = false
    document.getElementById('heading').addEventListener("change", function(){
        if (this.checked) {
            if (!geoloc_pos_handler) {
                geoloc_pos_handler = watch("geoloc_pos", (pos) => {
                    const coords = [pos.coords.longitude, pos.coords.latitude]
                    heading_remove()
                    if (isNumeric(pos.coords.heading) && (mymap.getZoom()>14)) {
                        const poly = turf.lineString([coords, [coords[0], coords[1] + 0.01]]);
                        const rotatedPoly = turf.transformRotate(poly, pos.coords.heading, {pivot: coords});
                        heading_layer = L.geoJSON(rotatedPoly, heading_style).addTo(mymap)
                    }
                })
            }
            geoloc_stop_handler = watch("geoloc_stop", heading_remove)
        } else {
            heading_remove()
            if (geoloc_pos_handler) {
                unwatch("geoloc_pos", geoloc_pos_handler);
                geoloc_pos_handler = false;
            }
            if (geoloc_stop_handler) {
                unwatch("geoloc_stop", geoloc_stop_handler);
                geoloc_stop_handler = false;
            }
        }
    })
}
