"use strict";

export const consts = {
    baseLayers: [
       {
            name: "OSM Fr",
            maxZoom: 20,
            maxNativeZoom: 19,
            attribution: false,
            default: true,
            byDefault: true,
            url: 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png'
        },
        {
            name: "OSM Cyclo",
            maxZoom: 20,
            attribution: false,
            byDefault: true,
            url: 'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png'
        },
        {
            name: "OSM Topo",
            maxZoom: 19,
            maxNativeZoom: 17,
            attribution: false,
            byDefault: true,
            url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png'
        },
        {
            name: "Swiss topo",
            url: 'https://wmts10.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg',
            attribution: false,
            byDefault: false,
            maxZoom: 20,
            maxNativeZoom: 19,
            format: 'image/png'
        },
        {
            name: "IGN carte",
            url: 'https://data.geopf.fr/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE={style}&TILEMATRIXSET=PM&FORMAT={format}&LAYER={layer}&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
            attribution: false,
            maxZoom: 20,
            maxNativeZoom: 19,
            layer: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2',
            format: 'image/png',
            default: true,
            byDefault: true,
            style: 'normal'
        },
        {
            name: "IGN topo",
            url: 'https://data.geopf.fr/private/wmts?apikey={apikey}&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE={style}&TILEMATRIXSET=PM&FORMAT={format}&LAYER={layer}&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
            attribution: false,
            maxNativeZoom: 16,
            maxZoom: 18,
            apikey: 'ign_scan_ws',
            layer: 'GEOGRAPHICALGRIDSYSTEMS.MAPS.SCAN25TOUR',
            format: 'image/jpeg',
            default: true,
            byDefault: true,
            style: 'normal'
        },
        {
            name: "Lim. Adm.",
            url: 'https://data.geopf.fr/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE={style}&TILEMATRIXSET=PM&FORMAT={format}&LAYER={layer}&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
            attribution: false,
            maxZoom: 16,
            apikey: 'an7nvfzojv5wa96dsga5nk8w',
            layer: 'LIMITES_ADMINISTRATIVES_EXPRESS.LATEST',
            format: 'image/png',
            byDefault: false,
            style: 'normal'
        },
        {
            name: "IGN Sat.",
            url: 'https://data.geopf.fr/wmts?service=WMTS&request=GetTile&version=1.0.0&tilematrixset=PM&tilematrix={z}&tilecol={x}&tilerow={y}&layer=ORTHOIMAGERY.ORTHOPHOTOS&format=image/jpeg&style=normal',
            attribution: false,
            maxZoom: 20,
            maxNativeZoom: 19,
            apikey: 'choisirgeoportail',
            format: 'image/jpeg',
            byDefault: true,
            style: 'normal'
        },
        {
            name: "Swiss Sat.",
            url: 'https://wmts10.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg',
            attribution: false,
            maxZoom: 20,
            maxNativeZoom: 19,
            byDefault: false,
            format: 'image/png'
        },
        {
            name: "ESRI Sat.",
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            attribution: false,
            byDefault: false,
            maxZoom: 19
        },
        {
            name: "Google Sat.",
            url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
            subdomains:['mt0','mt1','mt2','mt3'],
            attribution: false,
            byDefault: false,
            maxZoom: 20
        },
        {
            name: "Google Hybrid",
            url: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
            subdomains:['mt0','mt1','mt2','mt3'],
            attribution: false,
            byDefault: false,
            maxZoom: 20
        }
    ],

    oldLayers: [
        {
            name: "IGN Cassini",
            url: 'https://wxs.ign.fr/{apikey}/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE={style}&TILEMATRIXSET=PM&FORMAT={format}&LAYER={layer}&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
            url2: 'https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=GEOGRAPHICALGRIDSYSTEMS.CASSINI&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
            attribution: false,
            maxZoom: 15,
            apikey: 'an7nvfzojv5wa96dsga5nk8w',
            layer: 'GEOGRAPHICALGRIDSYSTEMS.CASSINI',
            format: 'image/jpeg',
            byDefault: false,
            style: 'normal'
        },
       {
            name: "Mapbox Route",
            maxZoom: 20,
            tileSize: 512,
            zoomOffset: -1,
            attribution: false,
            byDefault: false,
            url: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}{ratio}?access_token={token}',
            id: 'mapbox/streets-v11',
            ratio: (window.devicePixelRatio>=2)?"@2x":"",
            token_storage: "mapbox_token"
        },
        {
            name: "Mapbox Terrain",
            maxZoom: 20,
            tileSize: 512,
            zoomOffset: -1,
            attribution: false,
            url: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}{ratio}?access_token={token}',
            id: 'mapbox/outdoors-v11',
            ratio: (window.devicePixelRatio>=2)?"@2x":"",
            byDefault: false,
            token_storage: "mapbox_token"
        },
        {
            name: "Mapbox Sat.",
            maxZoom: 20,
            attribution: false,
            url: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}{ratio}?access_token={token}',
            id: 'mapbox/satellite-v9',
            ratio: (window.devicePixelRatio>=2)?"@2x":"",
            byDefault: false,
            token_storage: "mapbox_token"
        }
    ],

    panes: [
        {name:"grid", zIndex:250},
        {name:"squadrats", zIndex:300},
        {name:"tiles", zIndex:350},
        {name:"route", zIndex:520},
        {name:"trace", zIndex:550}
    ],

    markersIcons : {
          flyby: { icon: 'fa-paw',       markerColor: 'green',  shape: 'circle', prefix: 'fas' },
          path:  { icon: 'fa-running',   markerColor: 'blue',   shape: 'circle', prefix: 'fas' },
          bike:  { icon: 'fa-bicycle',   markerColor: 'yellow', shape: 'circle', prefix: 'fas' },
          road:  { icon: 'fa-car',       markerColor: 'red',    shape: 'circle', prefix: 'fas' },
          gpx:   { icon: 'fa-ellipsis-h',markerColor: 'black',   shape: 'circle', prefix: 'fas' }
    }
}

export const styles_configuration = [
    {
        title: {
            i18n: "styles.trace"
        },
        template: "template-styles-configuration-section-line",
        svg: {
            stroke: "trace.style.color",
            "stroke-opacity": "trace.style.opacity",
            "stroke-width": "trace.style.weight"
        },
        fields: [
            {path: "trace.style.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "trace.style.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "trace.style.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
        ]
    },
    {
        title: {
            i18n: "styles.marker",
        },
        template: "template-styles-configuration-section-circle",
        svg: {
            "fill": "marker.color",
            "stroke": "marker.color",
            "stroke-opacity": "marker.opacity",
            "stroke-width": "marker.weight",
            "fill-opacity": "marker.fillOpacity",
        },
        fields: [
            {path: "marker.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "marker.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "marker.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
            {path: "marker.fillOpacity", i18n: "styles.fillOpacity", template: "template-styles-configuration-percent"},
        ]
    },
    {
        title: {
            i18n: "styles.heading",
        },
        svg: {
            stroke: "heading.style.color",
            "stroke-opacity": "heading.style.opacity",
            "stroke-width": "heading.style.weight"
        },
        template: "template-styles-configuration-section-line",
        fields: [
            {path: "heading.style.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "heading.style.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "heading.style.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
        ]
    },
    {
        title: {
            i18n: "styles.trace_tiles",
        },
        template: "template-styles-configuration-section-rect",
        svg: {
            "fill": "trace_tiles.color",
            "stroke": "trace_tiles.color",
            "stroke-opacity": "trace_tiles.opacity",
            "stroke-width": "trace_tiles.weight",
            "fill-opacity": "trace_tiles.fillOpacity",
        },
        fields: [
            {path: "trace_tiles.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "trace_tiles.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "trace_tiles.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
            {path: "trace_tiles.fillOpacity", i18n: "styles.fillOpacity", template: "template-styles-configuration-percent"},
        ]
    },
    {
        title: {
            i18n: "styles.main_route",
        },
        svg: {
            stroke: "route_line.style.color",
            "stroke-opacity": "route_line.style.opacity",
            "stroke-width": "route_line.style.weight"
        },
        template: "template-styles-configuration-section-line",
        fields: [
            {path: "route_line.style.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "route_line.style.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "route_line.style.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
        ]
    },
    {
        title: {
            i18n: "styles.other_route",
        },
        template: "template-styles-configuration-section-line",
        svg: {
            stroke: "other_routes.style.color",
            "stroke-opacity": "other_routes.style.opacity",
            "stroke-width": "other_routes.style.weight"
        },
        fields: [
            {path: "other_routes.style.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "other_routes.style.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "other_routes.style.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
        ]
    },
    {
        title: {
            i18n: "styles.edit_route",
        },
        template: "template-styles-configuration-section-line",
        svg: {
            stroke: "route_line_edit.style.color",
            "stroke-opacity": "route_line_edit.style.opacity",
            "stroke-width": "route_line_edit.style.weight"
        },
        fields: [
            {path: "route_line_edit.style.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "route_line_edit.style.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "route_line_edit.style.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
        ]
    },
    {
        title: {
            i18n: "styles.edit_halo_route",
        },
        template: "template-styles-configuration-section-line",
        svg: {
            stroke: "route_line_halo.style.color",
            "stroke-opacity": "route_line_halo.style.opacity",
            "stroke-width": "route_line_halo.style.weight"
        },
        fields: [
            {path: "route_line_halo.style.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "route_line_halo.style.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "route_line_halo.style.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
        ]
    },

    {
        title: {
            i18n: "styles.route_tiles",
        },
        template: "template-styles-configuration-section-rect",
        svg: {
            "fill": "route_tiles.style.color",
            "stroke": "route_tiles.style.color",
            "stroke-opacity": "route_tiles.style.opacity",
            "stroke-width": "route_tiles.style.weight",
            "fill-opacity": "route_tiles.style.fillOpacity",
        },
        fields: [
            {path: "route_tiles.style.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "route_tiles.style.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "route_tiles.style.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
            {path: "route_tiles.style.fillOpacity", i18n: "styles.fillOpacity", template: "template-styles-configuration-percent"},
        ]
    },
    {
        title: {
            i18n: "styles.grid_primary",
        },
        template: "template-styles-configuration-section-line",
        svg: {
            stroke: "grid.primary.style.color",
            "stroke-opacity": "grid.primary.style.opacity",
            "stroke-width": "grid.primary.style.weight"
        },
        fields: [
            {path: "grid.primary.style.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "grid.primary.style.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "grid.primary.style.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
        ]
    },
    {
        title: {
            i18n: "styles.grid_secondary",
        },
        template: "template-styles-configuration-section-line",
        svg: {
            stroke: "grid.secondary.style.color",
            "stroke-opacity": "grid.secondary.style.opacity",
            "stroke-width": "grid.secondary.style.weight"
        },
        fields: [
            {path: "grid.secondary.style.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "grid.secondary.style.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "grid.secondary.style.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
        ]
    },
    {
        title: {
            i18n: "styles.cluster",
        },
        template: "template-styles-configuration-section-rect",
        svg: {
            "fill": "squadrats.cluster.color",
            "stroke": "squadrats.cluster.color",
            "stroke-opacity": "squadrats.cluster.opacity",
            "stroke-width": "squadrats.cluster.weight",
            "fill-opacity": "squadrats.cluster.fillOpacity",
        },
        fields: [
            {path: "squadrats.cluster.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "squadrats.cluster.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "squadrats.cluster.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
            {path: "squadrats.cluster.fillOpacity", i18n: "styles.fillOpacity", template: "template-styles-configuration-percent"},
        ]
    },
    {
        title: {
            i18n: "styles.sub-cluster",
        },
        template: "template-styles-configuration-section-rect",
        svg: {
            "fill": "squadrats.sub-cluster.color",
            "stroke": "squadrats.sub-cluster.color",
            "stroke-opacity": "squadrats.sub-cluster.opacity",
            "stroke-width": "squadrats.sub-cluster.weight",
            "fill-opacity": "squadrats.sub-cluster.fillOpacity",
        },
        fields: [
            {path: "squadrats.sub-cluster.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "squadrats.sub-cluster.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "squadrats.sub-cluster.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
            {path: "squadrats.sub-cluster.fillOpacity", i18n: "styles.fillOpacity", template: "template-styles-configuration-percent"},
        ]
    },
    {
        title: {
            i18n: "styles.others-tiles",
        },
        template: "template-styles-configuration-section-rect",
        svg: {
            "fill": "squadrats.all.color",
            "stroke": "squadrats.all.color",
            "stroke-opacity": "squadrats.all.opacity",
            "stroke-width": "squadrats.all.weight",
            "fill-opacity": "squadrats.not_clusters.fillOpacity",
        },
        fields: [
            {path: "squadrats.all.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "squadrats.all.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "squadrats.all.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
            {path: "squadrats.not_clusters.fillOpacity", i18n: "styles.fillOpacity", template: "template-styles-configuration-percent"},
        ]
    },
    {
        title: {
            i18n: "styles.max_square",
        },
        template: "template-styles-configuration-section-rect",
        svg: {
            "fill": "squadrats.max_square.color",
            "stroke": "squadrats.max_square.color",
            "stroke-opacity": "squadrats.max_square.opacity",
            "stroke-width": "squadrats.max_square.weight",
            "fill-opacity": "squadrats.max_square.fillOpacity",
        },
        fields: [
            {path: "squadrats.max_square.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "squadrats.max_square.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "squadrats.max_square.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
            {path: "squadrats.max_square.fillOpacity", i18n: "styles.fillOpacity", template: "template-styles-configuration-percent"},
        ]
    },
    {
        title: {
            i18n: "styles.max_square_sub",
        },
        template: "template-styles-configuration-section-rect",
        svg: {
            "fill": "squadrats.max_square_sub.color",
            "stroke": "squadrats.max_square_sub.color",
            "stroke-opacity": "squadrats.max_square_sub.opacity",
            "stroke-width": "squadrats.max_square_sub.weight",
            "fill-opacity": "squadrats.max_square_sub.fillOpacity",
        },
        fields: [
            {path: "squadrats.max_square_sub.color", i18n: "styles.color", template:"template-styles-configuration-color"},
            {path: "squadrats.max_square_sub.weight", i18n: "styles.weight", template: "template-styles-configuration-value"},
            {path: "squadrats.max_square_sub.opacity", i18n: "styles.opacity", template: "template-styles-configuration-percent"},
            {path: "squadrats.max_square_sub.fillOpacity", i18n: "styles.fillOpacity", template: "template-styles-configuration-percent"},
        ]
    },
]

export const styles = {
    squadrats : {
        pane: 'squadrats',
        all: {
            name: "carrés",
            weight : 2,
            opacity: 0.4,
            fillOpacity : 0,
            color : "#0000FF"
        },
        not_clusters: {
            name: "carrés",
            weight : 0,
            opacity: 0,
            fillOpacity : 0.15,
            color : "#0000FF"
        },
        cluster : {
            name: "yard(inho)",
            weight : 3,
            opacity: 0.4,
            fillOpacity : 0.4,
            color : "#CC6633",
        },
        "sub-cluster": {
            name: "clusters",
            weight : 0,
            opacity: 0.0,
            fillOpacity : 0.4,
            color : "#0000FF",
        },
        max_square: {
            name: "über",
            weight : 3,
            fillOpacity : 0,
            opacity : 0.7,
            color : "#008000"
        },
        max_square_sub: {
            name: "sub-über",
            weight : 2,
            fillOpacity : 0,
            opacity : 0.5,
            color : "#008000"
        },
        unique: {
            opacity:0,
            fillOpacity: 0
        }
    },

    grid : {
        primary: {
            pane: "grid",
            style: {
              color: "#bbbbbb",
              weight: 2,
              opacity: 0.7,
            }
        },
        secondary: {
            pane: "grid",
            style: {
              color: "#888888",
              weight: 3,
              opacity: 0.5,
            }
        },
    },

    trace_tiles: {
        pane: "tiles",
        color: '#008000',
        fillOpacity:0.2,
        weight:2
    },

    trace: {
        pane:"trace",
        style: {
            color:'#FF0000',
            weight: 3,
            opacity:0.9,
            radius: 1.5,
        }
    },

    marker: {
        pane:"trace",
        color:"#FF0000",
        opacity: 0.5,
        weight: 3,
        fillOpacity: 0.2
    },

    heading: {
        pane: "trace",
        style: {
            color: "#FFFF00",
            weight: 1,
            opacity: 1.0
        }
    },

    // Route
    route_tiles : {
        pane: "tiles",
        style:{
            color: '#FF00FF',
            opacity: 0.4,
            fillOpacity: 0.1,
            weight: 2
        }
    },

    other_routes : {
        pane: "route",
        style: {
            color: '#00FF80',
            opacity: 0.7,
            weight: 2
        }
    },
    route_line : {
        pane: "route",
        style: {
            color: '#00FFFF',
            opacity: 0.7,
            weight: 4
        }
    },
    route_line_edit : {
        pane: "route",
        style: {
            color : "#FF00FF",
            opacity: 0.7,
            weight: 4
        }
    },
    route_line_halo : {
        pane: "route",
        style: {
            color : "#FF00FF",
            opacity: 0.2,
            weight: 20
        }
    }
}
