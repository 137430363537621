import i18next from 'i18next';


class OverlayAction {
    constructor(elementId) {
        this.overlay = document.getElementById(elementId)
        this.overlay.onclick = () => { this.onclick() }
        this.callbacks = []
    }

    __show() {
        console.log("[OverlayAction] show")
        this.overlay.classList.toggle("d-none", false)
    }

    __hide() {
        console.log("[OverlayAction] hide")
        this.overlay.classList.toggle("d-none", true)
    }

    show(message, callback) {
        this.callbacks.push(callback)
        this.overlay.querySelector(".overlay-action-messages").innerHTML += "<div>" + i18next.t(message) + "</div>"
        this.__show()
    }

    onclick() {
        console.log("[OverlayAction] click")
        this.__hide()
        for (const callback of this.callbacks) {
            callback()
        }
        this.callbacks = []
        this.overlay.querySelector(".overlay-action-messages").innerHTML = ""
    }
}

export const overlayAction = new OverlayAction("overlay-action")

/*overlayAction.show("TEST", () => {
    console.log("overlay action OK")
})
*/