import { dispatch } from '../libs/helpers.js'


document.getElementById("install_app_container").classList.add("d-none")

if ('serviceWorker' in navigator) {

    const invokeServiceWorkerUpdateFlow = function (registration) {
        dispatch("notification", "application.notification.title", "application.notification.new_version", true, () => {
            if (registration.waiting) {
                // let waiting Service Worker know it should became active
                registration.waiting.postMessage({action: 'skip_waiting'})
            }
        })
    }

    console.log("import.meta.url:", import.meta.url)
    navigator.serviceWorker.register(new URL('../sw.js', import.meta.url), {type: 'module'})
    .then(registration => {
        console.log("[serviceWorker]", registration)
        if (registration.installing) {
            console.log('[serviceWorker] installing');
        } else if (registration.waiting) {
            console.log('[serviceWorker] installed');
            invokeServiceWorkerUpdateFlow(registration);
        } else if (registration.active) {
            console.log('[serviceWorker] active');
        }
        registration.addEventListener('updatefound', () => {
            console.log('[serviceWorker] Service Worker update detected!');
            if (registration.installing) {
                // wait until the new Service worker is actually installed (ready to take over)
                registration.installing.addEventListener('statechange', () => {
                    if (registration.waiting) {
                        // if there's an existing controller (previous Service Worker), show the prompt
                        if (navigator.serviceWorker.controller) {
                            invokeServiceWorkerUpdateFlow(registration)
                        } else {
                            // otherwise it's the first install, nothing to do
                            console.log('Service Worker initialized for the first time')
                        }
                    }
                })
            }
        });
    })


    let refreshing = false;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        console.log("[serviceWorker] controllerchange")
        if (!refreshing) {
            window.location.reload();
            refreshing = true;
        }
    })

    let deferredPrompt=null;

    window.addEventListener('beforeinstallprompt', (e) => {
        document.getElementById("install_app_container").classList.remove("d-none")
        deferredPrompt = e;
    });

    document.getElementById('installApp').addEventListener('click', async () => {
        if (deferredPrompt !== null) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                deferredPrompt = null;
                document.getElementById("install_app_container").classList.add("d-none");
            }
        }
    });

}
