import * as L from'leaflet'
import * as turf from '@turf/turf'
import { dispatch } from '../libs/helpers.js'
import {Observable} from '../libs/observable.js'
import { styleConfiguration } from '../modules/styles-configuration/styles-configuration.js'

// Display points as circle and not default marker


export var trace = (function () {
    var mymap = null;
    var idx = 0;
    var current_trace = null;
    var layers = null;
    var on_new_point = new Observable("trace.on_new_point");
    var trace_style = null;

    function update_style(styles) {
        trace_style = styles.trace
        trace_style.pointToLayer = function (feature, latlng) {
            return L.circleMarker(latlng, trace_style.style);
        };
        if (layers == null) {
            init_display()
        } else {
            for (const layer of layers) {
                layer.setStyle(trace_style.style)
            }
        }
    }
    update_style(styleConfiguration.style)

    styleConfiguration.onStyleUpdated.subscribe((styles) => {
        update_style(styles)
    })

    function display_current() {
        if (!mymap) return;
        const new_layer = L.geoJSON(current_trace, trace_style).addTo(mymap);
        if (layers.length > idx) {
            layers[idx].remove();
            layers[idx] = new_layer;
        } else {
            layers.push(new_layer);
        }
    }

    function commit(continue_last = false) {
        const last_trace = current_trace;
        current_trace = turf.multiPoint([], { times: [] });
        if (continue_last) {
            current_trace.geometry.coordinates.push(
                last_trace.geometry.coordinates.at(-1)
            );
            current_trace.properties.times.push(
                last_trace.properties.times.at(-1)
            );
        }
        idx++;
        save();
    }

    function save() {
        localStorage.setItem("trace_" + idx, JSON.stringify(current_trace));
    }

    function init_display() {
        if (!mymap) return
        console.log("[TRACE] init_display")
        layers = []
        for (;;) {
            const trace = localStorage.getItem("trace_" + idx);
            if (trace == null) {
                break;
            }
            current_trace = JSON.parse(trace);
            layers.push(
                L.geoJSON(current_trace, trace_style).addTo(mymap)
            );
            idx++;
        }
        idx--;
        if (idx >= 0) {
            dispatch("trace_loaded");
        }
    }

    return {
        init: function(map) {
            mymap = map;
            init_display();
        },
        on_new_point,

        exists: function () {
            return current_trace != null;
        },

        delete: function () {
            for (const layer of layers) {
                layer.remove();
            }
            while (idx >= 0) {
                localStorage.removeItem("trace_" + idx);
                idx--;
            }
            layers = [];
            current_trace = null;
        },

        add_point: function (pos) {
            if (pos.coords.accuracy > 25) {
                // if accuracy is not enough good, don't store the point
                return;
            }
            on_new_point.notify(pos)
            if (!current_trace) {
                commit();
            } else {
                if ((pos.timestamp - current_trace.properties.times.at(-1)) / 1000 > 60) {
                    // If last point is too old, restart a new line
                    commit();
                }
            }
            // Restart a new line if line is too long (for display optimization)
            if (current_trace.geometry.coordinates.length > 500) {
                commit(true);
            }
            // Add coords and timestamp to the geojson
            current_trace.geometry.coordinates.push([pos.coords.longitude, pos.coords.latitude]);
            current_trace.properties.times.push(pos.timestamp);
            // Change type to lineString if there is at least 2 points in the feature
            if ((current_trace.geometry.type == "MultiPoint") && (current_trace.geometry.coordinates.length > 1)) {
                current_trace.geometry.type = "LineString";
            }
            display_current();
            save();
        },

        geojson: function () {
            let geojson = turf.featureCollection([])
            let current_feature = null
            for (let i = 0; i <= idx; i++) {
                const feature = JSON.parse(localStorage.getItem("trace_" + i))
                if (current_feature) {
                    if (feature.properties.times[0] == current_feature.properties.times.at(-1)) {
                        current_feature.geometry.coordinates.pop()
                        current_feature.properties.times.pop()
                        current_feature.geometry.coordinates = current_feature.geometry.coordinates.concat(feature.geometry.coordinates)
                        current_feature.properties.times = current_feature.properties.times.concat(feature.properties.times)
                    } else {
                        geojson.features.push(current_feature)
                        current_feature = feature
                    }
                } else {
                    current_feature = feature
                }
            }
            if (current_feature) {
                geojson.features.push(current_feature)
            }
            return geojson;
        },

        last_date: function () {
            if (idx>=0) {
                return current_trace.properties.times.at(-1);
            } else {
                return 0;
            }
        }
    };
})();
