
import { clone, watch } from '../libs/helpers.js'

(function () {
    "use strict";

    var undo_list = new Map()
    var current_route = false
    document.getElementById("route-undo").classList.add("d-none")

    function undo_display_refresh() {
        var disabled = true
        if (current_route) {
            var undo_route = undo_list.get(current_route.id)
            disabled = (!undo_route) || (undo_route.length<=1)
        }
        document.getElementById("button-route-undo").disabled = disabled
    }

    watch("route_changed", (route) => {
        var undo_route = undo_list.get(route.id)
        undo_route.push(clone(route.geojson))
        if (undo_route.length>20) {
            undo_route.shift()
        }
        undo_display_refresh()
    });

    watch("route_change_id", (from_id, to_id) => {
        if (undo_list.has(from_id)) {
            undo_list.set(to_id, undo_list.get(from_id))
            for (let u of undo_list.get(to_id)) {
                u.id = to_id;
            }
            undo_list.delete(from_id)
        }
    });

    watch("routes_list_add", (route) => {
        undo_list.set(route.id, [clone(route.geojson)])
    });

    watch("routes_list_remove", (route) => {
        undo_list.delete(route.id)
    });

    watch("routes_list_change_selection", (route) => {
        current_route = route
        undo_display_refresh()
    });

    watch("edit_mode_changed", function(checked) {
        if (checked) {
          document.getElementById("route-undo").classList.remove("d-none")
        } else {
          document.getElementById("route-undo").classList.add("d-none")
        }
    })

    document.getElementById("button-route-undo").onclick = () => {
        if (current_route == false) return
        var undo_route = undo_list.get(current_route.id)
        var undo_elt = undo_route.pop()
        undo_elt = undo_route.pop()
        if (undo_elt) {
            current_route.set_geojson(undo_elt)
        }
    }

})();

