"use strict";


export function ready(eventHandler) {
  if (document.readyState !== 'loading') {
    eventHandler();
  } else {
    document.addEventListener('DOMContentLoaded', eventHandler);
  }
}

export let isString = value => typeof value === 'string' || value instanceof String;

export function clone(something) {
    return JSON.parse(JSON.stringify(something))
}

export function addLiveEventListener(el, eventName, selector, eventHandler) {
  const wrappedHandler = (e) => {
    if (e.stop_propagation) return;
    if (!e.target) return;
    const el = e.target.closest(selector);
    if (el) {
      const newEvent = Object.create(e, {
        target: {
          value: el
        }
      });
      if (eventHandler.call(el, newEvent)==true) {
        e.stopPropagation();
        e.stop_propagation = true
      }
    }
  };
  el.addEventListener(eventName, wrappedHandler);
  return wrappedHandler;
}

export function trigger(el, eventType) {
  if (typeof eventType === 'string' && typeof el[eventType] === 'function') {
    el[eventType]();
  } else {
    const event =
      typeof eventType === 'string'
        ? new Event(eventType, {bubbles: true})
        : eventType;
    el.dispatchEvent(event);
  }
}

export function dispatch(name) {
    if (process.env.NODE_ENV === 'development') {
        console.log("DISPATCH", name)
    }
    document.dispatchEvent(new CustomEvent(name, {detail: Array.prototype.slice.call(arguments, 1)}));
}

export function watch(name, callback, self=null) {
    const fct = function(event) {
        callback.apply(self, event.detail);
    };
    if (isString(name)) name = [ name ];
    for (const n of name) {
        document.addEventListener(n, fct);
    }
    return fct;
}

export function unwatch(name, fct) {
    if (isString(name)) name = [ name ];
    for (const n of name) {
        document.removeEventListener(n, fct);
    }
    return null;
}

export function empty(elt) {
    while(elt.firstChild) elt.removeChild(elt.firstChild)
}

export function on_init(callback, delay=1) {
    setTimeout(callback, delay)
}

export function delayed(fct, delay=1) {
    let timeout_id = null;
    return function() {
        const args = arguments
        if (timeout_id) {
            clearTimeout(timeout_id);
            timeout_id = false;
        }
        timeout_id = setTimeout(() => {
            Promise.resolve().then(() => {
                fct.apply(null, args);
            });
            timeout_id = null;
        }, delay);
    }
}

/*export function delayed_this(fct, delay=1) {
    let timeout_ids = new Map();
    return function() {
        const self = this
        if (timeout_ids.has(this)) {
            clearTimeout(timeout_ids.get(this));
            timeout_ids.delete(this);
        }
        timeout_ids.set(this, setTimeout(() => {
            fct.apply(self, arguments);
            timeout_ids.delete(self);
        }, delay));
    }
}*/

export function get_template(template_id){
  let template = document.getElementById(template_id);
  let clone = document.importNode(template.content, true);
  return clone.firstElementChild;
}

export function download(filename, text, content_type="application/gpx+xml") {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:'+content_type+';charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function traverse_object(object, path) {
    let parts = path.split('.')
    while (parts.length) {
        const v = parts.shift()
       /* if (create && !(v in object) && (parts.length>0)) {
            object[v] = {}
        }*/
        object = object[v]
        if (object === undefined) {
            break
        }
    }
    return object
}

export function traverse_object_set(object, path, value) {
    let parts = path.split('.')
    while (parts.length > 1) {
        const v = parts.shift()
        if (!(v in object)) {
            object[v] = {}
        }
        object = object[v]
    }
    object[parts[0]] = value
}

export function parentSelector(element, selector) {
    for(;;) {
        if (element.matches(selector)) {
            return element
        }
        element = element.parentElement
        if (!element) return null
    }
}

export function wrapElement(element, wrapper) {
    if (typeof wrapper === "string") wrapper = document.createElement(wrapper);
    element.after(wrapper);
    wrapper.appendChild(element);
    return wrapper;
}
