import i18next from 'i18next';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

i18next
  .use(i18nextBrowserLanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: (process.env.NODE_ENV === 'development'),
    resources: require('../i18n_ressources.json')
  },
  function (/*t*/) {
      i18n_fragment(document);
      for (const t of document.querySelectorAll('template')) {
        i18n_fragment(t.content);
      }
  }
);

function i18n_fragment(node) {
  var i18nList = node.querySelectorAll('[data-i18n]');
  for (const v of i18nList) {
    var key = v.dataset.i18n
    var attr = 'text';
    if (key.indexOf('[') === 0) {
        var parts = key.split(']');
        key = parts[1];
        attr = parts[0].substr(1, parts[0].length-1);
    }
    const i8n_translation = i18next.t(key);
    if (attr == 'text') {
        v.textContent = i8n_translation;
    } else {
        v.setAttribute(attr, i8n_translation);
    }
  }
}
