import { dispatch } from '../libs/helpers.js'

class Geoloc {
    constructor() {
        console.log("[geoloc] init")
        this.watch_id = false;
    }

    get started() {
        return this.watch_id != false;
    }

    autostart(last_date) {
        if (localStorage.getItem("geoloc.active") != "1") {
            localStorage.setItem("geoloc.active", "0");
            return;
        }
        if ((Date.now() - last_date)/1000 < 60*60) {
            console.log("relaunch_geolocation on trace_loaded")
            this.start();
        }
    }

    start() {
        console.log("launch_geolocation")
        if (this.watch_id) {
            console.log("stop actual watch")
            navigator.geolocation.clearWatch(this.watch_id);
            this.watch_id = false;
        } else {
            localStorage.setItem("geoloc.active", "1")
            dispatch('geoloc_start')
        }
        console.log("geolocation.watchPosition")
        this.watch_id = navigator.geolocation.watchPosition(function(pos) {
            dispatch("geoloc_pos", pos)
        }, function(error){
            console.warn("watchPosition error:", error.code, error.message);
            //navigator.geolocation.clearWatch(watch_id);
            //watch_id = false;
        }, {timeout: 5000, enableHighAccuracy: true});
    }

    stop() {
        if (this.watch_id) {
            console.log("geolocation.clearWatch")
            navigator.geolocation.clearWatch(this.watch_id);
            localStorage.setItem("geoloc.active", "0")
            this.watch_id = false
            dispatch('geoloc_stop')
        }
    }
}

export default new Geoloc();
