import { dispatch, watch, unwatch } from '../libs/helpers.js'
import geoloc_ctrl from '../controllers/geoloc_ctrl.js'
import { overlayAction } from '../controllers/overlay_action.js'

(function() {
    "use strict";

    if (!("wakeLock" in navigator)) {
        document.getElementById('nosleep').disabled = true;
        return
    }

    let wakeLock = null
    var geoloc_start_watch = null;
    var geoloc_stop_watch = null;

    async function no_sleep_start() {
        try {
            wakeLock = await navigator.wakeLock.request("screen")
            // listen for our release event
            dispatch("notification", 'no_sleep.notifications.title', 'no_sleep.notifications.activated')
            wakeLock.addEventListener("release", () => {
                console.log("[no_sleep] wakeLock release");
                dispatch("notification", 'no_sleep.notifications.title', 'no_sleep.notifications.release')
                wakeLock = null;
            });
        } catch (error) {
            console.error("wakeLock", error.message);
            overlayAction.show("options.options.no_sleep", () => {
                no_sleep_start();
            })
        }
    }

    function no_sleep_stop() {
        if (wakeLock !== null) {
            wakeLock.release().then(() => {
                wakeLock = null;
            });
        }
    }

    document.addEventListener("visibilitychange", () => {
        console.log("visibilitychange", document.visibilityState)
        if (document.visibilityState == "visible") {
            if (geoloc_ctrl.started && geoloc_start_watch) {
                console.log("[no_sleep] wakeLock restart");
                no_sleep_start();
            }
        }
    })

    document.getElementById('nosleep').addEventListener("change", function(){
        if (this.checked) {
            geoloc_start_watch = watch("geoloc_start", no_sleep_start)
            geoloc_stop_watch = watch("geoloc_stop", no_sleep_stop)
            if (geoloc_ctrl.started) {
                no_sleep_start()
            }
        } else {
            if (geoloc_start_watch) {
                geoloc_start_watch = unwatch("geoloc_start", geoloc_start_watch);
            }
            if (geoloc_stop_watch) {
                geoloc_stop_watch = unwatch("geoloc_stop", geoloc_stop_watch);
            }
            if (wakeLock) {
                no_sleep_stop()
            }
        }
    })
})();
