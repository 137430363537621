"use strict";

import L from 'leaflet'

import {styles} from "../libs/consts.js"
import { generateGrid } from '../libs/utils.js'

import tiles_configuration from '../models/tiles_configuration.js'
import { styleConfiguration } from '../modules/styles-configuration/styles-configuration.js'

export function grid_add(mymap) {
    let grid_layer = [];
    let grid_style = styles.grid

    styleConfiguration.onStyleUpdated.subscribe((styles) => {
        grid_style = styles.grid
        display_grid()
    })

    function display_grid_level(level, style) {
        const geojson_grid = generateGrid(mymap.getBounds(), level, 5000);
        if (geojson_grid) {
            grid_layer.push(L.geoJSON(geojson_grid, style).addTo(mymap))
            return true
        }
        return false
    }

    function display_grid() {
        var style_index = 0
        // Remove actual grids
        for (const layer of grid_layer) {
            layer.remove()
        }
        grid_layer = []

        // Create grids
        for (const level of Object.keys(tiles_configuration.configuration.geojson).reverse()) {
            if (display_grid_level(level, grid_style[(style_index==0)?'primary':'secondary'])) {
                style_index += 1
            }
        }
    }

    tiles_configuration.on_configuration_changed.subscribe(() => {
        display_grid()
    });

    mymap.on("moveend", display_grid);
}
