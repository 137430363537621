import user_configuration from './user_configuration.js'
import { database } from './database.js'
import { serverApi } from './serverApi.js'

const defaultParser = {
    parse: (v) => v,
    stringify: (v) => v
}

export const mapParser = {
    stringify: (d) => {
        return JSON.stringify([...d.entries()]);
    },
    parse: (s) => {
        return new Map(JSON.parse(s));
    }
}

export class OptionsStorage {
    constructor(key, parser=defaultParser) {
        this.key = key;
        this.parser = parser
    }

    get defaultValue() {
        return null
    }

    async get() {
        //console.log("[OptionsStorage].get:", this.key)
        return new Promise(async (resolve) => {
            var dbOption = await database.get("options", this.key)
           // console.log("[OptionsStorage].get dbOption=", dbOption)
            if (!dbOption) {
                dbOption = {key: this.key, value: this.defaultValue, date: 0, isModified: false}
            }
            if (navigator.onLine && user_configuration.cloud) {
               // console.log("[OptionsStorage].get CLOUD")
                try {
                    const serverOption = await this._getServer(dbOption.date)
                   // console.log("[OptionsStorage].get getServerOption=", serverOption)
                    if (serverOption.change) {
                        if (dbOption.isModified) {
                           // console.log("[OptionsStorage] getOption <>")
                            // Server change and not localy modified => ask action
                            dbOption = await this._resolution(dbOption, serverOption, false)
                            database.set("options", dbOption)
                        } else {
                           // console.log("[OptionsStorage] getOption get server")
                            // Server change and not locally modified => get new option
                            dbOption.value = serverOption.value
                            dbOption.date = serverOption.date
                            database.set("options", dbOption)
                        }
                    } else {
                        if (dbOption.isModified) {
                           // console.log("[OPTIONS] getOption set server")
                            dbOption = await this._setServer(dbOption)
                            dbOption.isModified = false
                            database.set("options", dbOption)
                        }
                    }
                } catch {
                    // console.warn("[OptionsStorage].get CLOUD CATCH")
                    //reject()
                }
            }
           // console.log("[OptionsStorage].get resolve:", dbOption.value)
            resolve(dbOption.value)
        })
    }

    async set(value) {
       // console.log("[OptionsStorage].set", this.key, value)
        return new Promise(async (resolve) => {
            var dbOption = await database.get("options", this.key)
            if (!dbOption) {
                dbOption = {key: this.key, date: 0}
            }
            dbOption.value = value;
            dbOption.isModified = Date.now()/1000;
            console.log("set before network", dbOption)
            database.set("options", {...dbOption});

            if (navigator.onLine && user_configuration.cloud) {
                try {
                    dbOption = await this._setServer(dbOption)
                    dbOption.isModified = false
                    console.log("[OptionsStorage].set server save OK", dbOption)
                    await database.set("options", dbOption)
                } catch {
                    console.warn("[OptionsStorage].set server save FAIL", this.key)
                }
            }
            resolve(dbOption.value)
        })
    }

    conflictResolution(localData, serverData, isSet=false) {
       // console.log("conflictResolution", localData , serverData)
        if (isSet || (localData.isModified > serverData.date)) {
            return "local"
        } else {
            return "server"
        }
    }

    async _resolution(dbOption, serverOption, isSet=false) {
        var choice = this.conflictResolution(dbOption, serverOption, isSet)
       // console.log("RESOLUTION choice", choice)
        if (choice == "server") {
            dbOption.value = serverOption.value
            dbOption.date = serverOption.date
            dbOption.isModified = false
            // console.log("_resolution server->local=", dbOption)
        }
        if (choice == "local") {
            // keep actual options
            dbOption.date = serverOption.date
            // console.log("_resolution local->server=", dbOption)
            dbOption = await this._setServer(dbOption)
            dbOption.isModified = false
        }
       // console.log("_resolution return=", dbOption)
        return dbOption
    }

    async _getServer(date) {
        return new Promise((resolve, reject) => {
           // console.log("_getServer()")
            serverApi.request({
                token: user_configuration.cloud.key,
                name: user_configuration.cloud.user,
                action: "user_get_option",
                key: this.key,
                date: date
            })
            .then(data => {
               // console.log("_getServer.then=", data)
                if (data.status) {
                    if (data.value) {
                        data.value = this.parser.parse(data.value)
                    }
                    resolve(data)
                } else {
                    reject(data)
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    }

    async _setServer(dbOption) {
        return new Promise((resolve, reject) => {
            serverApi.request({
                token: user_configuration.cloud.key,
                name: user_configuration.cloud.user,
                action: "user_set_option",
                key: this.key,
                date: dbOption.date,
                value: this.parser.stringify(dbOption.value)
            }, "POST")
            .then(async (data) => {
                if (data.status) {
                    dbOption.date = data.date
                    resolve(dbOption)
                } else {
                    if (data.error == "api.error.wrong_date") {
                        dbOption = await this._resolution(dbOption, data, true)
                        resolve(dbOption)
                    } else {
                        reject(data)
                    }
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    }
}