

async function request(args={}, method="GET") {
    return new Promise((resolve, reject) => {
        const url = new URL("https://www.bouillard.org/kikourou/api");
        let options = {
            method: method,
            cache: "no-cache",
            referrerPolicy: "no-referrer"
        };

        if (method=="GET") {
            for (const [k, v] of Object.entries(args)) {
                url.searchParams.append(k, v);
            }
        } else {
            options.body = new FormData();
            for (const [k, v] of Object.entries(args)) {
              options.body.set(k, v);
            }
        }

        fetch(url, options)
        .then(response => response.json())
        .then(data => {
            resolve(data)
        })
        .catch((error) => {
            reject(error)
        })
    })
}

export const serverApi = {
    request,
    API_PUBLIC_TOKEN: "lgOJGY-DTrVhJ-XQo7UJ"

}
