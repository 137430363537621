"use strict";

import { elevation_svg } from '../libs/elevation.js'
import { watch, delayed } from '../libs/helpers.js'

var svg_chart = null
var current_route = null
const elevation_profile_elt = document.getElementById('elevation_profile')

function update_elevation_profile() {
    console.log("ELEVATION.update_elevation_profile")
    if (svg_chart) {
        svg_chart.remove()
        svg_chart = null
    }
    if (document.getElementById('display_elevation').checked) {
        if (current_route) {
            svg_chart = elevation_svg(current_route.geojson, elevation_profile_elt)
            if (svg_chart) {
                console.log("ELEVATION.update_elevation_profile display")
                elevation_profile_elt.append(svg_chart)
            }
        }
    }
}
elevation_profile_elt.classList.toggle("d-none", true);
document.getElementById("display_elevation").addEventListener("change", () => {
    elevation_profile_elt.classList.toggle("d-none", !document.getElementById("display_elevation").checked)
    update_elevation_profile()
})

const delayedUpdateElevation = delayed(() => {
  update_elevation_profile();
}, 1000);

const elevationResizeObserver = new ResizeObserver(() => {
    delayedUpdateElevation();
});

elevationResizeObserver.observe(elevation_profile_elt);


watch(["routes_selected_route_changed", "routes_list_change_selection"], (route) => {
    current_route = route
    update_elevation_profile();
});
