"use strict";

const service_url = "https://wxs.ign.fr/calcul/geoportail/itineraire/rest/1.0.0/route"
const elevation_url = "https://wxs.ign.fr/calcul/alti/rest/elevation.json"

const routing_modes = {
      "path": { profile: "pedestrian", optimization: "shortest"},
      "bike": { profile: "pedestrian", optimization: "shortest"},
      "road": { profile: "car", optimization: "shortest"}
}

export class Route_Ign {
    constructor() {
    }

    route(from_coord, to_coord, routing_mode) {
        const rm = routing_modes[routing_mode]
        const url = new URL(service_url);

        url.searchParams.append("resource", "bdtopo-osrm");
        url.searchParams.append("zonly", "true");
        url.searchParams.append("start", `${from_coord[0]},${from_coord[1]}`);
        url.searchParams.append("end", `${to_coord[0]},${to_coord[1]}`);
        for (const k in rm) {
          url.searchParams.append(k, rm[k]);
        }

        return new Promise((resolve/*, reject*/) => {
            fetch(url)
            .then((response)=> response.json())
            .then((json) => {
                resolve(json.geometry);
            })
        })
    }

    add_elevation(feature) {
        let lats = []
        let lons = []
        for (const [lon, lat] of feature.geometry.coordinates) {
            lats.push(lat.toFixed(7))
            lons.push(lon.toFixed(7))
        }
        let chunks = []
        const chunkSize = 200;
        for (let i = 0; i < lats.length; i += chunkSize) {
            chunks.push([lats.slice(i, i + chunkSize), lons.slice(i, i + chunkSize)])
        }
        return new Promise((resolve/*, reject*/) => {
            Promise.all(chunks.map( (chunk) => {
                const url = new URL(elevation_url);
                url.searchParams.append("lat", chunk[0].join("|"));
                url.searchParams.append("lon", chunk[1].join("|"));
                url.searchParams.append("sampling", chunk[0].length);
                url.searchParams.append("zonly", true);
                return fetch(url)
                .then((response)=> response.json())
                .then((json_ele) => {
                    return json_ele.elevations
                })
            }))
            .then(responses => {
                let ele = [].concat(...responses)
                ele.map((el, index) => {
                    feature.geometry.coordinates[index].push(el)
                })
                resolve(feature);
            })
        })
    }

}

