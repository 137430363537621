import i18next from 'i18next';
import { watch, get_template } from '../libs/helpers.js'

(function() {
    "use strict";

    function create_notification(title, message, error=false, action=null) {
        let html_fragment = get_template("template-notification");
        html_fragment.querySelector(".notification-title").innerText = title
        html_fragment.querySelector(".notification-message").innerText = message

        if (action) {
            html_fragment.querySelector(".toast-header").classList.add("bg-info", "text-bg-info")
            html_fragment.querySelector(".toast-body").classList.add("bg-info-subtle", "text-bg-info-subtle")
            html_fragment.querySelector(".notification-action-yes").onclick = function() {
                action();
                html_fragment.remove();
            }
        } else {
            html_fragment.querySelector(".notification-actions").remove()
            if (error) {
                html_fragment.querySelector(".toast-header").classList.add("bg-danger", "text-bg-danger")
                html_fragment.querySelector(".toast-body").classList.add("bg-danger-subtle", "text-bg-danger-subtle")
            } else{
                html_fragment.querySelector(".toast-header").classList.add("bg-success", "text-bg-success")
                html_fragment.querySelector(".toast-body").classList.add("bg-success-subtle", "text-bg-success-subtle")
            }
        }

        document.getElementById("notification-container").insertAdjacentElement('beforeend', html_fragment)

        if (!(error || action)) {
            setTimeout(() => {
                html_fragment.remove()
                //document.getElementById("notification-container").removeChild(document.getElementById(toast_id))
            }, 5000)
        }
    }

    watch("notification", (title, message, is_error=false, action=null) => {
        const regex = new RegExp('^\\w+(\\.\\w+)*$');
        title = i18next.t(title);
        if (regex.test(message)) {
            message = i18next.t(message);
        } else {
            const regex_replace = new RegExp('#\\w+(\\.\\w+)*#', 'g');
            message = message.replaceAll(regex_replace, (match) => i18next.t(match.replaceAll("#", '')))
        }
        create_notification(title, message, is_error, action)
    })

})();

