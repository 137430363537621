
class DbApi {
    constructor() {
        this.db = null
    }

    close() {
        if (this.db) {
            this.db.close()
            this.db = null
        }
    }

    open() {
        if (this.db) {
            return Promise.resolve(this.db)
        }
        return new Promise((resolve, reject) => {
            const request = indexedDB.open("jjab", 4);

            request.onerror = (db_event) => {
                console.error("Why didn't you allow my web app to use IndexedDB?!");
                reject(db_event)
            };

            request.onupgradeneeded = (db_event) => {
                const db = db_event.target.result;
                if (db_event.oldVersion < 1) {
                    db.createObjectStore("jsonfiles", { keyPath: "path" });
                }
                if (db_event.oldVersion < 2) {
                    db.createObjectStore("routes", { keyPath: "id" });
                }
                if (db_event.oldVersion < 3) {
                    db.createObjectStore("styles", { keyPath: "id" });
                }
                if (db_event.oldVersion < 4) {
                    db.createObjectStore("options", { keyPath: "key" });
                }
            };
            request.onsuccess = (db_event) => {
                this.db = db_event.target.result
                resolve(this.db)
            }
        })
    }

    getObjectStore(db, table) {
        const transaction = db.transaction(table, "readwrite");
        const objectStore = transaction.objectStore(table);
        return objectStore
    }

    remove(table, element_id) {
        return new Promise((resolve, reject) => {
            this.open()
            .then((db) => {
                const objectStore = this.getObjectStore(db, table);
                const request = objectStore.delete(element_id);
                request.onerror = () => {
                    reject("Request error");
                };
                request.onsuccess = () => {
                    resolve()
                };
            });
        })
    }

    get(table, element_id) {
        return new Promise((resolve, reject) => {
            this.open()
            .then((db) => {
                const objectStore = this.getObjectStore(db, table);
                const request = objectStore.get(element_id);
                request.onerror = () => {
                    reject("request error");
                };
                request.onsuccess = (db_event) => {
                    if (db_event.target.result) {
                        resolve(db_event.target.result)
                    } else {
                        resolve(null)
                    }
                };
            })
        })
    }

    getAll(table) {
        return new Promise((resolve, reject) => {
            this.open()
            .then((db) => {
                const objectStore = this.getObjectStore(db, table);
                const request = objectStore.getAll();
                request.onerror = () => {
                    reject("request error");
                };
                request.onsuccess = (db_event) => {
                    if (db_event.target.result) {
                        resolve(db_event.target.result)
                    }
                };
            })
        })
    }

    set(table, element) {
        return new Promise((resolve, reject) => {
            this.open()
            .then((db) => {
                const objectStore = this.getObjectStore(db, table);
                const request = objectStore.put(element);
                request.onerror = () => {
                    reject("request error");
                };
                request.onsuccess = () => {
                    resolve()
                };
            })
        })
    }

    clear(table) {
        return new Promise((resolve, reject) => {
            this.open()
            .then((db) => {
                const transaction = db.transaction([table], "readwrite");
                const objectStore = transaction.objectStore(table);
                const request = objectStore.clear();
                request.onerror = () => {
                    console.log("CLEAR ON ERROR")
                    reject("request error");
                };
                request.onsuccess = () => {
                    resolve()
                };
            })
        })
    }

}

export const database = new DbApi()
