import { watch } from '../libs/helpers.js'

import sound from '../models/sound.js'
import geoloc_ctrl from '../controllers/geoloc_ctrl.js'

// SOUND
watch("geoloc_button", () => {
    sound.reset();
    sound.play(0.0);
})

document.addEventListener("visibilitychange", async () => {
    if (document.visibilityState != "visible") return
    console.log("[sound] visibilitychange")
    if (!geoloc_ctrl.started) return
    console.log("[sound] try sound")
    sound.play(0.0)
})

watch("geoloc_start", async function() {
    console.log("[controllers/sound] geoloc_start")
    sound.play(0.0)
})

document.getElementById("play_volume").addEventListener("change", function() {
    if (document.getElementById("modal-options").classList.contains("show")) {
        sound.play();
    }
})

