"use strict";

import * as turf from '@turf/turf'
import togeojson from '@mapbox/togeojson'

import { TilesStorage, geojson_tiles } from './utils.js'
import { dispatch } from './helpers.js'
import deepEqual from 'deep-equal'

/*async function stall(stallTime = 1000) {
  await new Promise(resolve => setTimeout(resolve, stallTime));
}*/

function geojson_template() {
    return {
        type: "FeatureCollection",
        features: [ ],
        name: "nouveau parcours",
        id : window.crypto.randomUUID(),
        idx : 0,
        length: 0,
        modified: false
    }
}

export class Route {
    constructor(route=null) {
        this.geojson = route || geojson_template()
        this._rq_count = 0
        this.routing_service = null

        if (this.geojson.modified === undefined) {
            this.geojson.modified = true
        }

        // compatibility with previous gpx route
        if (route && route.from_gpx && (route.features.length==1)) {
            var coords = this.geojson.features[0].geometry.coordinates
            this.geojson.features = []
            delete this.geojson.from_gpx
            this.geojson.features.push(turf.point(coords[0],{routing: "gpx"}))
            this.geojson.features.push(turf.lineString(coords))
            this.geojson.features.push(turf.point(coords[coords.length-1],{routing: "gpx"}))
        }

        // Compatibility with previous format
        if (this.geojson.features.length>1) {
            if (this.geojson.features[1].geometry.type != "LineString") {
                var old_geojson = this.geojson
                var new_geojson = turf.featureCollection([])
                turf.featureEach(this.geojson, function(feature, index) {
                    new_geojson.features.push(feature)
                    if (index < old_geojson.features.length-1) {
                        if (feature.properties.routing == "flyby") {
                            new_geojson.features.push(turf.lineString([feature.geometry.coordinates, old_geojson.features[index+1].geometry.coordinates]))
                        } else {
                            new_geojson.features.push(turf.feature(feature.properties.route))
                            delete feature.properties["route"]
                        }
                    }

                })
                new_geojson.name = "nouveau parcours"
                this.geojson = new_geojson
            }
        }
        if (!('name' in this.geojson)) {
            var this_geojson = this.geojson
            turf.propEach(this.geojson, function (currentProperties) {
                if (! ('name' in this_geojson)) {
                    if ('name' in currentProperties) {
                        this_geojson.name = currentProperties.name
                    }
                }
            });
            if (! ('name' in this.geojson)) {
                this.geojson.name = "nouveau parcours"
            }
        }
        // Tiles
        this._tilesStorage_rebuild()
    }

    _tilesStorage_rebuild() {
        this.tiles = {};
        if (!("tiles" in this.geojson) || Array.isArray(this.geojson.tiles)) {
            this.geojson.tiles = {};
        } else {
            for (const [level, tiles] of Object.entries(this.geojson.tiles)) {
              this.tiles[level] = new TilesStorage(tiles)
            }
        }
    }

    get id() {
        return this.geojson.id;
    }

    set id(id) {
      const last_id = this.id;
      this.geojson.id = id;
      dispatch("route_change_id", last_id, id);
      this.trigger_change();
    }

    get finished() {
        return this.rq_count==0;
    }

    set_geojson(geojson, modified=true) {
        const pg = this.geojson
        this.geojson = geojson
        this.modified = modified
        if (pg.name != this.name) {
            dispatch("route_rename", this);
        }
        this._tilesStorage_rebuild()
        this.trigger_change(modified)
        dispatch('route_tiles_changed', this);
    }

    get_tiles(level) {
        if (!("tiles" in this.geojson) || Array.isArray(this.geojson.tiles)) {
            this.geojson.tiles = {};
        }
        if (!(level in this.geojson.tiles)) {
            var tiles = new TilesStorage();
            turf.featureEach(this.geojson, function (feature) {
                if (feature.geometry.type == "LineString") {
                    let feature_tiles;
                    if (!("tiles" in feature.properties) || Array.isArray(feature.properties.tiles)) {
                        feature.properties.tiles = {};
                    }
                    if (!(level in feature.properties.tiles)) {
                        feature_tiles = geojson_tiles(feature, level);
                        feature.properties.tiles[level] = feature_tiles.tiles;
                    } else {
                        feature_tiles = new TilesStorage(feature.properties.tiles[level]);
                    }
                    tiles.append(feature_tiles);
                }
            });
            if (!("tiles" in this.geojson)) {
                this.geojson.tiles = {};
            }
            this.geojson.tiles[level] = tiles.list();
            if ((!(level in this.tiles)) || !tiles.is_equal(this.tiles[level])) {
                this.tiles[level] = tiles;
                dispatch("route_tiles_changed", this);
            }
        }
        return this.tiles[level];
    }

    set name(new_name) {
        if (new_name != this.geojson.name ) {
            this.geojson.name = new_name
            this.modified = true
            dispatch("route_rename", this);
        }
    }

    get name() {
        return this.geojson.name;
    }

    get length() {
        return this.get_length()
    }

    get_length() {
        if (!this.geojson.length) {
            console.log("length with turf length")
            this.geojson.length = turf.length(this.geojson)
        }
        return this.geojson.length
    }


    get modified() {
        return this.geojson.modified;
    }

    set modified(is_modified) {
        if (this.geojson.modified != is_modified) {
            this.geojson.modified = is_modified;
            dispatch("route_modified_status_changed", this, is_modified, this.rq_count==0);
       }
    }

    set idx(value) {
        this.geojson.idx = value
        dispatch("route_modified_status_changed", this, this.geojson.modified, this.rq_count==0);
    }

    get rq_count() {
        return this._rq_count
    }

    set rq_count(value) {
        this._rq_count = value
        this.trigger_change()
    }

    trigger_change(modified=true) {
        if (this.rq_count==0) {
            this.geojson.length = turf.length(this.geojson)
            dispatch("route_changed", this);
        } else {
            dispatch("route_changing", this);
        }
        this.geojson.markers_km = null;
        this.modified = modified
    }

    compute_path() {
        if (this.geojson.features.length<1)  return null
        var path = turf.featureReduce(this.geojson, function (previousValue, currentFeature) {
            if (currentFeature.geometry.type=="LineString") {
                if (previousValue.geometry.type!="LineString") {
                    return turf.lineString(currentFeature.geometry.coordinates)
                } else {
                    previousValue.geometry.coordinates = previousValue.geometry.coordinates.concat(currentFeature.geometry.coordinates)
                }
            }
            return previousValue
        });
        path.properties.name = this.name
        return path
    }

    indexOfFeature(feature) {
      for (let index=0; index<this.geojson.features.length; index++) {
        if ((this.geojson.features[index] == feature) || deepEqual(this.geojson.features[index], feature)) {
          return index
        }
      }
      return -1
    }

    featureAtDistance(distance) {
        for (let index=0; index<this.geojson.features.length; index++) {
            const feature = this.geojson.features[index]
            if (feature.geometry.type=="LineString") {
                const fd = turf.length(feature)
                if (distance <= fd ) {
                    return feature
                }
                distance -= fd
            }
        }
        return null
    }

    waypoint_append(coords) {
        var routing_mode = "flyby"
        // Get routing mode of last point
        if (this.geojson.features.length>0) {
            const last = this.geojson.features[this.geojson.features.length-1]
            routing_mode = last.properties.routing
            if (routing_mode == "gpx") {
                dispatch("notification", "route.error.title", "route.error.waypoint_append_gpx", true)
                return false
            }
            this.geojson.features.push(turf.lineString([last.geometry.coordinates, coords], {}))
        }
        this.geojson.features.push(turf.point(coords, {routing: routing_mode}))
        if (this.geojson.features.length>1) {
            this.routing(this.geojson.features.length-3)
        }
        this.trigger_change()
    }

    waypoint_insert(feature, coords, routing_mode=false) {
        // Get index of feature
        const index = this.indexOfFeature(feature)-1
        // Get nearest point of the line
        const nearest = turf.nearestPointOnLine(feature, coords)
        // Split the feature on the point
        const split = turf.lineSplit(feature, nearest)
        if (split.features.length != 2) {
            return
        }
        // Replace feature with the two part and a point
        if (!routing_mode) {
            routing_mode = this.geojson.features[index].properties.routing;
        }
        this.geojson.features.splice(index+1, 1, split.features[0],
            turf.feature(nearest.geometry,{ routing: routing_mode }), split.features[1] )
        // Compute tiles of the new lines
        this.remove_tiles_feature(this.geojson.features[index+1])
        this.remove_tiles_feature(this.geojson.features[index+3])

        if (routing_mode != this.geojson.features[index].properties.routing) {
            this.routing(index+2, false)
        }
        // Trigger change for display and saving
        this.trigger_change()
    }

    waypoint_remove(index) {
        if (index>0) {
        this.remove_tiles_feature(this.geojson.features[index-1])
            this.geojson.features.splice(index-1, 2)
        } else {
        this.remove_tiles_feature(null)
            this.geojson.features.splice(index, 2)
        }
        if ((index>0) && (index != this.geojson.features.length+1)) {
            this.routing(index-2)
        } else {
            dispatch("route_tiles_changed", this);
        }
        this.trigger_change()
    }

    waypoint_move(index, coords) {
        let feature = this.geojson.features[index]
        feature.geometry.coordinates = coords
        if (index>0) {
            this.routing(index-2)
        }
        if (index < this.geojson.features.length-1) {
            this.routing(index)
        }
        this.trigger_change()
    }

    waypoint_set_routing_mode(index, routing_mode) {
        let feature = this.geojson.features[index]
        if (feature.properties.routing != routing_mode) {
            feature.properties.routing = routing_mode
            if (index < this.geojson.features.length-1) {
                this.routing(index)
            }
            this.trigger_change()
        }
    }

    reverse() {
        this.geojson.features = this.geojson.features.reverse()
        var self = this
        turf.featureEach(this.geojson, function(feature, index) {
            if (feature.geometry.type=="Point") {
                if (index < self.geojson.features.length-1)
                feature.properties.routing = self.geojson.features[index+2].properties.routing
            }

            if (feature.geometry.type=="LineString") {
                feature.geometry.coordinates = feature.geometry.coordinates.reverse()
            }
        })
        turf.featureEach(this.geojson, function(feature, index) {
            if (feature.geometry.type=="Point") {
                self.routing(index)
            }
        })
        this.trigger_change()
    }

    remove_tiles_feature(feature) {
        if (feature) {
            feature.properties.tiles = {}
        }
        this.geojson.tiles = {}
    }

    routing(index, recursive=true) {
        if (index>this.geojson.features.length-2) return
        var featurePoint = this.geojson.features[index]
        var featureRoute = this.geojson.features[index+1]
        var featureToPoint = this.geojson.features[index+2]
        var from_coord = featurePoint.geometry.coordinates
        var to_coord = featureToPoint.geometry.coordinates
        if (!featurePoint.properties.routing) {
            featurePoint.properties.routing = "flyby"
        }
        let promise_routing = null
        if (featurePoint.properties.routing == "flyby") {
            featureRoute.geometry.coordinates = [from_coord, to_coord]
            this.remove_tiles_feature(featureRoute)
            promise_routing = Promise.resolve(featureRoute);
        } else if (featurePoint.properties.routing == "gpx") {
            // No change
            promise_routing = Promise.resolve(featureRoute);
        } else {
            promise_routing = new Promise((resolve) => {
                featureRoute.geometry.coordinates = [from_coord, to_coord]
                this.rq_count += 1
                this.routing_service.route(from_coord, to_coord, featurePoint.properties.routing)
                .then(async (geometry) => {
                    //await stall(); // FOR DEBUG TO SLOW SPEED REQUESTS
                    featureRoute.geometry = geometry
                    this.remove_tiles_feature(featureRoute)
                    console.log("equal 1?" , index,  featurePoint.geometry.coordinates, featureRoute.geometry.coordinates[0])
                    if (!deepEqual(featurePoint.geometry.coordinates.slice(0, 2), featureRoute.geometry.coordinates[0].slice(0, 2))) {
                        console.log("Move point on track", index)
                        featurePoint.geometry.coordinates = featureRoute.geometry.coordinates[0]
                        if (index>0 && recursive) {
                            this.routing(index-2, false)
                        }
                    }
                    console.log("equal 2?" , index+2,  featureToPoint.geometry.coordinates, featureRoute.geometry.coordinates[featureRoute.geometry.coordinates.length-1])
                    if (!deepEqual(featureToPoint.geometry.coordinates.slice(0, 2), featureRoute.geometry.coordinates[featureRoute.geometry.coordinates.length-1].slice(0, 2))) {
                        console.log("Move point on track", index+2)
                        featureToPoint.geometry.coordinates = featureRoute.geometry.coordinates[featureRoute.geometry.coordinates.length-1]
                        if (index+2<this.geojson.features.length-1 && recursive) {
                            this.routing(index+2, false)
                        }
                    }
                    this.rq_count -= 1
                    resolve(featureRoute)
                }, (error) => {
                    alert(error)
                    featurePoint.properties.routing = "flyby"
                    this.remove_tiles_feature(featureRoute)
                    this.rq_count -= 1
                    resolve(featureRoute)
                })

            })
        }
        if (this.routing_service.add_elevation) {
            this.rq_count += 1
            promise_routing
            .then(() => this.routing_service.add_elevation(featureRoute))
            .then(() => {
                this.rq_count -= 1
            })
        }
    }

    get_km_markers(zoom_level) {
        const zoom_level_space = new Map([
            [8, 100],
            [9, 50],
            [10, 20],
            [11, 10],
            [12, 5],
            [13, 2],
            [14, 1],
            [15, 0.5],
            [16, 0.2],
            [17, 0.1],
        ]);
        let space = zoom_level_space.get(Math.floor(zoom_level))
        if (!space) {
            if (zoom_level < 8) space = 100;
            if (zoom_level > 17) space = 0.1;
        }
        if (!this.geojson.markers_km) {
            this.geojson.markers_km = {}
        }
        if (!(space in this.geojson.markers_km)) {
            let markers = []
            const path = this.compute_path();
            let index = 0
            while (index * space < this.length) {
                const marker = turf.along(path, index * space)
                marker.properties = {'distance': index * space}
                markers.push(marker)
                index += 1
            }
            this.geojson.markers_km[space] = turf.featureCollection(markers)

            // TODO this.save() ?
        }
        return this.geojson.markers_km[space]
    }
}

export function gpx_to_geojson(dom) {
    var geojson_gpx = togeojson.gpx(dom)
    var geojson = geojson_template()
    var coords = geojson_gpx.features[0].geometry.coordinates
    geojson.features.push(turf.point(coords[0],{routing: "gpx"}))
    geojson.features.push(turf.lineString(coords))
    geojson.features.push(turf.point(coords[coords.length-1],{routing: "flyby"}))
    if (geojson_gpx.features[0].properties.name) {
      geojson.name = geojson_gpx.features[0].properties.name
    }
    return geojson
}