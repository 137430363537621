import * as turf from '@turf/turf'

import { Observable } from '../libs/observable.js'
import { TilesStorage, TileFromCoord, boundsFromTile } from '../libs/utils.js'
import { dispatch } from '../libs/helpers.js'

import tiles_configuration from '../models/tiles_configuration.js'


class TraceTilesModel {
    constructor() {
        this.precision_factor = 0;
        this.tiles = new TilesStorage()
        this.on_tiles_updated = new Observable("TraceTiles.on_tiles_updated");
        this.on_new_tile = new Observable("TraceTiles.on_new_tile");
        this.mymap = null;
    }

    initialize(mymap) {
        this.mymap = mymap;
        if (localStorage.getItem("trace_visited_tiles")) {
            console.log("load trace_visited_tiles")
            this.tiles = new TilesStorage(JSON.parse(localStorage.getItem("trace_visited_tiles")))
        }
        this.on_tiles_updated.notify(this.tiles);
    }

    save() {
         localStorage.setItem("trace_visited_tiles", JSON.stringify(this.tiles.tiles));
    }

    has(x, y) {
        return this.tiles.has(x, y);
    }

    recompute(geojson=false) {
        console.log("[trace_tiles] recompute")
        this.tiles = new TilesStorage()
        if (geojson && tiles_configuration.level) {
            console.log("recompute_trace_visited_tiles")
            turf.coordEach(geojson, (coord) => {
                const tile = this.check_latlon_tile(coord[1], coord[0]);
                if (tile != null) {
                    this.tiles.add(tile.x, tile.y)
                }
            })
        }
        this.save();
        this.on_tiles_updated.notify(this.tiles);
    }

    check_latlon_tile(lat, lon, accuracy=false) {
        if (!tiles_configuration.level) return null;
        const coords = [lat, lon]
        const [x, y] = TileFromCoord(lat, lon, tiles_configuration.level)
        const bounds = boundsFromTile(x, y, tiles_configuration.level)
        const min_dist = this.precision_factor * (accuracy || 5)
        if ((this.mymap.distance(coords, [lat, bounds.getWest()]) > min_dist)
            && (this.mymap.distance(coords, [lat, bounds.getEast()]) > min_dist)
            && (this.mymap.distance(coords, [bounds.getSouth(), lon]) > min_dist)
            && (this.mymap.distance(coords, [bounds.getNorth(), lon]) > min_dist)
           ) {
            if (! this.has(x, y)) {
                console.log("trace_tiles.check_latlon_tile new tile", x, y)
                return {x, y, bounds}
            }
        }
        return null
    }

    add_latlon(lat, lon, accuracy=false) {
        //console.log("trace_tiles.add_latlon", lat, lon)
        const tile = this.check_latlon_tile(lat, lon, accuracy)
        if (tile != null) {
            this.tiles.add(tile.x, tile.y)
            console.log("trace_tiles.add_latlon tiles.count", this.tiles.count)
            dispatch("tile_trace_add", tile.x, tile.y, tile.bounds)
            this.save();
            this.on_new_tile.notify(tile.x, tile.y, tile.bounds);
            return tile;
        }
        return false;
    }
}

export default new TraceTilesModel();

