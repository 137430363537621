import { Observable } from '../libs/observable.js'

let GEN_DATA_SQUADRATS = "squadrats/gen/"
let GEN_DATA_STATSHUNTERS = "statshunters/gen/"

/*if (process.env.NODE_ENV === 'development') {
    GEN_DATA_SQUADRATS = "./gen/sq/"
    GEN_DATA_STATSHUNTERS = "./gen/sh/"
}*/

const tiles_conf = {
    "No": {
        "condition": [],
        "level": false,
        "geojson": {},
        "json":  false
    },
    "17": {
        "condition": ["squadrats"],
        "level": 17,
        "geojson": {
            "17": (user) => GEN_DATA_SQUADRATS + user + "/data_17.geojson"
        },
        "json":  (user) => GEN_DATA_SQUADRATS + user + "/tiles_17.json"
    },
    "14": {
        "condition": ["squadrats"],
        "level": 14,
        "geojson": {
            "14": (user) => GEN_DATA_SQUADRATS + user + "/data_14.geojson",
        },
        "json":  (user) => GEN_DATA_SQUADRATS + user + "/tiles_14.json",
    },
    "both": {
        "condition": ["squadrats"],
        "level": 17,
        "geojson": {
            "14": (user) => GEN_DATA_SQUADRATS + user + "/data_14.geojson",
            "17": (user) => GEN_DATA_SQUADRATS + user + "/data_17.geojson"
        },
        "json": (user) => GEN_DATA_SQUADRATS + user + "/tiles_17.json"
    },
    "sh_user": {
        "condition": ["statshunters"],
        "level": 14,
        "geojson": {
            "14": (user) => GEN_DATA_STATSHUNTERS + "users/" + user + "/" + user + ".geojson",
        },
        "json": (user) => GEN_DATA_STATSHUNTERS + "users/" + user + "/" + user + "_tiles.json",
    },
    "sh_community": {
        "condition": ["statshunters"],
        "level": 14,
        "geojson": {
            "14": () => GEN_DATA_STATSHUNTERS + "community/community.geojson",
        },
        "json": () => GEN_DATA_STATSHUNTERS + "community/community_tiles.json",
    },
    "jjab_17": {
        "condition": ["jjab_tiles"],
        "level": 17,
        "geojson": {
            "17": (user) => GEN_DATA_SQUADRATS + user + "/data_17.geojson"
        },
        "json":  (user) => GEN_DATA_SQUADRATS + user + "/tiles_17.json"
    },
    "jjab_14": {
        "condition": ["jjab_tiles"],
        "level": 14,
        "geojson": {
            "14": (user) => GEN_DATA_SQUADRATS + user + "/data_14.geojson",
        },
        "json":  (user) => GEN_DATA_SQUADRATS + user + "/tiles_14.json",
    },
    "jjab_both": {
        "condition": ["jjab_tiles"],
        "level": 17,
        "geojson": {
            "14": (user) => GEN_DATA_SQUADRATS + user + "/data_14.geojson",
            "17": (user) => GEN_DATA_SQUADRATS + user + "/data_17.geojson"
        },
        "json": (user) => GEN_DATA_SQUADRATS + user + "/tiles_17.json"
    }
}

class TilesConfigurationModel {
    constructor() {
        this.configurations = null;
        this._selection = "No";
        this.configuration = tiles_conf["No"];
        this.on_configuration_changed = new Observable("TilesConfiguration.on_configuration_changed");
        this.on_tile_level_changed = new Observable("TilesConfiguration.on_tile_level_changed");
    }

    get level() {
        return this.configuration.level
    }

    set_selection(value, init) {
        if (init && (this.configurations == null)) {
            this._selection = value;
            return;
        }
        if (!(value in this.configurations)) {
            console.warn('[ConfigurationModel] Invalid selection!');
            value = "No";
        }
        const previous = this.configuration
        this._selection = value
        this.configuration = this.configurations[value]
        if (this.configuration.level != previous.level) {
            this.on_tile_level_changed.notify(this.configuration.level, init);
        }
        if (this.configuration != previous) {
            this.on_configuration_changed.notify(this.configuration);
        }
    }

    configure(infos, init) {
        let user_conf = {}
        for (const [kink, conf] of Object.entries(tiles_conf)) {
            let isok = true;
            for (const condition of conf.condition) {
                if (infos[condition] != true) {
                    isok = false;
                }
            }
            if (isok) {
                user_conf[kink] = {
                    'level': conf.level,
                    'json': conf.json,
                    'geojson': {}
                };
                if (typeof(conf.json)=="function") {
                    user_conf[kink].json = user_conf[kink].json(infos.name)
                }
                for (const [level, fct] of Object.entries(conf.geojson)) {
                    user_conf[kink].geojson[level] = fct(infos.name);
                }
            }
        }
        console.log(user_conf)
        this.configurations = user_conf
        this.set_selection(this._selection, init)
    }
}

export default new TilesConfigurationModel()
