"use strict";

import { watch } from '../libs/helpers.js'

function display_refresh(route) {
    if (route == null) {
        document.getElementById("route_length").textContent = ""
        return
    }
    const length = route.get_length()
    if (length>0) {
        document.getElementById("route_length").textContent = length.toFixed(2)+" km"
    } else {
        document.getElementById("route_length").textContent = ""
    }
}

watch(["routes_selected_route_changed", "routes_selected_route_changing", "routes_list_change_selection"], (route) => {
    display_refresh(route)
});
